import React from 'react';
import { Badge, Table } from 'react-bootstrap';
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
const getCellStyle = (isChanged, isPrev) => {
  if (isChanged) {
    return {
      color: '#ff0000'
    };
  } else if (!isPrev) {
    return {
      color: '#009D44'
    };
  }
  return null;
};

const getTableBody = (products, offset) => {
  return products.map((item, index) => {
    let cells = _.map(item, (vObj, key) => {
      return (
        <td key={key} style={getCellStyle(vObj.isChanged, vObj.isPrev)}>
          {vObj.value}
        </td>
      );
    });
    return (
      <tr key={index}>
        <td className="tableIndex">{offset + index + 1}</td>
        {cells}
      </tr>
    );
  });
};

const getTableHead = (product = {}) => {
  let tHeads = _.map(product, (vObj, key) => {
    let tableHeadingClassNames = classNames({
      homePageType: key === 'Secondary Menu List',
      brandPageType: key === 'Description',
      productType: key === 'Product Description',
      reviews: key === 'Reviews',
      brandDis: key === 'Brand Description'
    });
    return (
      <th className={tableHeadingClassNames} key={key}>
        {key}
      </th>
    );
  });

  return (
    <tr>
      <th className="tableIndex"> </th>
      {tHeads}
    </tr>
  );
};

const CurrentProductsView = props => {
  const { products, currentProductDate, offset, count, perPage } = props;
  if (_.isEmpty(products)) {
    return <p className="alert alert-info">No product(s) found.</p>;
  }
  let showingStr = '';
  if (count < offset + perPage) {
    showingStr = `Showing ${offset + 1} - ${count} rows`;
  } else {
    showingStr = `Showing ${offset + 1} - ${offset + perPage} rows`;
  }

  return (
    <div>
      <div className="po-current-products-col-header">
        <label> New </label>
        <Badge>
          {' '}
          {currentProductDate
            ? moment(new Date(`${currentProductDate}`)).format('MM/DD/YYYY')
            : 'N/A'}
        </Badge>
        <Badge className="po-num-of-rows-color">{count} Rows</Badge>
        <Badge className="po-badge">{showingStr}</Badge>
      </div>
      <div id="leftDiv" className="po-current-products-table-wrap">
        <Table bordered condensed hover>
          {/* <thead>{getTableHeadChar(products[0])}</thead> */}
          <thead>{getTableHead(products[0])}</thead>
          <tbody>{getTableBody(products, offset)}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default CurrentProductsView;
