import React from "react";
import Img from "react-image";
import classNames from "classnames";
import FALLBACK_IMG from "assets/img/not-found-image.png";
import { Link } from "react-router-dom";

// import px1Image from 'assets/img/1x1.png';
// import the component
// import ContentLoader from 'react-content-loader';
// http://danilowoz.com/create-content-loader/

class ImageLoader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.openInNewTab = this.openInNewTab.bind(this);
  }

  openInNewTab(event) {
    let { image, imageLarge, openInNewTab = true, brandSplash } = this.props;
    // if (!openInNewTab || !image || brandSplash) {
    //   return;
    // }
    var viewImage = image;
    if (imageLarge) {
      viewImage = imageLarge;
    }
    // window.open(viewImage);
    this.props.handleImageModal(true, viewImage);
  }

  render() {
    let {
      image = "",
      caption = "",
      isBackground = false,
      containBg = false,
      // zoomable = true,
      brandSplash,
      retailerId,
    } = this.props;

    let spinner = (
      <div className="Image-Spinner">
        <i className="fa fa-spinner fa-spin fa-2x fa-fw" />
      </div>
    );

    let helperClasses = classNames({
      "Image-Not-Found": !image,
      "Image-Background": isBackground,
      "Image-Background-Contain": isBackground && containBg,
      "Image-Background-Cover": isBackground && !containBg,
      "Has-Caption": caption,
    });

    let bgStyle = {};
    if (isBackground) {
      bgStyle = {
        backgroundImage: `url("${image}")`,
        backgroundRepeat: "no-repeat",
      };
    }

    return (
      <div className="Image-Loader">
        {caption && <p className="Image-Caption">{caption}</p>}

        {!brandSplash ? (
          <div
            className={helperClasses}
            style={bgStyle}
            onClick={(event) => this.openInNewTab(event)}
          >
            <Img src={[image, FALLBACK_IMG]} loader={spinner} />
          </div>
        ) : (
          <Link to={`/dashboard/retailer/${retailerId}`}>
            <div className={helperClasses} style={bgStyle}>
              <Img src={[image, FALLBACK_IMG]} loader={spinner} />
            </div>
          </Link>
        )}
      </div>
    );
  }
}

export default ImageLoader;
