import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import Placeholders from '../../components/draft-js/Placeholders';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const MessagesEditor = ({ mesgContent, onEditorStateChangeCallback, mode }) => {
  const [editorState, setEditorState] = useState(() => {
    if (!mesgContent) {
      return EditorState.createEmpty();
    }
    const blocksFromHtml = htmlToDraft(mesgContent);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    let editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    return editorState;
  });
  const onEditorStateChange = editorState => {
    setEditorState(editorState);
    let editorHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let hasText = editorState.getCurrentContent().hasText();
    onEditorStateChangeCallback &&
      onEditorStateChangeCallback(editorHtml, hasText);
  };

  return (
    <Editor
      wrapperClassName="MessagesEditor-wrapper"
      editorClassName="MessagesEditor-editor"
      toolbarClassName="MessagesEditor-toolbar"
      toolbar={{
        inline: {
          options: ['bold', 'italic', 'underline']
        },
        options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'remove']
      }}
      toolbarCustomButtons={[<Placeholders mode={mode} />]}
      defaultEditorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
  );
};
export default MessagesEditor;
