import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';

import {
  doFetchStats,
  doFetchStatsSuccess,
  doFetchStatsFailure
} from 'dashboard/actions/stats';

//http://ec2-54-190-197-232.us-west-2.compute.amazonaws.com/brandRetailerSummary/:brand/:month

export default function thunkFetchStats(action) {
  return function(dispatch, getState) {
    dispatch(doFetchStats(action));
    let user = Auth.getUser();
    let brandId = user.brand._id;
    return omni_axios_instance
      .get(`/brandRetailerSummary/${brandId}`)
      .then(response => {
        dispatch(doFetchStatsSuccess(response.data));
      })
      .catch(error => dispatch(doFetchStatsFailure(error.xhr)));
  };
}
