import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import moment from 'moment';

class FileHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, id) {
    const path = `/admin/post-office/comparison/${id}`;
    this.props.history.push(path);
  }

  fileName(name) {
    let fileName = name.indexOf('/');
    if (fileName) {
      return name.split('/')[1];
    }
    return;
  }

  render() {
    const { item, activeIndex } = this.props;
    const fileNameBgColor = flag =>
      classNames({
        item: true,
        itemFileName: true,
        [flag]: true
      });

    const statusColor = color =>
      classNames({
        fileType: true,
        [color]: true
      });

    const poLeftContentClassNames = index =>
      classNames({
        leftContent: true,
        activeLeftContent: activeIndex === index
      });

    return (
      <div
        className="po-product-content period-file"
        onClick={e => this.handleClick(e, item._id)}
      >
        <div className={poLeftContentClassNames(item._id)}>
          <div className="file-text">
            <strong> File:</strong>{' '}
          </div>
          <div
            className={fileNameBgColor(item.flag)}
            data-toggle="tooltip"
            title={this.fileName(item.fileName)}
          >
            {this.fileName(item.fileName)}
          </div>

          <div className="item fileNameCon"> {item.name}</div>
          <div className="item fileType"> {item.type} </div>
          <div className="item">
            {' '}
            {moment(new Date(`${item.date}`)).format('MM/DD/YYYY')}
          </div>
        </div>

        <div className="rightContent">
          <div
            className={statusColor(item.approvedStatus)}
            data-toggle="tooltip"
            title="Approved Status"
          >
            {item.approvedStatus}
          </div>
          <div className="right-item">
            <i className="arrow-icon fa fa fa-caret-right" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FileHeader);
