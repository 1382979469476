import React from 'react';
import { Pagination } from 'react-bootstrap';

class UsersListPagination extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
    this.handlePagination = this.handlePagination.bind(this);
    this.pagination = this.pagination.bind(this);
    this.setPage = this.setPage.bind(this);
  }

  pagination(activePage1) {
    this.props.callback({
      activePage: activePage1
    });
  }

  setPage(activePage1) {
    this.setState({
      activePage: activePage1
    });
  }

  handlePagination(e) {
    let page = e.target.text || e.target.textContent;
    let { itemsPerPage, totalCount } = this.props;

    const totalPages = Math.ceil(totalCount / itemsPerPage);
    let map = {
      first: 1,
      last: totalPages,
      lastButtonText: 'Last',
      firstButtonText: 'First'
    };
    // use map later
    if (page === map.firstButtonText) {
      page = map.first;
    } else if (page === map.lastButtonText) {
      page = map.last;
    }
    let activePage = Number(page);

    this.setPage(activePage);
    this.pagination(activePage);
  }

  render() {
    let { activePage } = this.state;
    let { itemsPerPage, totalCount } = this.props;
    let totalPages = Math.ceil(totalCount / itemsPerPage);
    return (
      <div className="pagination-container">
        <Pagination
          first={totalPages > 1 ? 'First' : false}
          last={totalPages > 1 ? 'Last' : false}
          items={totalPages}
          maxButtons={10}
          boundaryLinks={true}
          ellipsis={true}
          activePage={activePage}
          onClick={e => {
            if (e.target.classList.contains('disabled')) {
              return false;
            }
            this.handlePagination(e);
          }}
        />
      </div>
    );
  }
}

export default UsersListPagination;
