import React from 'react';
import SettingForm from './components/SettingForm.jsx';
import './settings.scss';

class Settings extends React.PureComponent {
  render() {
    return (
      <div id="SettingsView" className="page">
        <SettingForm />
      </div>
    );
  }
}

export default Settings;
