import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

const AdminHeader = props => {
  return (
    <div className="admin-header">
      <div className="container-fluid">
        <Row>
          <Col sm={props.isBrandView ? 9 : 11}>
            <p>
              <img
                className="sitenalysis_logo"
                src={`${process.env.PUBLIC_URL}/siteNalysis_Logo.png`}
                alt="SiteNalysis"
              />
            </p>
          </Col>
          {props.isBrandView && (
            <Col sm={2}>
              <div className="admin-logout">
                <Link to="/admin/confirm">
                  <Button className="logout-button" bsStyle="default">
                    <i className="pe-7s-user" />
                    Admin/Brand User
                  </Button>
                </Link>
              </div>
            </Col>
          )}
          <Col sm={1}>
            <div className="admin-logout">
              <Link to="/login?session=logout">
                <Button className="logout-button" bsStyle="default">
                  <span className="btn-label">
                    <i className="pe-7s-close-circle" />
                  </span>
                  Log out
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default withRouter(AdminHeader);
