import React from 'react';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import { history } from '../configureStore';
import { Link } from 'react-router-dom';
import WelcomeModal from './WelcomeModal';
// test
import './privacypolicy.scss';

class Privacypolicy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
  }

  handleAcceptClick(e) {
    e.preventDefault();
    let user = Auth.getUser();
    console.log('user', user);
    let userId = user._id;
    omni_axios_instance
      .put(`updateUserPolicy/${userId}`, {
        policyFlag: false
      })
      .then(response => {
        history.push('/dashboard/summary');
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    let user = Auth.getUser();
    return (
      <div className="container" id="privacy-policy">
        <div className="privacy-policy__block">
          <div className="privacy-policy__heading">
            <div className="privacy-policy__heading_logo">
              <img src="/siteNalysis_Logo.png" alt="sitenalysis-logo" />
            </div>
            <div className="privacy-policy__heading_text">
              <h6>Welcome to Sitenalysis</h6>
              <span className="text__style">
                SiteNalysis Terms and <br />
                Conditions of Service
              </span>
            </div>
          </div>
          <div className="privacy-policy__terms">
            <strong>1. Information about SiteNalysis.</strong>
            <br />
            <br />
            1.1. The SiteNalysis Service is provided by OC ONLINE INC. (dba
            “SiteNalysis” or “OPT”) (hereinafter referred to as "SiteNalysis”),
            a private company incorporated in Delaware and headquartered at 275
            Madison Avenue – 6th Floor, New York, NY 10017.
            <br />
            <br />
            <strong>2. Interpretation.</strong>
            <br />
            <br />
            2.1. Unless the context requires otherwise, the following
            definitions apply to these Terms of Service:
            <br />
            2.1.1. "Account" means the account you create in order to use the
            Service;
            <br />
            2.1.2. "Confidential Information" means any and all information that
            is identified as confidential or proprietary by either party or by
            the nature of which such information is clearly confidential or
            proprietary;
            <br />
            2.1.3. "Web Data" means the data and any content made available on
            publicly accessible networks that you have authorized SiteNalysis to
            research and process on your behalf as a result of your use of the
            Service;
            <br />
            2.1.4. "Service" means the data research, aggregation and data
            display software that SiteNalysis makes available as a service via
            the Website;
            <br />
            2.1.5. "Terms of Service" means these terms and conditions of
            service as amended from time to time;
            <br />
            2.1.6. "Virus" mean anything or device (including any software,
            code, file or program) which may: impair, prevent or otherwise
            adversely affect the operation of any computer hardware, software,
            or network, any telecommunications service, equipment or network or
            any other service or device; impair, prevent or otherwise adversely
            affect access to or the operation of any program or data, including
            the reliability of any date or program (whether by rearranging,
            altering or erasing the program or data in whole or part or
            otherwise); or adversely affect the user experience, including
            worms, viruses, trojan horses and other similar things or devices;
            <br />
            2.1.7. "Website" means SiteNalysis.com; 
            <br />
            2.1.8. "you" or "your" means the user of the Service and jointly
            includes the company or other entity that has legally authorized you
            as an individual to utilize the Service on its behalf; and
            <br />
            2.1.9. “We” or “Us” means SiteNalysis.
            <br />
            <br />
            <strong>3. Use of the Service.</strong>
            <br />
            <br />
            3.1 Grant of License. SiteNalysis grants you a non-exclusive,
            non-transferable, personal and non sub-licensable license
            (“License”) to use the Service, as permitted by the functionality of
            the Service, for the purpose of researching, formatting, presenting
            and processing Web Data using the Service and in order to make
            available management of your Account.
            <br />
            3.2. Your Account. You must have an Account in order for you to be
            able to use the Service. The Account is personal to you and your
            company and you are responsible for maintaining the confidentiality
            of login details for your Account, including not disclosing any
            information hereon to any third parties.
            <br />
            3.3. Passwords. SiteNalysis will assign you a password. You may
            request a change in your password by emailing info@SiteNalysis.com
            with your request If you suspect that the login details for your
            Account have been misused please contact SiteNalysis at
            info@SiteNalysis.com.
            <br />
            3.4. Responsibility. You agree that you are and are legally
            authorized by your company to be a user and, along with your
            company, are jointly responsible for any and all activity made from
            or via your Account.
            <br />
            3.5. Your Responsibility. You agree that your use of the
            technologies, tools, applications, and products made available from
            time to time on the Website and through the Services are used solely
            in conjunction with the researching and processing of data from
            publicly accessible websites. In all cases where copyrighted and or
            confidential data may be included as part or all of the researched
            or processed data (including any Web Data or content that you retain
            or copy in connection with your use of the Service), you agree to
            comply with all copyright laws and rules covering such data
            including, but not limited to, the reproduction and resale of such
            data. In all cases where personal data, may be included as part or
            all of the researched or processed data or data that you enter into
            the Service, you agree to comply with all applicable regulations and
            laws in all respects.
            <br />
            3.6. Reasonable precautions to prevent abuse of Account. You must
            take all reasonable precautions in order to prevent any unauthorized
            access to or use of the Service and, in the event of any such
            unauthorized access or use, you are obligated to immediately notify
            SiteNalysis hereof in writing at info@SiteNalysis.com.
            <br />
            3.7. Third party claims You shall indemnify and defend SiteNalysis,
            its agents, contractors and subcontractor from and against any and
            all losses, damages, claims, liabilities or expenses, including
            reasonable lawyer’s fees, arising out of a claim brought by a third
            party relating to your use of the Service except to the extent
            caused by SiteNalysis’s gross negligence.
            <br />
            <br />
            <strong>4. Confidential and personal data.</strong>
            <br />
            <br />
            4.1. Confidential Information. Subject to the express permissions of
            these Terms, each Party will protect each other’s Confidential
            Information from unauthorized use, access or disclosure in the same
            manner as each protects its own Confidential Information, but with
            no less than reasonable care. Except as otherwise expressly
            permitted pursuant to these Terms, each of us may use each other’s
            Confidential Information solely to exercise our respective rights
            and perform our respective obligations under these Terms and shall
            disclose such Confidential Information (a) solely to those of our
            respective employees, representatives, subcontractors and agents who
            have a need to know such Confidential Information for such purposes
            and who are bound to maintain the confidentiality of, and not
            misuse, such Confidential Information; (b) as necessary to comply
            with an order or subpoena of any administrative agency or court of
            competent jurisdiction; or (c) as reasonably necessary to comply
            with any applicable law or regulation.
            <br />
            4.2. Personal Data.
            <br />
            We collect certain information (which may include Personal Data)
            about You and Your Agents as well as Your and their respective
            devices, computers, and use of the Service. We use, disclose, and
            protect this information as described in Our Privacy Policy, the
            current version of which is available at SiteNalysis/privacy-policy
            and is incorporated into these Terms by this reference. In providing
            the Service, SiteNalysis will engage entities within SiteNalysis and
            other authorized third party service providers, to process Personal
            Data pursuant to these Terms. You agree that SiteNalysis may also
            use your email address to send you other messages, including
            information about the Website, Services, updates, news, events, and
            special offers. You may opt out of such email by changing your
            preferences or sending an email to info@SiteNalysis.com.
            <br />
            4.3. Data Security. SiteNalysis will maintain commercially
            reasonable administrative, physical and technical safeguards to
            protect the security, confidentiality and integrity of Personal
            Data.
            <br />
            4.4. Third Party Service Providers. You agree that We and the
            service providers that We utilize to assist in providing the
            Services to You shall have the right to access Your Account and to
            use, modify, reproduce, distribute, display and disclose Service
            Data to the extent necessary to provide the Services, including,
            without limitation, in response to Your support requests. Any third
            party service providers We utilize will only be given access to Your
            Account, Service Data, or Personal Data as is reasonably necessary
            to provide the Service and will be subject to confidentiality
            obligations which are commercially reasonable and substantially
            consistent with the standards described in these Terms.
            <br />
            <br />
            <strong>5. Rights of SiteNalysis.</strong>
            <br />
            <br />
            5.1. Deactivation of Accounts. SiteNalysis has the right to at any
            time and at its sole discretion to deactivate any Account or Account
            logon method, if SiteNalysis has reasonable suspicion in its sole
            judgment that you have failed to comply with any of these Terms of
            Service or in case SiteNalysis determines to discontinue the
            Service. 5.2. Changes to the Service.
            <br />
            In order to be able to improve the Service, you agree that the
            Service may change from time to time and no warranty, representation
            or other commitment is given to sustain any elements of, or
            functionality of, the Service.
            <br />
            5.3. Right to monitor. SiteNalysis may monitor your use of the
            Service to ensure quality, improve the Service, and verify your
            compliance with these Terms of Service.
            <br />
            <br />
            <strong>6. License.</strong>
            <br />
            <br />
            6.1. By accepting these Terms of Service, SiteNalysis grants you a
            worldwide, non-exclusive, non-transferrable, non-sublicensable,
            terminable license to use the Service, including but not limited to
            any updates to the Service that SiteNalysis may make available from
            time to time for the sole purpose of using the Service in accordance
            with clause 3.1 and in accordance with these Terms of Service.
            <br />
            <br />
            <strong>7. Your Obligations.</strong>
            <br />
            <br />
            7.1. You: 7.1.1. agree that by using the Service you are obligated
            to comply with any and all applicable laws and regulations in force
            with respect to your use of the Service and your activities
            performed under these Terms of Service;
            <br />
            7.1.2. are only to use the Service in accordance with these Terms of
            Service;
            <br />
            7.1.3. must ensure that your systems and network comply with the
            relevant specifications provided by SiteNalysis from time to time;
            <br />
            7.1.4. are solely reliable for obtaining and maintaining at all
            times, all necessary licenses, consents, and permissions necessary
            for SiteNalysis to perform its obligations under these Terms of
            Service;
            <br />
            7.1.5. are solely responsible for procuring and maintaining your
            network connections and telecommunications access points and links
            from your own systems in order to access and use the Service.
            <br />
            7.1.6. must not use any automated system such as without limitation
            robots, spiders or offline readers to access the Service in a manner
            that sends more request messages to the Service than a human can
            reasonably produce in the same period of time; and
            <br />
            7.1.7. must not attempt to interfere with or compromise the Service
            security or integrity.
            <br />
            7.2. SiteNalysis may make available updates to the Service. To the
            extent applicable, You shall install each update as soon as
            reasonably practicable after notification of an update’s
            availability and in accordance with the instructions given by
            SiteNalysis at the time each update is made available.
            <br />
            <br />
            <strong>8. Your Limitations.</strong>
            <br />
            <br />
            8.1. You are obligated to ensure that that any use of the Service
            authorized, directed or undertaken by You does not in any way access
            or process Web Data that may:
            <br /> 8.1.1. contain content which is defamatory of any person,
            obscene, offensive, hateful or inflammatory, promote sexually
            explicit material or promote violence or promote discrimination
            based on race, sex, religion, nationality, disability, sexual
            orientation or age;
            <br />
            8.1.2. infringe on the personal data rules in force from time to
            time in any jurisdiction in which SiteNalysis is based or you intent
            to process any such personal data;
            <br />
            8.1.3. be likely to deceive any person or which is made in breach of
            any legal duty owed to a third party such as a duty of confidence or
            a contractual duty or promote any form of illegal activity;
            <br />
            8.1.4. infringe or be considered an infringement on any intellectual
            property right of any person, including but not limited to
            copyright, patent right, database right, trademark right, design
            right;
            <br />
            8.1.5. be threatening, abusive or invasive of another individual’s
            privacy, or cause annoyance, inconvenience or needless anxiety or be
            likely to harass, upset, embarrass, alarm or annoy any other
            individual;
            <br />
            8.1.6. be used to impersonate any person, or to be able to mislead
            any third party with regards to your identity or affiliation with
            any person or is able to give the impression that they originate
            from SiteNalysis, as long as this is not the case;
            <br />
            8.1.7. be used to promote, support or assist any unlawful act such
            as but not limited to copyright infringements and negligent acts; or
            <br />
            8.1.8. be used to distribute, access, transmit or store any Virus
            using the Service.
            <br />
            8.2. You agree that any failure to comply with this clause 8 will
            constitute a material breach of these Terms of Service, and that
            such failure may result in SiteNalysis at its sole discretion and
            determination taking all or any of the following actions: 8.2.1.
            immediately, temporarily or permanently withdraw any or all rights
            to use the Service;
            <br />
            8.2.2. issue a warning;
            <br />
            8.2.3. initiate legal action against you or any affiliate including
            proceedings for reimbursement of all costs and expenses hereunder
            but not limited to reasonable administrative and legal costs
            incurred by us as a result of the breach; or
            <br />
            8.2.4. disclose such information to law enforcement authorities as
            we reasonably believe is necessary.
            <br />
            8.3. By using the Service, you acknowledge and agree that the
            actions described in this clause are unlimited, and we may take any
            other action we reasonably deem appropriate.
            <br />
            <br />
            <strong>9. Intellectual Property Rights.</strong>
            <br />
            <br />
            9.1. SiteNalysis is the owner of or the licensor to any and all
            intellectual property rights vested in the Service. Such works are
            protected by national and international law, and all such rights are
            reserved.
            <br />
            9.2. You shall not, except as may be allowed by any applicable law
            which may not be excluded by SiteNalysis:
            <br />
            9.2.1. attempt to copy, modify, duplicate, create derivative works
            of, frame, mirror, republish, download, display, transmit, or
            distribute all or any portion of the Service in any form or media or
            by any means, except to the extent expressly permitted under these
            Terms of Service;
            <br />
            9.2.2. attempt to decompile, disassemble, reverse engineer or
            otherwise reduce to human- perceivable form all or any part of the
            Service;
            <br />
            9.2.3. access all or any part of the Service in order to build a
            product or service which competes or is to co-exist with the
            Service, or any other SiteNalysis product or service existing at any
            point in time;
            <br />
            9.2.5. license, sell, resell, rent, lease, transfer, assign,
            distribute, display, disclose, or otherwise commercially exploit, or
            otherwise make the Service available to any third party,
            <br />
            9.2.4. use the Service to provide similar or like services to third
            parties; or
            <br />
            9.2.6. attempt to obtain, or assist third parties in obtaining,
            access to the Service other than as provided under this clause 9.
            <br />
            9.3. By using this Service, you grant SiteNalysis (x) a license to
            access your Web Data and (y) to use the name and logo of your
            company as a customer reference on SiteNalysis, SiteNalysis related
            websites and in SiteNalysis marketing material. You may at all times
            withdraw your consent hereto to the usage detailed in (y) of this
            section without any consequences for the use of the Service. Any
            withdrawal of consent to the usage detailed in (y) of this section
            must be made in writing to SiteNalysis.
            <br />
            <br />
            <strong>10. Web Data.</strong>
            <br />
            <br />
            10.1. You warrant that you have all necessary rights to use the Web
            Data in the manner contemplated by you and for us to process the Web
            Data on your behalf in providing the Service.
            <br />
            10.2. You agree and acknowledge that you do not claim any ownership
            over the Web Data that is researched and processed. Web Data is
            archived after four weeks and deleted permanently from the
            SiteNalysis servers after six months.
            <br />
            10.3. You are solely liable for ensuring that that your use of the
            Service to research and process Web Data does not infringe any
            copyright, database right, trademark or any other intellectual
            property right of any person or, in any way, infringe the terms of
            any license you may have in respect of any Web Data.
            <br />
            10.4. You acknowledge and explicitly agree that SiteNalysis shall
            not be responsible or liable for the deletion, correction,
            alteration, destruction, damage, loss, disclosure or failure to
            store any Web Data, neither directly nor indirectly, regardless of
            the cause hereto.
            <br />
            <br />
            <strong>11. Notice and Takedown Policy.</strong>
            <br />
            <br />
            11.1. You are welcome to contact SiteNalysis by sending us a notice
            of infringement if that person considers that the Service infringes
            on their rights or fails to comply with the above clause 10 or
            otherwise. The notice must be sent by post to OC ONLINE INC., 275
            Madison Avenue – 6th Floor, New York, NY 20017 ATTENTION: LEGAL
            DEPT. - COPYWRIGHT INFRINGEMENT and it must contain the following
            information: name and contact details on the infringed person; a
            statement explaining in detail why the Service is considered
            infringing on the rights or fails to comply with clause 10 or
            otherwise; and a link to or such other means of identifying the
            problematic content of feature.
            <br />
            11.2. We will take the action that we deem appropriate depending on
            the nature of the notice and we will respond to the notice within a
            reasonable period of time. Our response will contain information on
            the action we propose to take.
            <br />
            <br />
            <strong>12. Confidential Information.</strong>
            <br />
            <br />
            12.1. Each party may be given access to Confidential Information
            from the other party in order to perform its obligations under these
            Terms of Service. A party’s Confidential Information shall not be
            deemed to include information that:
            <br />
            12.1.1. is or becomes publicly known other than through any act or
            omission of the receiving party;
            <br />
            12.1.2. is lawfully disclosed to the receiving party by a third
            party without restriction on disclosure;
            <br />
            12.1.3. was in the other party’s lawful possession prior to the
            disclosure;
            <br />
            12.1.4. is developed independently by the receiving party and such
            independent development may be shown by written evidence; or
            <br />
            12.1.5. is required to be disclosed by law or virtue, by any court
            of competent jurisdiction or by any regulatory or administrative
            body.
            <br />
            12.2. Each party shall hold the other’s Confidential Information
            confidential and, unless required by law or regulations, not make
            the other’s Confidential Information available for use for any
            purpose other than as needed to perform these Terms of Service.
            <br />
            12.3. Each party shall take any and all reasonable steps to ensure
            that the other party’s Confidential Information whereto is given
            access is not disclosed or distributed by it or its employees,
            agents or other affiliates in violation of these Terms of Service.
            <br />
            12.4. Each party shall make back-ups of its’ own Confidential
            Information and shall not be responsible to the other for any loss,
            destruction, alteration or disclosure of Confidential Information.
            <br />
            <br />
            <strong>13. Support and Availability.</strong>
            <br />
            <br />
            13.1. Support services for the Service are provided entirely at
            SiteNalysis’s option and discretion.
            <br />
            <br />
            <strong>14. Termination.</strong>
            <br />
            <br />
            14.1. On termination of these Terms of Service for any reason, all
            licenses granted under these Terms of Service shall immediately
            terminate and your right to access and use the Service will
            immediately be terminated and thus ended.
            <br />
            <br />
            <strong>15. Limited Warranty.</strong>
            <br />
            <br />
            15.1. The Service is provided on an "AS IS" and "AS AVAILABLE"
            basis. SiteNalysis gives no representations, warranties, conditions
            or other terms of any kind in respect of the Service, whether
            express or implied, including, but not limited to, warranties of
            satisfactory quality, availability, merchantability fitness for a
            particular purpose or non-infringement.
            <br />
            15.2. SiteNalysis shall in no event be liable to you or anyone else
            for any consequential losses, including without limitation, any
            direct, indirect, incidental or special damages, including but not
            limited to any lost profits, revenues or trading losses arising out
            of or relating to the use or inability to use the software, services
            or licensed information or any decision made or action taken by you
            or anyone else in reliance on the software, services or licensed
            information, even if advised of the possibility of such damages.
            <br />
            15.3. Except as expressly and specifically provided for in these
            Terms of Service you assume sole responsibility for results obtained
            from the use of the Service and for any and all conclusions drawn or
            decisions taken based upon such use and you rely on the results
            obtained from the use of the Service at your own risk.
            <br />
            15.4. You assume sole responsibility for any and all
            representations, warranties, conditions and all other terms of any
            kind whatsoever implied by statute or common law are, to the fullest
            extent permitted by law, excluded from these Terms of Service.
            <br />
            SiteNalysis will not be responsible for any interruptions, delays,
            failures or non-availability affecting the Service or the
            performance of the Service which are caused by third party services
            or errors or bugs in software, hardware or the internet on which
            SiteNalysis relies to provide the Service. You acknowledge that
            SiteNalysis does not control such third party services and that such
            errors and bugs are inherent in the use of such software, hardware
            and the Internet.
            <br />
            15.6. SiteNalysis is not responsible for any damage to your
            computer, tablet, mobile device, software, modem, telephone or other
            property resulting from your use of the software, Services or the
            licensed information.
            <br />
            15.7. Some jurisdictions do not allow the exclusion or limitation of
            warranties or incidental or consequential damages, so the above
            limitations or exclusions may not apply to you.
            <br />
            15.8. Without limiting anything contained in this agreement, in no
            event will SiteNalysis’s liability arising out of this agreement
            exceed the amount of fees paid by or on behalf of end user hereunder
            during the most recent twelve month period. The limitations of
            damages set forth above are fundamental elements of the basis of the
            bargain between SiteNalysis and you. SiteNalysis would not have
            provided the Services or the software or licensed information
            without such limitations.
            <br />
            <br />
            <strong>16. SiteNalysis's liability.</strong>
            <br />
            <br />
            16.1. SiteNalysis will not be liable for any losses (whether direct
            or indirect) that result from SiteNalysis’s failure to comply with
            these Terms of Service, tort (including negligence) or otherwise.
            <br />
            16.2. Nothing in these Terms of Service excludes or limits
            SiteNalysis’s liability for death or personal injury caused by
            SiteNalysis’s negligence or for fraud or fraudulent
            misrepresentation.
            <br />
            <br />
            <strong>17. Written communications.</strong>
            <br />
            <br />
            17.1. As applicable laws may require that information or
            communications SiteNalysis sends to you are in writing, you accept
            that any and all communication with SiteNalysis when using the
            Service, is mainly electronic. SiteNalysis will whenever possible
            contact you by e-mail or provide you with information by posting
            notices on the Service. For contractual purposes, you agree to
            electronic means of communication and you acknowledge that all
            contracts, notices, information and other communications that
            SiteNalysis provides to you electronically comply with any legal
            requirement that such communications be in writing and that your
            acceptance in the form of use constitutes any and all such
            signatures as are necessary.
            <br />
            <br />
            <strong>18. Notices </strong>
            <br />
            <br />
            18.1. All notices given by you to SiteNalysis must be given to
            info@SiteNalysis.com. SiteNalysis may give notice to you by e-mail
            or the postal address you provide to SiteNalysis, or any other way
            SiteNalysis deems appropriate. Notice will be deemed received and
            properly served immediately when posted on the Service or 24 hours
            after an e-mail is sent or three days after the date of posting of
            any letter. In proving the service of any notice, it will be
            sufficient to prove, in the case of a letter, that such letter was
            properly addressed, stamped and placed in the post and, in the case
            of an e-mail that such e-mail was sent to the specified e-mail
            address of the addressee. You are solely responsible for being able
            to receive mailing on the listed addresses.
            <br />
            <br />
            <strong>19. Transfer of rights and obligations.</strong>
            <br />
            <br />
            19.1. It is not allowed for you to transfer, assign, charge or
            otherwise deal in these Terms of Service, or any of your rights or
            obligations arising under these Terms of Service, without
            SiteNalysis’s prior written consent.
            <br />
            <br />
            <strong>20. Events outside SiteNalysis's control.</strong>
            <br />
            <br />
            20.1. No party shall be liable to the other for delay or
            non-performance of its obligations under these Terms of Service
            arising from any cause beyond its reasonable control including, but
            not limited to telecommunications failure, internet failure, acts of
            God, governmental acts, war, fire, flood, explosion, civil commotion
            or any other force majeure event.
            <br />
            <br />
            <strong>21. Severability.</strong>
            <br />
            <br />
            21.1. If any provision of these Terms of Service is judged to be
            illegal or unenforceable, the continuation in full force and effect
            of the remainder of the provisions shall not be prejudiced.
            <br />
            <br />
            <strong>22. Waiver.</strong>
            <br />
            <br />
            22.1. A party’s failure to exercise or a delay in exercising any
            right, power or privilege under this Agreement shall not operate as
            a waiver, nor shall any single or partial exercise of any right,
            power or privilege preclude any other or further exercise thereof.
            <br />
            <br />
            <strong>23. Change in the Terms of Service.</strong>
            <br />
            <br />
            23.1. SiteNalysis has at its sole discretion, the right to amend and
            perform revision of these Terms of Service from time to time, in
            order for the Terms of Service to reflect any and all changes in the
            Service or market conditions which may affect SiteNalysis’s
            business.
            <br />
            23.2. You will be subject to the Terms of Service in force at any
            time that you make use of the Service, or if SiteNalysis notifies
            you of changes to these Terms of Service and you continue to use the
            Service you will be subject to those changes.
            <br />
            23.3. SiteNalysis will use reasonable endeavors to notify you of any
            material changes to these Terms of Service by email or by the
            placement of a notice on the Service and any such notification shall
            constitute an agreed change to the Terms of Service.
            <br />
            <br />
            <strong>24. Law and jurisdiction.</strong>
            <br />
            <br />
            24.1. This Agreement shall be governed by, and construed in
            accordance with, the Laws of the State of New York without regard to
            conflict of law principles thereof. Each of the Parties hereto
            irrevocably submits to the jurisdiction of the Commercial Part of
            the New York State Supreme Court located in New York County and the
            United States District Court for the Southern District of New York,
            for the purposes of any suit, action or other proceeding arising out
            of this Agreement or any transaction contemplated hereby.
            <br />
            <br />
            <strong>25. Section headings.</strong>
            <br />
            <br />
            25.1. The section headings used in this Agreement are intended for
            convenience only and shall not be deemed to supersede or modify any
            provisions.
            <br />
            <br />
            082919
          </div>
          <form className="privacy-policy__form">
            {user.policyFlag == true ? (
              <button
                type="submit"
                className="btn btn-default  privacy-policy__form__accept-btn"
                onClick={this.handleAcceptClick}
              >
                I Accept
              </button>
            ) : (
              <Link
                to="/dashboard/summary"
                className="btn btn-default go-back-btn"
              >
                Go Back
              </Link>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default Privacypolicy;
