import {
  SET_ACTIVE_RETAILER,
  FETCH_RETAILER_REQUEST,
  FETCH_RETAILER_SUCCESS,
  FETCH_RETAILER_FAILURE
} from './actionTypes';

/*
 * action creators
 */
export function setActiveRetailer(retailer) {
  return {
    type: SET_ACTIVE_RETAILER,
    retailer
  };
}

export function doFetchRetailer(rId) {
  return {
    type: FETCH_RETAILER_REQUEST,
    rId
  };
}

export function doFetchRetailerSuccess(payload) {
  return {
    type: FETCH_RETAILER_SUCCESS,
    payload
  };
}

export function doFetchRetailerError(error) {
  return {
    type: FETCH_RETAILER_FAILURE,
    error
  };
}
