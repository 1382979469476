import React from 'react';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import _ from 'lodash';
// react component that creates a switch button that changes from on to off mode
import Card from 'dashboard/components/Card/Card.jsx';
import ImageLoader from 'dashboard/components/ImageLoader';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import Helpers from 'utils/helpers';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import moment from 'moment';
const retailerProductThArray = [
  {
    name: 'Product Image',
    sort: false
  },
  {
    name: 'Name',
    sort: 'name',
    order: 'asc',
    isActive: false
  },
  {
    name: 'Price',
    sort: 'price',
    order: 'asc',
    isActive: false
  },
  {
    name: 'Retailer Sku',
    sort: 'style_number',
    order: 'asc',
    isActive: false
  },
  {
    name: 'Brand Sku',
    sort: 'style_number',
    order: 'asc',
    isActive: false
  },
  {
    name: 'Period',
    sort: false
  },
  {
    name: 'Details',
    sort: false
  }
];

class ProductPreviousVersions extends React.PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      tableHeadOpts: retailerProductThArray,
      products: [],
      isLoading: false,
      activeProduct: ''
    };
    this.createTableData = this.createTableData.bind(this);
  }

  componentDidMount() {
    this.getPreviousProducts(this.props);
    this._isMounted = true;
  }
  componentWillReceiveProps(nextPorps) {
    this.getPreviousProducts(nextPorps);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getPreviousProducts(props) {
    this.setState({ isLoading: true });

    let user = Auth.getUser();
    let { _id: bId } = user.brand;
    let { pId } = props.match.params;
    let { retailer: rId } = props.retailer;

    let apiEndpoint = `/getRelatedProducts?id=${pId}&brand=${bId}&retailer=${rId}`;
    omni_axios_instance.get(`${apiEndpoint}`).then(response => {
      if (this._isMounted) {
        this.setState({
          products: response.data,
          isLoading: false,
          activeProduct: pId
        });
      }
    });
  }

  getPreviousProductDetails(cRId, id) {
    let cId = this.props.retailer._id;
    let url = `/dashboard/retailer/${cId}/product/${id}`;
    this.props.history.replace(url);
  }

  createTableData() {
    let { products: productsList } = this.state;
    if (_.isEmpty(productsList)) {
      return (
        <tr key={'0'}>
          <td className="text-center" colSpan="6">
            <div>No product(s) found!</div>
          </td>
        </tr>
      );
    }

    var tableRows = [];
    productsList.forEach((prop, key) => {
      let { activeProduct } = this.state;
      let { retailer } = prop.retailer_brand_scraping.retailer_scraping;
      let { approvedDate } = prop.retailer_brand_scraping;
      tableRows.push(
        <tr
          key={key}
          className="previousProductsTable-Row"
          style={{ backgroundColor: activeProduct === prop._id && '#80808024' }}
        >
          <td>
            <div className="img-container Thumbnail-Image margin-auto">
              <div
                onClick={() =>
                  this.getPreviousProductDetails(retailer._id, prop._id)
                }
                className="color-black item"
              >
                <ImageLoader
                  openInNewTab={false}
                  zoomable={false}
                  image={prop.image}
                  className="img-responsive"
                />
              </div>
            </div>
          </td>
          <td>
            <div
              className={classNames({
                'color-green': prop.type === 'change',
                item: true
              })}
              onClick={() =>
                this.getPreviousProductDetails(retailer._id, prop._id)
              }
            >
              {prop.name}
            </div>
          </td>
          <td>{Helpers.setProductPrice(prop.price)}</td>
          <td>{prop.style_number}</td>
          <td>
            {(prop.product && prop.product.sku) || (prop.brand_sku || 'N/A')}
          </td>
          <td>
            <div>
              {moment(new Date(`${approvedDate}`)).format('MM/DD/YYYY')}
            </div>
          </td>
          <td>
            <div
              className="item"
              onClick={() =>
                this.getPreviousProductDetails(retailer._id, prop._id)
              }
            >
              Link
            </div>
          </td>
        </tr>
      );
    });

    return tableRows;
  }

  render() {
    let { isLoading } = this.state;
    let { tableHeadOpts } = this.state;

    let thHtml = tableHeadOpts.map((prop, key) => {
      return (
        <th key={`pdtr-${key}`}>
          <div className="thRow-Inner">
            <span className="col">{prop.name}</span>
          </div>
        </th>
      );
    });

    let tbHtml = '';
    if (isLoading) {
      tbHtml = (
        <tr className="Products-Loader">
          <td colSpan="6">
            <i className="fa fa-spin fa-spinner fa-2x fa-fw" />
          </td>
        </tr>
      );
    } else {
      tbHtml = this.createTableData();
    }

    return (
      <div className="previous-product-table-data">
        <Grid fluid>
          <Row>
            <Col md={12} className="previous-product-con">
              <Card
                textCenter
                tableFullWidth
                content={
                  <div>
                    <h4 className="title">Product History</h4>

                    {/* <ProductPagination productTableList={true} /> */}
                    <Table
                      responsive
                      className="previousProductsTable table-bigboy"
                    >
                      <thead className="previousProductsTableHead">
                        <tr>{thHtml}</tr>
                      </thead>
                      <tbody className="previousProductsTableBody">
                        {tbHtml}
                      </tbody>
                    </Table>
                    {/* <ProductPagination /> */}
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(ProductPreviousVersions);
