import React from "react";
import _ from "lodash";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Truncate from "react-truncate";
import EditRetailerPopUp from "./EditRetailerPopUp";
import { omni_axios_instance } from "../../utils/axios";

class RetailerList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRetailer: "",
      showModal: false,
    };
    this.showPopUpModal = this.showPopUpModal.bind(this);
    this.hidePopUpModal = this.hidePopUpModal.bind(this);
    this.handleRetailerDelete = this.handleRetailerDelete.bind(this);
  }

  async handleRetailerDelete(e, retailer) {
    e.preventDefault();
    let id = retailer._id;
    let result = window.confirm("Confirm Deletion ?");
    // console.log("result: ", result);
    if (result) {
      await omni_axios_instance
        .delete(`https://api.pricescraper.net/retailers/${id}`)
        .then((response) => {
          console.log("response:", response);
        })
        .catch((error) => console.log(error));
      this.props.getRetailers();
    }
  }

  showPopUpModal(e, retailers) {
    e.preventDefault();
    this.setState({
      showModal: true,
      selectedRetailers: retailers,
    });
  }
  hidePopUpModal() {
    this.setState({
      showModal: false,
    });
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  };

  selectedRetailerList = (props) => {
    let selectedRetailers = props.retailers || [];
    let retailerList = [];
    if (_.isEmpty(selectedRetailers)) {
      return false;
    }
    _.map(selectedRetailers, (item, key) => {
      return item.flag && retailerList.push(`${item.retailer.name}`);
    });
    let filteredList = retailerList.sort().join(", ");
    return (
      <Truncate
        lines={1}
        width={540}
        ellipsis={
          <span className="ellipsis-con">
            ...
            <Button onClick={(e) => this.showPopUpModal(e, props)}>
              view more
            </Button>
          </span>
        }
      >
        <span className="retailerText">{`${filteredList}`}</span>
      </Truncate>
    );
  };

  render() {
    let { showModal, selectedRetailers } = this.state;
    let { brands, retailers, getBrands } = this.props;

    const columns = [
      {
        Header: "Name",
        accessor: "name", // String-based value accessors!
        className: "table-cell-edit",
        minWidth: 150,
      },
      {
        Header: "Opt ID",
        accessor: "optId", // String-based value accessors!
        className: "table-cell-edit",
      },
      {
        Header: "Slug",
        accessor: "slug", // String-based value accessors!
        className: "table-cell-edit",
      },
      {
        Header: "Website",
        accessor: "website", // String-based value accessors!
        minWidth: 200,
        className: "table-cell-edit",
        style: { overflowWrap: "anywhere" },
        // Cell: (props) => {
        //   return this.selectedRetailerList(props.original);
        // },
      },
      {
        Header: "Action",
        accessor: "retailes",
        filterable: false,
        className: "table-cell-edit",
        // minWidth: 60,
        // maxWidth: 60,
        Cell: (props) => (
          <>
            <span onClick={(e) => this.showPopUpModal(e, props.original)}>
              <i className="fa fa-edit" />
            </span>
            <span onClick={(e) => this.handleRetailerDelete(e, props.original)}>
              <i className="fa fa-trash" />
            </span>
          </>
        ),
      },
      // {
      //   Header: "Action",
      //   accessor: "retailes",
      //   filterable: false,
      //   className: "table-cell-edit",
      //   Cell: (props) => (
      //     <span onClick={(e) => this.handleRetailerDelete(e, props.original)}>
      //       <i className="fa fa-trash" />
      //     </span>
      //   ),
      // },
    ];

    return (
      <div className="container retailer-list">
        <h2 className="text-left">Retailer List </h2>
        <div className="back-btn-div">
          <Link className="back-btn" to="/admin">
            <i className="fa fa-angle-left" aria-hidden="true" />
            <span>Back</span>
          </Link>
          <label onClick={(e) => this.showPopUpModal(e, "")}>
            <i className="fa fa-plus" aria-hidden="true" />
            <span>Add</span>
          </label>
        </div>
        <div className="retailer-users-table">
          <ReactTable
            data={retailers}
            columns={columns}
            defaultPageSize={100}
            filterable={true}
            minRows={0}
            showPaginationTop={false}
            defaultFilterMethod={(filter, row) =>
              this.filterCaseInsensitive(filter, row)
            }
            className="-striped"
          />
        </div>

        {showModal && (
          <EditRetailerPopUp
            show={showModal}
            retailer={selectedRetailers}
            hide={this.hidePopUpModal}
            getRetailers={this.props.getRetailers}
          />
        )}
      </div>
    );
  }
}

export default RetailerList;
