import React from 'react';
import { connect } from 'react-redux';
import doFetchPostOfficeData from 'admin/thunks/thunkFetchPostOfficeData';
import { withRouter } from 'react-router';

class Sorting extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  handleChange(e, sortOrFilter) {
    this.props.doFetchPostOfficeData({
      [sortOrFilter]: e.target.value
    });
  }

  handleChangeSelect = selectedOption => {
    this.setState({ selectedOption });
    // console.log(`Option selected:`, selectedOption);
  };

  render() {
    let { filters } = this.props.filters;
    let sortedBy = filters.sort || '';
    let filteredBy = filters.approvedStatus || '';
    let flaggedBy = filters.flag || '';
    return (
      <div className="po-sorting-con">
        <p className="po-header-label">Sort by</p>
        <p className="po-sort-by-dropdown-wrapper">
          <select
            className="po-sort-select"
            name="sortByDropdown"
            value={sortedBy}
            onChange={e => this.handleChange(e, 'sort')}
          >
            <option value="date;desc">Date: Desc</option>
            <option value="date;asc">Date: Asc</option>
            <option value="name;desc">Name: Desc</option>
            <option value="name;asc">Name: Asc</option>
            <option value="type;desc">Robot Type: Desc</option>
            <option value="type;asc">Robot Type: Asc</option>
          </select>
        </p>
        <p className="po-header-label">Status</p>
        <p className="po-filter-by-dropdown-wrapper">
          <select
            className="po-filter-select"
            name="filterByDropdown"
            value={filteredBy}
            onChange={e => this.handleChange(e, 'approvedStatus')}
          >
            <option value="">All</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
        </p>
        <p className="po-header-label">Flag by</p>
        <p className="po-filter-by-dropdown-wrapper">
          <select
            className="po-filter-select"
            name="filterByDropdown"
            value={flaggedBy}
            onChange={e => this.handleChange(e, 'flag')}
          >
            {' '}
            <option value="">All</option>
            <option value="require_review">Require Review</option>
            <option value="caution">Caution</option>
            <option value="unflagged">Un-Flagged</option>
          </select>
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.admin.postOffice
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doFetchPostOfficeData: atts => {
      dispatch(doFetchPostOfficeData(atts));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sorting)
);
