import React from 'react';
import { Modal } from 'react-bootstrap';

class AddEditUserSuccessfullModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    };
    this.onModalHide = this.onModalHide.bind(this);
  }

  onModalHide() {
    this.setState({
      showModal: false
    });
    this.props.callbackForAddEdit(false);
  }

  render() {
    let { showModal } = this.state;
    let { mode } = this.props;
    console.log('mode', mode);
    return (
      <Modal
        id="success-modal"
        show={showModal}
        onHide={this.onModalHide}
        className={mode === 'edit' ? 'update-success' : 'add-success'}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {mode === 'edit' ? (
            <h5>User information has been updated successfully</h5>
          ) : (
            <h5>New user has been created</h5>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddEditUserSuccessfullModal;
