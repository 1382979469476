import React from "react";
import { Pagination } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import thunkFetchProducts from "dashboard/thunks/thunkFetchProducts";
import {
  doFetchProducts,
  doFetchProductsFullfilled,
  doFetchProductsError,
} from "./actions/products";
import "./ProductPagination.scss";
import $ from "jquery";
import FileUploadModal from "./components/FileUploadModal";
import { omni_axios_instance } from "../utils/axios";

class ProductPagination extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.page || 1,
    };
    this.handlePagination = this.handlePagination.bind(this);
    // console.log("props: ", this.props);
  }

  componentWillReceiveProps(nextProps) {
    // This will help to sync store and local state
    if (nextProps.page) {
      this.setState({
        activePage: nextProps.page,
      });
    }
  }

  handlePagination(e) {
    let page = e.target.text || e.target.textContent;
    let { itemsPerPage, totalProducts } = this.props;
    const totalPages = Math.ceil(totalProducts / itemsPerPage);
    let map = {
      first: 1,
      last: totalPages,
      lastButtonText: "Last",
      firstButtonText: "First",
    };
    // use map later
    if (page === map.firstButtonText) {
      page = map.first;
    } else if (page === map.lastButtonText) {
      page = map.last;
    }
    const activePage = Number(page);
    this.setState(
      {
        activePage,
      },
      () => {
        this.props.fetchProducts({
          page: activePage,
          IS_PAGINATION_FLAG: true, // Needed in reducer fn `products`
        });
      }
    );
  }

  render() {
    let { itemsPerPage, totalProducts } = this.props;
    // let contactBtn = "";
    const totalPages = Math.ceil(totalProducts / itemsPerPage);

    // If 1 page, then hide
    if (totalPages < 2) {
      return null;
    }

    return (
      <div className="Pagination-Container">
        <Pagination
          first={totalPages > 1 ? "First" : false}
          last={totalPages > 1 ? "Last" : false}
          items={totalPages}
          maxButtons={10}
          boundaryLinks={true}
          ellipsis={true}
          activePage={this.state.activePage}
          onClick={(e) => {
            if (e.target.classList.contains("disabled")) {
              return false;
            }
            this.handlePagination(e);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.items,
    itemsPerPage: state.products.filters.limit,
    totalProducts: state.products.totalProducts,
    page: state.products.filters.page,
    retailerScrappingInfoData: state.retailerScrappingInfoData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProducts: (value) => {
      dispatch(thunkFetchProducts(value));
    },
    doFetchProducts: (value) => {
      dispatch(doFetchProducts(value));
    },
    doFetchProductsFullfilled: (value) => {
      dispatch(doFetchProductsFullfilled(value));
    },
    doFetchProductsError: (value) => {
      dispatch(doFetchProductsError(value));
    },
  };
};

ProductPagination.propTypes = {
  activePage: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPagination);
