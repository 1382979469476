import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { omni_axios_instance } from 'utils/axios';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';

const SignupSchema = Yup.object().shape({
  sitenalysis_Password: Yup.string()
    .min(4, 'Too Short!')
    .max(12, 'Too Long!')
    .required('Required'),
  sitenalysis_ConfirmPassword: Yup.string()
    .min(4, 'Too Short!')
    .max(12, 'Too Long!')
    .required('Required')
});

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isTokenValid: false,
      isResetPasswort: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // let AUTH_TOKEN = Auth.getAccessToken();
    // console.log('AUTH_TOKEN',AUTH_TOKEN)
    console.log('this.props.location', this.props.location);
    let pathName = this.props.location.pathname.indexOf('reset-password') > -1;
    this.setState(
      {
        isResetPasswort: pathName
      },
      () => {
        omni_axios_instance
          .get(`/validateToken${this.props.location.search}`)
          .then(res => {
            if (res.status !== 200) {
              toastr.error('ERROR', 'Your Session has been expired');
              // window.location.href = '/';
              this.props.history.push('/');
            }
          });
      }
    );
  }
  handleClick(values, actions) {
    if (values.sitenalysis_Password !== values.sitenalysis_ConfirmPassword) {
      return (
        actions.setSubmitting(false), alert('please confirm your password')
      );
    }

    // let AUTH_TOKEN = Auth.getAccessToken();
    // console.log(this.props, 'props');

    omni_axios_instance
      .post(`/resetPassword${this.props.location.search}`, {
        password: values.sitenalysis_ConfirmPassword
      })
      .then(res => {
        if (res.status === 200) {
          toastr.success(
            'SUCCESS',
            'Your Password has been Successfully reset'
          );
          this.props.history.push('/');
          actions.setSubmitting(false);
        }
      });
  }

  render() {
    let { isResetPasswort } = this.state;
    return (
      <div id="reset-password">
        <div className="container reset-password">
          <div className="reset-password-content">
            <h2 className="form-heading">
              {isResetPasswort ? 'RESET PASSWORD' : 'ACTIVATE ACCOUNT'}
            </h2>
            <Formik
              initialValues={{
                sitenalysis_Password: '',
                sitenalysis_ConfirmPassword: ''
              }}
              validationSchema={SignupSchema}
              onSubmit={this.handleClick}
              render={({ errors, touched, isSubmitting }) => (
                <Form id="reset-password-form">
                  <div className="form-group">
                    <Field
                      type="password"
                      name="sitenalysis_Password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <ErrorMessage
                      name="sitenalysis_Password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      type="password"
                      name="sitenalysis_ConfirmPassword"
                      className="form-control"
                      placeholder="Confirm Password"
                    />
                    <ErrorMessage
                      name="sitenalysis_ConfirmPassword"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary reset-password-btn"
                      disabled={isSubmitting}
                    >
                      {isResetPasswort ? 'Reset Password' : 'Set Password'}
                    </button>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
