import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { withRouter } from 'react-router';
import ReactTooltip from 'react-tooltip';
import Button from 'components/CustomButton/CustomButton.jsx';
import { toastr } from 'react-redux-toastr';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import { ChromePicker } from 'react-color';
import reactCSS from 'reactcss';
class BrandSettingForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: '#333131',
      textColor: '#ffffff',
      hoverColor: '#ffffff3b',
      summaryPageSectionColor: '#333131',
      toggle_bgColor_picker: false,
      toggle_textColor_picker: false,
      toggle_hoverColor_picker: false,
      toggle_summaryPageSectionColor_picker: false,
      CompanyEmailBcc: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInputField = this.handleInputField.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
  }

  componentWillMount() {
    // let formVals = LocalStorage._getValueFromStore('settingsForm');
    let USER = Auth.getUser();
    let theme = USER.brand.colors;
    let bccVal = USER.brand.company_email_bcc;
    this.setState({
      bgColor: theme.bgColor,
      textColor: theme.textColor,
      hoverColor: theme.hoverColor,
      summaryPageSectionColor: theme.summaryPageSectionColor,
      CompanyEmailBcc: bccVal
    });
    // this.props.initialize(formVals);
  }

  handleChange(color, targetPicker = '') {
    this.setState({
      [targetPicker]: color.hex
    });
  }

  handleClick(targetPicker = '') {
    this.setState({
      [targetPicker]: !this.state[targetPicker]
    });
  }

  handleClose(targetPicker = '') {
    this.setState({
      [targetPicker]: false
    });
  }
  handleInputField(e) {
    this.setState({
      CompanyEmailBcc: e.target.value
    });
  }
  saveSettings(e) {
    e.preventDefault();
    let USER = Auth.getUser();
    let {
      bgColor,
      textColor,
      hoverColor,
      summaryPageSectionColor,
      CompanyEmailBcc
    } = this.state;

    let colors = {
      bgColor,
      hoverColor,
      textColor,
      summaryPageSectionColor
    };

    let values = {
      colors,
      company_email_bcc: CompanyEmailBcc
    };
    omni_axios_instance
      .put(`/brands/${USER.brand._id}`, values)
      .then(response => {
        this.props.history.push('/login');
        toastr.success('SUCCESS', 'Settings updated.');
      })
      .catch(error => {
        // https://github.com/axios/axios#handling-errors
      });

    // LocalStorage._setValueInStore(values, 'settingsForm');
  }

  render() {
    const { submitting } = this.props;

    let {
      bgColor,
      textColor,
      hoverColor,
      summaryPageSectionColor,
      toggle_bgColor_picker,
      toggle_textColor_picker,
      toggle_hoverColor_picker,
      toggle_summaryPageSectionColor_picker,
      CompanyEmailBcc
    } = this.state;

    const styles = reactCSS({
      default: {
        bgColor: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${bgColor}`
        },
        textColor: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${textColor}`
        },
        hoverColor: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${hoverColor}`
        },
        summaryPageSectionColor: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${summaryPageSectionColor}`
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        },
        popover: {
          position: 'absolute',
          zIndex: '2'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    });

    return (
      <form onSubmit={this.saveSettings}>
        <FormGroup>
          <ControlLabel>Background color (site color)</ControlLabel>
          <div className="Color-Field_bgColor">
            <div
              style={styles.swatch}
              onClick={() => this.handleClick('toggle_bgColor_picker')}
            >
              <div style={styles.bgColor} />
            </div>
            {toggle_bgColor_picker ? (
              <div style={styles.popover}>
                <div
                  style={styles.cover}
                  onClick={() => this.handleClose('toggle_bgColor_picker')}
                />
                <ChromePicker
                  color={bgColor}
                  onChange={color => this.handleChange(color, 'bgColor')}
                />
              </div>
            ) : null}
          </div>
        </FormGroup>

        <FormGroup>
          <ControlLabel>Sidebar Text color</ControlLabel>
          <div className="Color-Field_textColor">
            <div
              style={styles.swatch}
              onClick={() => this.handleClick('toggle_textColor_picker')}
            >
              <div style={styles.textColor} />
            </div>
            {toggle_textColor_picker ? (
              <div style={styles.popover}>
                <div
                  style={styles.cover}
                  onClick={() => this.handleClose('toggle_textColor_picker')}
                />
                <ChromePicker
                  color={textColor}
                  onChange={color => this.handleChange(color, 'textColor')}
                />
              </div>
            ) : null}
          </div>
        </FormGroup>

        <FormGroup>
          <ControlLabel>Sidebar Text hover color</ControlLabel>
          <div className="Color-Field_hoverColor">
            <div
              style={styles.swatch}
              onClick={() => this.handleClick('toggle_hoverColor_picker')}
            >
              <div style={styles.hoverColor} />
            </div>
            {toggle_hoverColor_picker ? (
              <div style={styles.popover}>
                <div
                  style={styles.cover}
                  onClick={() => this.handleClose('toggle_hoverColor_picker')}
                />
                <ChromePicker
                  color={hoverColor}
                  onChange={color => this.handleChange(color, 'hoverColor')}
                />
              </div>
            ) : null}
          </div>
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            Summary Page center section background color
          </ControlLabel>
          <div className="Color-Field_bgColor">
            <div
              style={styles.swatch}
              onClick={() =>
                this.handleClick('toggle_summaryPageSectionColor_picker')
              }
            >
              <div style={styles.summaryPageSectionColor} />
            </div>
            {toggle_summaryPageSectionColor_picker ? (
              <div style={styles.popover}>
                <div
                  style={styles.cover}
                  onClick={() =>
                    this.handleClose('toggle_summaryPageSectionColor_picker')
                  }
                />
                <ChromePicker
                  color={summaryPageSectionColor}
                  onChange={color =>
                    this.handleChange(color, 'summaryPageSectionColor')
                  }
                />
              </div>
            ) : null}
          </div>
        </FormGroup>

        <FormGroup>
          <ControlLabel>company email bcc</ControlLabel>
          <span data-tip="To bcc email address(es) on all outbound emails sent via the Retailer Contact Form, add the desired email address(es) below.">
            <img
              className="img-icon"
              src={`${process.env.PUBLIC_URL}/info-icon.png`}
              alt="info-icon"
            />
          </span>
          <FormControl
            name="CompanyEmailBcc"
            disabled={false}
            value={CompanyEmailBcc || ''}
            className="form-control"
            onChange={this.handleInputField}
            type="text"
          />
          <ReactTooltip
            className="omni__tooltip omni__tooltip--right"
            place="right"
            type="light"
            effect="solid"
          />
        </FormGroup>

        <FormGroup>
          <Button bsStyle="info" fill type="submit" disabled={submitting}>
            Update
          </Button>
        </FormGroup>
      </form>
    );
  }
}

export default withRouter(BrandSettingForm);
