import React from 'react';
import ReactTable from 'react-table';
// import isEmpty from 'lodash/isEmpty';
import { omni_axios_instance } from 'utils/axios';

const SearchedItems = props => {
  let headerLabel;
  let { filterType, selectionStatus, results } = props;

  if (filterType === 'retailer' || filterType === '') {
    headerLabel = 'Brands Retailer Carries';
  } else {
    headerLabel = 'Retailers that Carry Brand';
  }

  let columns = [
    {
      Header: (
        <div>
          {headerLabel}
          <span>&#8693;</span>
        </div>
      ),
      accessor: 'name' // String-based value accessors!
    },
    {
      Header: (
        <div>
          Currently Enabled in Dashboard<span>&#8693;</span>
        </div>
      ),
      accessor: 'value',
      filterable: false
    }
  ];

  const onExportBtnClick = () => {
    let { filterType, selectionStatus } = props;
    let url;
    let __id = selectionStatus.value;
    if (filterType === 'retailer') {
      url = `/BrandRetailerExport?retailer=${__id}&exportFlag=true`;
    } else {
      url = `/BrandRetailerExport?brand=${__id}&exportFlag=true`;
    }
    omni_axios_instance
      .get(url)
      .then(response => {
        window.location = response.data.url;
      })
      .catch(error => console.log(error));
  };

  const onSort = (a, b, desc) => {
    a = a === null || a === undefined ? '' : a;
    b = b === null || b === undefined ? '' : b;
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };

  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  };

  //<span style='font-size:100px;'>&#8593;</span>  -- to show ascending by default
  //<span style='font-size:100px;'>&#8595;</span>   -- to show descending

  return (
    <div className="searchedItems">
      {selectionStatus && (
        <h4>
          <span>Search Results for</span> '{selectionStatus.label}'
        </h4>
      )}
      <div className="tableInfo">
        <div className="tableCount">Count: {results.length}</div>
        <div>
          <button
            className="btn btn-primary export-btn"
            onClick={onExportBtnClick}
          >
            Export
            <i className="fa fa-file-excel-o" />
          </button>
        </div>
      </div>
      <div className="retailer-users-table">
        <ReactTable
          data={results}
          columns={columns}
          defaultPageSize={20}
          filterable={true}
          minRows={0}
          showPaginationTop={false}
          defaultFilterMethod={(filter, row) =>
            filterCaseInsensitive(filter, row)
          }
          defaultSortMethod={onSort}
          className="-striped"
        />
      </div>
    </div>
  );
};

export default SearchedItems;
