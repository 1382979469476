import SummaryView from 'dashboard/SummaryView.jsx';
import SummaryViewV2 from 'dashboard/SummaryViewV2.jsx';
import Inbox from 'dashboard/inbox';
import Settings from 'dashboard/settings';
// import ManageCatalog from 'dashboard/manage-catalog';

var dashRoutes = [
  {
    path: '/dashboard/summary',
    name: 'Summary view',
    icon: 'fa fa-th',
    component: SummaryViewV2
  },
  {
    path: '/dashboard/stats',
    name: 'Retailer List',
    icon: 'pe-7s-graph',
    component: SummaryView
  },
  {
    path: '/dashboard/profile/settings',
    name: 'Settings',
    icon: 'pe-7s-tools',
    component: Settings
  },
  {
    path: '/dashboard/message-inbox',
    name: 'Inbox',
    icon: 'pe-7s-mail',
    component: Inbox
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/dashboard',
    name: 'Dashboard'
  }
];

export default dashRoutes;
