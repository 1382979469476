import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_UPDATE,
} from "dashboard/actions/actionTypes";
import omit from "lodash/omit";

let initialState = {
  items: [],
  filters: {
    page: 1,
    limit: 25,
  },
  isLoading: false,
  errors: [],
  currentCount: 0, // total products for this page/iteration
  next: 0, // next page
  totalCount: 0, // total products
};

// default remove unwanted fields
function _doCleanUp(atts) {
  return omit(atts, ["IS_SORT_FLAG", "IS_PAGINATION_FLAG", "IS_REFRESH_FLAG"]);
}

// Reset filters to original state and add new filters as per tab changes
function _doReset(oldAtts, newAtts) {
  // console.log({
  //   oldAtts,
  //   newAtts
  // })
  let keysToCheck = ["IS_SORT_FLAG", "IS_PAGINATION_FLAG", "IS_REFRESH_FLAG"];
  if (keysToCheck.some((k) => k in newAtts)) {
    return Object.assign({}, oldAtts, newAtts);
  }
  // console.log("new atts : ", newAtts)
  // else remove tab specific old filters / reset filters
  let atts = omit(oldAtts, ["tab", "isFlagged"]);
  if (!newAtts.hasOwnProperty("page")) {
    newAtts.page = 1;
  }
  // merge new filters
  atts = Object.assign({}, atts, newAtts);
  return atts;
}

function products(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      // `action.atts` is nothing but latest filter
      // parse new filters
      // console.log({
      //   filters: state.filters,
      //   atts: action.atts
      // })
      let filters = _doReset(state.filters, action.atts);
      // console.log({
      //   newFilters: filters,
      // });
      filters = _doCleanUp(filters);

      return {
        ...state,
        isLoading: true,
        errors: [],
        filters,
      };

    case FETCH_PRODUCTS_SUCCESS:
      let { data } = action.payload;
      // console.log("payload: ", action.payload);
      return Object.assign({}, state, {
        isLoading: false,
        errors: [],
        items: data.products,
        totalProducts: data.totalCount,
        currentProducts: data.currentCount,
      });
    case FETCH_PRODUCTS_UPDATE:
      let { _id, map_id, dataUpdated } = action.payload;
      return Object.assign({}, state, {
        isLoading: false,
        errors: [],
        items: dataUpdated.products.map((product) => {
          if (product._id === _id) {
            return {
              ...product,
              oh_map_id: map_id,
            };
          }
          return product;
        }),
        totalProducts: dataUpdated.totalCount,
        currentProducts: dataUpdated.currentCount,
      });

    case FETCH_PRODUCTS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        items: [],
        errors: action.payload
          ? action.payload.errors
          : ["Oops, blank response!"],
      });

    default:
      return state;
  }
}

export default products;
