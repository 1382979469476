import {
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILURE
} from '../actions/actionTypes';
let initialState = {
  filters: {
    page: 1,
    limit: 20
  },
  data: {},
  errors: []
};

function notification(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATION_REQUEST:
      return { ...state, errors: [] };

    case FETCH_NOTIFICATION_SUCCESS:
      let { data } = action.payload;
      return Object.assign({}, state, { data: data });

    case FETCH_NOTIFICATION_FAILURE:
      return Object.assign({}, state, {
        data: {},
        errors: action.payload
          ? action.payload.errors
          : ['Oops, blank response!']
      });

    default:
      return state;
  }
}

export default notification;
