import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';

import {
  doFetchRetailer,
  doFetchRetailerSuccess,
  doFetchRetailerError
} from 'dashboard/actions/retailer';

// To get Retailer Scrappig List by month & brand
// RetailerScraping/:brandId?month=<mm-yyyy>
// brandId >> you'll get from user.brand._id

export default function thunkFetchRetailer(rId) {
  return function(dispatch, getState) {
    dispatch(doFetchRetailer(rId));
    let user = Auth.getUser();
    let brandId = user.brand._id;

    return omni_axios_instance
      .get(`/RetailerScraping/${brandId}/${rId}`)
      .then(response => {
        dispatch(doFetchRetailerSuccess(response));
      })
      .catch(error => dispatch(doFetchRetailerError(error.xhr)));
  };
}
