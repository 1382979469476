import React from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

const AdminComponent = (props) => {
  return (
    <Row>
      <Col sm={3}>
        <div className="admin-con-options">
          <h5 className="title">PostOffice</h5>
          <ul>
            <li className="list-group-item">
              <Link to="/admin/post-office"> Go to PO </Link>
            </li>

            <li className="list-group-item">
              <Link to="/admin/post-office/settings">Go to PO Admin</Link>
            </li>
            <li className="list-group-item">
              <Link to="/admin/post-office/notification">
                Go to Notifications &nbsp;
                <Badge>{props.unseenCount}</Badge>
              </Link>
            </li>
          </ul>
        </div>
      </Col>
      <Col sm={3}>
        <div className="admin-con-options">
          <h5 className="title">Retailers</h5>
          <ul>
            <li className="list-group-item">
              <Link to="/admin/user-retailer-association">Manage Brands</Link>
            </li>
            {/* <li className="list-group-item">
              <Link to="/admin/manage-users">Manage Users</Link>
            </li> */}
            <li className="list-group-item">
              <Link to="/admin/permissions">Permissions</Link>
            </li>
            <li className="list-group-item">
              <Link to="/admin/retailers">Manage Retailers</Link>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default AdminComponent;
