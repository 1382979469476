import {
  FETCH_MONTHS_REQUEST,
  FETCH_MONTHS_SUCCESS,
  FETCH_MONTHS_FAILURE
} from './actionTypes';

/*
 * get months for current brand
 */
export function doFetchMonths(atts) {
  return {
    type: FETCH_MONTHS_REQUEST,
    atts
  };
}

export function doFetchMonthsSuccess(payload) {
  return {
    type: FETCH_MONTHS_SUCCESS,
    payload
  };
}

export function doFetchMonthsFailure(error) {
  return {
    type: FETCH_MONTHS_FAILURE,
    error
  };
}
