import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import doFetchPostOfficeData from 'admin/thunks/thunkFetchPostOfficeData';
import isEmpty from 'lodash/isEmpty';
import ProductPagination from './Pagination';
import FileHeader from './FileHeader';
import queryString from 'querystringify';
import ContentLoader from 'react-content-loader';
import './post-office.scss';
import { FormControl, Alert } from 'react-bootstrap';

import { omni_axios_instance } from 'utils/axios';

const FileListLoader = props => (
  <ContentLoader
    height={250}
    width={500}
    speed={2}
    primaryColor="#ded7d759"
    secondaryColor="#efefef"
    {...props}
  >
    <rect x="250" y="15" rx="0" ry="0" width="500" height="15" />
    <rect x="0" y="45" rx="0" ry="0" width="500" height="15" />
    <rect x="0" y="65" rx="0" ry="0" width="500" height="15" />
    <rect x="0" y="85" rx="0" ry="0" width="500" height="15" />
    <rect x="0" y="105" rx="0" ry="0" width="500" height="15" />
    <rect x="250" y="140" rx="0" ry="0" width="500" height="15" />
  </ContentLoader>
);

class FilesList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: true,
      activeIndex: '',
      isLoader: false,
      retailers: []
    };
    this.handleApproveBtnClick = this.handleApproveBtnClick.bind(this);
    this.handleNeedAttentionBtnClick = this.handleNeedAttentionBtnClick.bind(
      this
    );
  }
  fetchRetailers() {
    omni_axios_instance.get('/retailers').then(res => {
      // console.log('brands', res);
      this.setState({
        retailers: res.data.sort((a, b) => (a.name < b.name ? -1 : 1))
      });
    });
  }
  componentDidMount() {
    let fId = this.props.match.params.fId;
    // console.log(this.props);
    let q1;
    if (fId) {
      q1 = queryString.parse(this.props.location.search);
      this.setState({ activeIndex: fId });
    }
    let atts = fId ? q1 : '';
    this.props.doFetchPostOfficeData(atts);
    this.fetchRetailers();
  }

  handleApproveBtnClick = e => {
    e.stopPropagation();
    // console.log('click handleApproveBtnClick');
  };

  handleNeedAttentionBtnClick = e => {
    e.stopPropagation();
    // console.log('click handleNeedAttentionBtnClick');
  };

  // diffOfProducts(productData) {
  //   console.log(productData);
  // }
  render() {
    const { activeIndex, retailers } = this.state;
    const { files, isLoading, doFetchPostOfficeData, filters } = this.props;

    if (isLoading) {
      return (
        <div className="po-products text-center">
          <FileListLoader />
        </div>
      );
    }

    if (isEmpty(files)) {
      return (
        <div className="row">
          <Filter className="col-sm-6 col-sm-offset-6" />
          &nbsp;
          <div className="col-sm-12">
            <Alert variant="info">No files remains for comparision.</Alert>
          </div>
          {/* <div
            className="alert alert-info"
            style={{ marginTop: "unset" }}
          >
            No files remains for comparision.
          </div> */}
        </div>
      );
    }
    function Filter({ className }) {
      return (
        <div className={className} style={{ padding: '20px' }}>
          <div className="col-sm-6">
            {' '}
            <label>Retailer:</label>&nbsp;&nbsp;
            <select
              className="po-sort-select"
              onChange={e =>
                doFetchPostOfficeData({
                  ...filters,
                  fileName: '',
                  name: e.target.value,
                  page: 1
                })
              }
              value={filters.name}
            >
              <option value="all">All</option>
              {retailers.map(retailer => (
                <option value={retailer.slug} key={retailer._id}>
                  {retailer.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-6">
            {' '}
            {filters.fileName ? (
              <span
                className="badge badge-pill badge-light"
                style={{ cursor: 'pointer' }}
                onClick={e =>
                  doFetchPostOfficeData({
                    ...filters,
                    fileName: '',
                    page: 1
                  })
                }
              >
                {filters.fileName} X
              </span>
            ) : (
              ''
            )}
            <FormControl
              name="Search Robot File"
              className="form-control"
              // value={filters.fileName}
              onKeyUp={e => {
                if (e.keyCode !== 13) return;
                doFetchPostOfficeData({
                  ...filters,
                  name: '',
                  fileName: e.target.value
                });
              }}
              type="text"
            />
          </div>
        </div>
      );
    }
    return (
      <div className="po-products">
        <div className="row">
          <div className="col-sm-6">
            <ProductPagination />
          </div>
          <Filter className="col-sm-6" />
        </div>
        <div className="po-products-con">
          {files.map((item, index) => {
            // let isOpened = activeIndex === item._id;
            return (
              <FileHeader key={index} activeIndex={activeIndex} item={item} />
            );
          })}
        </div>

        <ProductPagination />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    files: state.admin.postOffice.data.data,
    filters: state.admin.postOffice.filters,
    isLoading: state.admin.postOffice.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doFetchPostOfficeData: atts => {
      dispatch(doFetchPostOfficeData(atts));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilesList)
);
