import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';

import {
  doFetchRetailerScrappingInfo,
  doFetchRetailerScrappingInfoFullfilled,
  doFetchRetailerScrappingInfoError
} from 'dashboard/actions/retailerScrappingInfo';

// To get Retailer Scrappig List by month & brand
// RetailerScraping/:brandId/:month=<mm-yyyy>
// brandId >> you'll get from user.brand._id
// and we pass month id i.e mId

export default function thunkFetchRetailersList(upcomingAtts = {}) {
  return function(dispatch, getState) {
    dispatch(doFetchRetailerScrappingInfo());
    let user = Auth.getUser();
    let brandId = user.brand._id;
    return omni_axios_instance
      .get(`/RetailerScraping/${brandId}/${upcomingAtts.mId}`)
      .then(response => {
        dispatch(doFetchRetailerScrappingInfoFullfilled(response.data));
      })
      .catch(error => dispatch(doFetchRetailerScrappingInfoError(error.xhr)));
  };
}
