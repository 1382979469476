import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import LoginModal from './login/LoginModal';
import ForgotPasswordModal from './login/ForgotPasswordModal';

// dynamically create pages routes
import pagesRoutes from './routes/pages.jsx';
import bgImage from 'assets/img/TopZone-bg2-blur.png';

class Pages extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordModal: false,
      showMobileLoginModal: false
    };
    this.forgotPasswordClick__callback = this.forgotPasswordClick__callback.bind(
      this
    );
    this.showMobileLoginModal__callback = this.showMobileLoginModal__callback.bind(
      this
    );
    this.modalClose = this.modalClose.bind(this);
    this.forgotPasswordModalClose = this.forgotPasswordModalClose.bind(this);
  }

  modalClose() {
    this.setState({ showMobileLoginModal: false });
  }

  showMobileLoginModal__callback() {
    this.setState(state => ({
      showMobileLoginModal: !state.showMobileLoginModal
    }));
  }

  forgotPasswordModalClose() {
    this.setState({ showForgotPasswordModal: false });
  }

  forgotPasswordClick__callback() {
    // Hide login dropdown first
    let dropdown = document.querySelector(
      '#login-dropdown-menu > div.dropdown'
    );
    dropdown.classList.remove('open');

    this.setState(state => ({
      showForgotPasswordModal: !state.showForgotPasswordModal
    }));
  }

  componentWillMount() {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }
  render() {
    let { showForgotPasswordModal, showMobileLoginModal } = this.state;
    return (
      <Fragment>
        <Header
          showMobileLoginModal__callback={this.showMobileLoginModal__callback}
          showForgotPasswordModal_callback={this.forgotPasswordClick__callback}
          onHide={this.modalClose}
        />
        <div className="wrapper wrapper-full-page">
          <div className={'full-page'}>
            <div className="content">
              <Switch>
                {pagesRoutes.map((prop, key) => {
                  const C = prop.component;
                  return (
                    <Route exact path={prop.path} key={key} component={C} />
                  );
                })}
              </Switch>
            </div>
            <div
              className="full-page-background"
              style={{ backgroundImage: 'url(' + bgImage + ')' }}
            />
          </div>
        </div>
        <LoginModal
          show={showMobileLoginModal}
          onHide={this.modalClose}
          showForgotPasswordModal_callback={this.forgotPasswordClick__callback}
        />
        <ForgotPasswordModal
          show={showForgotPasswordModal}
          onHide={this.forgotPasswordModalClose}
          onHideLoginModal={this.modalClose}
        />
        <Footer />
      </Fragment>
    );
  }
}

export default Pages;
