import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { OMNI_API_URL } from 'utils/constants';

const ImportExportHistory = props => {
  const getFiles = category => {
    let { downloadedFiles, uploadedFiles, loading } = props;
    let content, files, headerText, infoText, icon, col;
    if (category === 'downloaded') {
      files = downloadedFiles;
      icon = 'fa fa-arrow-down';
      headerText = 'Files that have been Downloaded';
      infoText = loading
        ? 'Loading Downloaded files...'
        : 'No Files Downloaded';
      col = 'leftCol';
    } else {
      files = uploadedFiles;
      icon = 'fa fa-arrow-up';
      headerText = 'Files that have been Uploaded';
      infoText = loading ? 'Loading Uploaded files...' : 'No Files Uploaded';
      col = 'rightCol';
    }
    if (files.length > 0) {
      content = (
        <ul>
          {files.map((file, key) => {
            return (
              <li key={file._id}>
                <a
                  href={`${OMNI_API_URL}/addressBook/downloadFile/${file._id}`}
                >
                  {file.fileName}
                </a>
              </li>
            );
          })}
        </ul>
      );
      // <div>No Downloaded files</div>
    } else content = <div className="text-center">{infoText}</div>;

    return (
      <>
        <div className="col-header">
          <i className={icon} />
          {headerText}
        </div>
        <div className={col}>{content}</div>
      </>
    );
  };
  return (
    <div className="import-export-history">
      <Row>
        <Col sm={6} className="downloaded-files">
          {getFiles('downloaded')}
        </Col>
        <Col sm={6} className="uploaded-files">
          {getFiles('uploaded')}
        </Col>
      </Row>
    </div>
  );
};
export default ImportExportHistory;
