import React from 'react';
import { withRouter } from 'react-router';
import { Grid, Row, Col, Tab, Nav, NavItem } from 'react-bootstrap';
import Card from 'dashboard/components/Card/Card.jsx';
import InviteTeamMemberModal from './InviteTeamMemberModal';
import ReportABugModal from 'dashboard/components/ReportABugModal';
import UserSettingForm from './UserSettingForm';
import BrandSettingForm from './BrandSettingForm';
import UsersTab from './users-tab/UsersTab';
import ManageReportsForm from '../../components/Forms/reports/ManageReportsForm';
import ContactTab from './address-book/ContactTab';
import Auth from 'utils/auth';

class SettingForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInviteModal: false,
      showContactModal: false,
      eventKey: 'user',
      showHidebrandTab: false
    };
    this.toggleInviteTeamMemberModal = this.toggleInviteTeamMemberModal.bind(
      this
    );
    this.toggleContactModal = this.toggleContactModal.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
  componentWillMount() {
    let USER = Auth.getUser();
    if (USER.roles.length > 0) {
      this.setState({
        showHidebrandTab: true
      });
    }
  }
  toggleInviteTeamMemberModal() {
    let { showInviteModal } = this.state;
    this.setState({
      showInviteModal: !showInviteModal
    });
  }

  handleSelect(eventKey) {
    this.setState({
      eventKey
    });
  }

  toggleContactModal() {
    let { showContactModal } = this.state;
    this.setState({
      showContactModal: !showContactModal
    });
  }

  render() {
    let {
      showInviteModal,
      showContactModal,
      eventKey,
      showHidebrandTab
    } = this.state;

    return (
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Update Settings"
              content={
                <div>
                  <Tab.Container
                    id="tabs-with-userSetting"
                    activeKey={eventKey}
                    onSelect={this.handleSelect}
                    className="setting-form"
                  >
                    <Row className="clearfix">
                      <Col sm={12} className="user-setting">
                        <Nav bsStyle="tabs">
                          <NavItem eventKey="user">
                            <h4>My Account</h4>
                          </NavItem>
                          {showHidebrandTab === true && (
                            <NavItem eventKey="brand">
                              <h4>Brand</h4>
                            </NavItem>
                          )}
                          <NavItem eventKey="reports">
                            <h4>Manage Reports</h4>
                          </NavItem>
                          <NavItem eventKey="contacts">
                            <h4>Contacts</h4>
                          </NavItem>
                          <NavItem eventKey="users">
                            <h4>Users</h4>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col sm={12} className="user-setting">
                        <Tab.Content animation={false}>
                          <Tab.Pane eventKey="user">
                            {/* <SForm onSubmit={this.saveSettings} /> */}
                            <UserSettingForm />
                          </Tab.Pane>
                          <Tab.Pane eventKey="brand">
                            <BrandSettingForm />
                          </Tab.Pane>
                          <Tab.Pane eventKey="reports">
                            <ManageReportsForm />
                          </Tab.Pane>
                          <Tab.Pane eventKey="contacts">
                            <ContactTab />
                          </Tab.Pane>
                          <Tab.Pane eventKey="users">
                            <UsersTab />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>

                  {showInviteModal && (
                    <InviteTeamMemberModal
                      callback={this.toggleInviteTeamMemberModal}
                    />
                  )}
                  {showContactModal && (
                    <ReportABugModal callback={this.toggleContactModal} />
                  )}
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(SettingForm);
