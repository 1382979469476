import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import ImageLoader from "dashboard/components/ImageLoader";
import isEmpty from "lodash/isEmpty";
// import classNames from 'classnames';
// import Masonry from 'react-masonry-component';
import Helpers from "../../utils/helpers";

/* const masonryOptions = {
  transitionDuration: 0
}; */
// const imagesLoadedOptions = { background: '.Image-Background' };

const GridLayout = (props) => {
  let { retailers, activeLayout } = props;
  let timeZone = new Date().getTimezoneOffset();
  if (isEmpty(retailers)) {
    return (
      <div className="text-left">
        <p className="alert alert-info">No Retailer(s) found.</p>
      </div>
    );
  }

  let numOfGridColumns = 4;
  if (activeLayout === "three-item-grid") {
    numOfGridColumns = 4;
  } else if (activeLayout === "two-item-grid") {
    numOfGridColumns = 6;
  } else {
    numOfGridColumns = 12;
  }
  let columnsHtml = retailers.map((item, i) => {
    let content = "";
    content = (
      <Col sm={numOfGridColumns} className="image-info" key={i}>
        <div className="image-info-inner">
          <ImageLoader
            // image={item.brand_screenshot_large || item.brand_screenshot}
            image={item.brand_screenshot}
            zoomable={false}
            isBackground
            brandSplash
            retailerId={item.retailer._id}
          />
          <div className="retailer-info">
            <div className="retailer-info-left-column">
              <p className="logo-name">
                <strong> Retailer Name: </strong>
                <span data-toggle="tooltip" title={item.retailer.name}>
                  <Link to={`/dashboard/retailer/${item.retailer._id}`}>
                    {item.retailer.name}
                  </Link>
                </span>
              </p>
              {item.brand_retailer &&
                (item.brand_retailer.webSiteType === "retailerWithProducts" ||
                  item.brand_retailer.webSiteType ===
                    "retailerWithProductsandModule") && (
                  <>
                    <p>
                      <strong> Products Listed: </strong>
                      <span> {item.totalProducts}</span>
                    </p>

                    <p>
                      <strong> Flagged Items: </strong>
                      <span> {item.flaggedProduct}</span>
                    </p>
                  </>
                )}

              <p>
                <strong> Last Scan Date: </strong>
                <span>
                  {item.approvedDate
                    ? moment(new Date(`${item.approvedDate}`))
                        .add(timeZone, "minutes")
                        .format("MMMM DD, YYYY")
                    : "N/A"}
                </span>
              </p>
              {item.brand_retailer &&
                (item.brand_retailer.webSiteType ===
                  "retailerWithoutProducts" ||
                  item.brand_retailer.webSiteType === "retailerWithModule" ||
                  item.brand_retailer.webSiteType ===
                    "retailerWithProductsandModule") && (
                  <div className="module-icon_con">
                    {Helpers.checkRetailerModule({
                      isModule:
                        item.brand_retailer &&
                        (item.brand_retailer.webSiteType ===
                          "retailerWithModule" ||
                          item.brand_retailer.webSiteType ===
                            "retailerWithProductsandModule")
                          ? true
                          : false,
                      brandName: item.brand_name,
                      imgType: "dark",
                      abcWithProduct:
                        item.brand_retailer.webSiteType ===
                        "retailerWithProductsandModule",
                    })}
                  </div>
                )}

              {item.brand_retailer &&
                item.brand_retailer.webSiteType !==
                  "retailerWithProductsandModule" && (
                  <div
                    className="module-icon_con"
                    style={{ visibility: "hidden", height: "31px" }}
                  >
                    test
                  </div>
                )}

              {/* {filterOptionsData(item)} */}
            </div>
            <div className="retailer-info-right-column">
              <Link
                to={`/dashboard/retailer/${item.retailer._id}`}
                className="btn view-retailer-stats-btn"
              >
                View Retailer Details
              </Link>
            </div>
          </div>
        </div>
      </Col>
    );

    return content;
  });

  return (
    <div className={`row ${activeLayout}`}>
      {/*  <Masonry
          className={classNames({
            row: true,
            [activeLayout]: true
          })}
          elementType={'div'}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
          imagesLoadedOptions={imagesLoadedOptions}
        >
          {columnsHtml}
        </Masonry> */}
      {columnsHtml}
    </div>
  );
};
export default GridLayout;
