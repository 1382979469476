import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
import { toastr } from 'react-redux-toastr';

class DeleteUserSuccessfullModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.onClickYesbtn = this.onClickYesbtn.bind(this);
    this.onClickNobtn = this.onClickNobtn.bind(this);
  }

  onModalHide() {
    this.setState({
      showModal: false
    });
    this.props.onHideDeleteModal(false);
  }

  onClickYesbtn() {
    let { userToDelete } = this.props;
    omni_axios_instance.delete(`/removeAndNotify/${userToDelete}`).then(res => {
      toastr.success('SUCCESS', 'User deleted successfully');
      this.props.onHideDeleteModal(false);
    });
  }

  onClickNobtn() {
    this.setState({
      showModal: false
    });
    this.props.onHideDeleteModal({
      showHideDeleteModal: false,
      refreshFlag: true
    });
  }

  render() {
    let { showModal } = this.state;
    console.log('this.props', this.props);
    return (
      <Modal id="delete-user-modal" show={showModal} onHide={this.onModalHide}>
        <Modal.Body>
          <p className="delete-user-modal__text">
            Are you sure you want to delete this user permanently?
          </p>
        </Modal.Body>
        <Modal.Footer className="delete-user-modal__footer">
          <Button
            variant="primary"
            className="yes-btn"
            onClick={this.onClickYesbtn}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="no-btn"
            onClick={this.onClickNobtn}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteUserSuccessfullModal;
