import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import _ from 'lodash';

class BrandSplashModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  slideShow = () => {
    let { imageData } = this.props;
    const { photoIndex } = this.state;

    if (_.isEmpty(imageData)) {
      return;
    }

    let tempImg = [];
    _.map(imageData, (vObj, key) => {
      // console.log(vObj, "vObj", key);

      if (key === 'primaryMenu') {
        vObj.map((item, i) => {
          return _.map(item, (primaryMenu, pKey) => {
            if (!_.isEmpty(primaryMenu)) {
              tempImg.push(primaryMenu);
            }
          });
        });
      } else {
        if (!_.isEmpty(vObj)) {
          tempImg.push(vObj);
        }
      }

      return tempImg;
    });

    return (
      <div>
        <Lightbox
          mainSrc={tempImg[photoIndex]}
          nextSrc={tempImg[(photoIndex + 1) % tempImg.length]}
          prevSrc={tempImg[(photoIndex + tempImg.length - 1) % tempImg.length]}
          onCloseRequest={() => this.props.closeSliseShow()}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + tempImg.length - 1) % tempImg.length
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % tempImg.length
            })
          }
        />
      </div>
    );
  };

  render() {
    const { isOpen } = this.props;

    return <div>{isOpen && this.slideShow()}</div>;
  }
}

export default BrandSplashModal;
