import {
  FETCH_MONTHS_REQUEST,
  FETCH_MONTHS_SUCCESS,
  FETCH_MONTHS_FAILURE
} from 'dashboard/actions/actionTypes';

let initialState = {
  items: [],
  isLoading: false,
  errors: []
};

function months(state = initialState, action) {
  switch (action.type) {
    // Rather than retailers routes, we have to empty months
    case '@@router/LOCATION_CHANGE':
      let { pathname } = action.payload;
      if (pathname && pathname.indexOf('/retailer') === -1) {
        return initialState;
      } else {
        return state;
      }

    case FETCH_MONTHS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: [],
        items: []
      });

    case FETCH_MONTHS_SUCCESS:
      let { data } = action.payload;
      return Object.assign({}, state, {
        isLoading: false,
        errors: [],
        items: data
      });

    case FETCH_MONTHS_FAILURE:
      return Object.assign({}, state, action.atts, {
        isLoading: false,
        items: [],
        errors: action.payload
          ? action.payload.errors
          : ['Oops, blank response!']
      });

    default:
      return state;
  }
}

export default months;
