import {
  SET_ACTIVE_PRODUCT,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE
} from 'dashboard/actions/actionTypes';

let initialState = {};

export default function product(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_PRODUCT:
      return Object.assign({}, action.product);

    case FETCH_PRODUCT_REQUEST:
      return Object.assign({}, initialState);

    case FETCH_PRODUCT_SUCCESS:
      let { data } = action.payload;
      return Object.assign({}, data);

    case FETCH_PRODUCT_FAILURE:
      return Object.assign({}, initialState);

    default:
      return state;
  }
}
