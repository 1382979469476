import React from "react";
import { omni_axios_instance } from "utils/axios";
import Auth from "utils/auth";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";
import BrandSplashModal from "./BrandSplashModal";
import cx from "classnames";
import GridLayout from "dashboard/components/GridLayout";
import qs from "querystringify";
import { isMobile, isTablet, isIE } from "react-device-detect";
import ContentLoader from "react-content-loader";
import Filter from "./components/Filter/Filter";
import Grid2 from "../assets/icons/grid-two-up-16.png";
import Grid3 from "../assets/icons/grid-three-up-16.png";
import Grid1 from "../assets/icons/square-16.png";

const PERPAGEITEM = 10;

class SummaryViewV2 extends React.PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    let searchParams = qs.parse(props.location.search);

    this.state = {
      retailerDataToShow: [],
      retailerData: [],
      retailerDataFilter: [],
      filter: "allProducts",
      isLoading: false,
      hasMore: false,
      activePage: 1,
      isOpen: false,
      imageData: {},
      activeLayout: searchParams.layout || "three-item-grid",
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.imageSlideShow = this.imageSlideShow.bind(this);
    this.handlePageLayout = this.handlePageLayout.bind(this);
    this.selectedFilter = this.selectedFilter.bind(this);
  }
  getActiveLayout() {
    if (isTablet) {
      this.handlePageLayout("two-item-grid");
    } else if (isMobile) {
      this.handlePageLayout("one-item-grid");
    }
  }
  componentDidMount() {
    this._isMounted = true;
    this.getData();
    this.getActiveLayout();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  selectedFilter(filter) {
    console.log("filter", filter);
    let { retailerDataToShow } = this.state;
    // console.log('filter', filter, retailerDataToShow);
    let filterData = _.filter(retailerDataToShow, (o) => {
      return filter !== "-1"
        ? o.brand_retailer && o.brand_retailer.webSiteType === filter
        : o;
    });
    this.setState({
      retailerDataFilter: filterData,
    });
  }
  handleScroll() {
    let { retailerDataToShow, retailerData, activePage } = this.state;
    if (retailerDataToShow.length >= retailerData.length) {
      this.setState({
        hasMore: false,
      });
      return;
    }
    let pageNo = activePage + 1;
    let index = pageNo * PERPAGEITEM;
    let data = retailerData.slice(0, index);
    setTimeout(() => {
      this.setState({
        retailerDataToShow: data,
        activePage: pageNo,
      });
    }, 2000);
  }
  getData(page) {
    let user = Auth.getUser();
    if (_.isEmpty(user.brand)) {
      return;
    }
    let brandId = user.brand._id;
    this.setState({
      isLoading: true,
    });

    omni_axios_instance
      .get(`/RetailerScraping/${brandId}?user=${user._id}`)
      .then((response) => {
        let getSortedData = _.sortBy(response.data, [
          function (o) {
            return o.retailer.name;
          },
        ]);
        if (this._isMounted) {
          this.setState({
            retailerDataToShow: getSortedData,
            retailerDataFilter: getSortedData,
            retailerData: getSortedData,
            isLoading: false,
            hasMore: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  imageSlideShow = (e, images) => {
    e.preventDefault();
    this.setState({
      isOpen: true,
      imageData: images,
    });
  };

  closeSliseShow = () => {
    this.setState({ isOpen: false });
  };

  updateSearch = (layout) => {
    this.props.history.push(`/dashboard/summary?layout=${layout}`);
  };

  handlePageLayout = (layout = "three-item-grid") => {
    this.setState({
      activeLayout: layout,
    });
    this.updateSearch(layout);
  };

  getContentLoader(user = {}, retailerData = {}, isLoading = false) {
    let reactContentLoader = (
      <ContentLoader
        height={440}
        width={370}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="5" ry="5" width="370" height="290" />
        <rect x="0" y="320" rx="4" ry="4" width="350" height="15" />
        <rect x="0" y="350" rx="4" ry="4" width="300" height="15" />
      </ContentLoader>
    );

    return (
      <div className="summary-view-v2">
        <div className="summary-view-v2-inner">
          <Row className="summary-view-filter-option">
            <Col md={7} sm={7}>
              <h4> Welcome {user.name} </h4>
              <p className="heading-con">
                You are currently auditing {retailerData.length} websites. Click
                any tile below for more details.
              </p>
            </Col>
            <Col md={5} sm={5}>
              <Filter selectedFilter={this.selectedFilter} />
              {this.getPageLayoutOptions()}
            </Col>
          </Row>

          {isLoading ? (
            <>
              <Row>
                <Col sm={4}>{reactContentLoader}</Col>
                <Col sm={4}>{reactContentLoader}</Col>
                <Col sm={4}>{reactContentLoader}</Col>
              </Row>
              <Row>
                <Col sm={4}>{reactContentLoader}</Col>
              </Row>
            </>
          ) : (
            <div className="text-left">
              <p className="alert alert-info text-center">
                No Retailer(s) found.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  getPageLayoutOptions = () => {
    const { activeLayout } = this.state;
    // let img =  activeOne || activeTwo || activeThree === true ? 'active' :'deactive'
    // console.log('img', img)
    const element = (
      <div className="page-layout-changer">
        {/* <div className="page-layout-changer-row-1">Select a Page Layout</div> */}
        <div className="select-page-label">Select a Page Layout:</div>
        <div className="page-layout-changer-row-2 flexbox">
          <div
            className={cx({
              "flexbox-child": true,
              active: activeLayout === "one-item-grid",
            })}
            onClick={() => this.handlePageLayout("one-item-grid")}
          >
            {/* <span
              className={cx({
                "layout-btn": !isIE,
                "layout-ie": isIE,
              })}
            > */}
            <img src={Grid1} />
            {/* </span> */}
          </div>
          <div
            className={cx({
              "flexbox-child": true,
              active: activeLayout === "two-item-grid",
            })}
            onClick={() => this.handlePageLayout("two-item-grid")}
          >
            {/* <span
              className={cx({
                "layout-btn": !isIE,
                "layout-ie": isIE,
              })}
            > */}
            <img src={Grid2} />
            {/* </span> */}
            {/* <span
              className={cx({
                "layout-btn": !isIE,
                "layout-ie": isIE,
              })}
            /> */}
          </div>
          <div
            className={cx({
              "flexbox-child": true,
              active: activeLayout === "three-item-grid",
            })}
            onClick={() => this.handlePageLayout("three-item-grid")}
          >
            {/* <span
              className={cx({
                "layout-btn": !isIE,
                "layout-ie": isIE,
              })}
            > */}
            <img src={Grid3} />
            {/* </span> */}
            {/* <span
              className={cx({
                'layout-btn': !isIE,
                'layout-ie': isIE
              })}
            />
            <span
              className={cx({
                'layout-btn': !isIE,
                'layout-ie': isIE
              })}
            /> */}
          </div>
        </div>
      </div>
    );

    return element;
  };

  render() {
    const {
      retailerDataToShow,
      retailerDataFilter,
      retailerData,
      isOpen,
      imageData,
      activeLayout,
      isLoading,
    } = this.state;

    const user = Auth.getUser();
    if (_.isEmpty(retailerDataToShow)) {
      return this.getContentLoader(user, retailerData, isLoading);
    }
    let hasMoreLoadingDone = (
      <p className="text-center">
        <b>Yay! Yeah! You’ve seen it all.</b>
      </p>
    );

    return (
      <div className="summary-view-v2">
        <div className="summary-view-v2-inner">
          <Row className="summary-view-filter-option">
            <Col md={7} sm={7}>
              <h4> Welcome {user.name} </h4>
              <p className="heading-con">
                You are currently auditing {retailerDataFilter.length} websites.
                Click any tile below for more details.
              </p>
            </Col>
            <Col md={5} sm={5}>
              <Filter selectedFilter={this.selectedFilter} />
              {this.getPageLayoutOptions()}
            </Col>
          </Row>
          <GridLayout
            retailers={retailerDataFilter}
            activeLayout={activeLayout}
          />
          {!_.isEmpty(retailerDataFilter) && hasMoreLoadingDone}
        </div>

        {isOpen && (
          <BrandSplashModal
            isOpen={isOpen}
            imageData={imageData}
            closeSliseShow={(e) => this.closeSliseShow()}
          />
        )}
      </div>
    );
  }
}

export default SummaryViewV2;
