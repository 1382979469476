import React from 'react';
import { Modal } from 'react-bootstrap';

const ViewMessageModal = props => {
  const onCloseModal = () => {
    props.onHide(false);
  };
  let { message } = props;
  return (
    <Modal
      show={props.show}
      onHide={onCloseModal}
      container={this}
      className="view-message-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>View Canned Message</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-message-modal__con">
          <div className="message-name-info">
            <label
              htmlFor="message-name"
              className="view-message-modal__con__label"
            >
              Message Name
            </label>
            <p>{message.name}</p>
          </div>
          <div className="message-status-info ">
            <label
              htmlFor="message-status-info"
              className="view-message-modal__con__label"
            >
              Status
            </label>
            <p className="message-status-info__details">{message.status}</p>
          </div>
          <div className="message-content-info">
            <label
              htmlFor="message-content-info"
              className="view-message-modal__con__label"
            >
              Message Content
            </label>
            <div className="message-content-info__details">
              <pre dangerouslySetInnerHTML={{ __html: message.content }} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewMessageModal;
