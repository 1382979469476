import React from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import Button from 'components/CustomButton/CustomButton.jsx';
import { reduxForm } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import Helpers from 'utils/helpers';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
const priceOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Price is missing', label: 'Price is missing' },
  { value: 'Wrong price', label: 'Wrong price' },
  { value: 'Discount', label: 'Discount' }
];

class WhyToFlagForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      price: false,
      Price: null,
      style: false,
      style_name: false,
      item_description: false,
      assortment_issue: false,
      image_issue: false,
      other: false,
      message: ''
    };
    this.handlePrice = this.handlePrice.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isCheckedOptions = this.isCheckedOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isCheckedOptions(e) {
    let value =
      e.target.type === 'checkbox'
        ? !this.state[e.target.name]
        : e.target.value;
    this.setState({
      [e.target.name]: value
    });
  }

  handlePrice() {
    this.setState({
      price: !this.state.price
    });
  }

  handleChange = (selectedOption, stateName) => {
    selectedOption.key = stateName.name;
    this.setState({ [stateName.name]: selectedOption });
  };

  getReasonsToFlagBrand(values) {
    let reasonsToFlag = [];
    _.forEach(values, (item, key) => {
      if ((item && !_.isEmpty(item.value)) || item === true) {
        if (key === 'Price') {
          reasonsToFlag.push({ name: 'Price', value: item.value });
        } else if (key === 'style') {
          reasonsToFlag.push({ name: 'Style #', value: '' });
        } else if (key === 'assortment_issue') {
          reasonsToFlag.push({
            name: 'Assortment Issue',
            value: 'Remove this item'
          });
        } else if (key === 'image_issue') {
          reasonsToFlag.push({
            name: 'Image Issues',
            value:
              'To report incorrect, broken or other issues relating to the item image, select this option.'
          });
        } else {
          let temp = key.split('_');
          let selectedItems = temp.map((item, index) => {
            return (
              item
                .toString()
                .charAt(0)
                .toUpperCase() + item.slice(1)
            );
          });
          reasonsToFlag.push({ name: selectedItems.join(' '), value: '' });
        }
      }
    });
    return reasonsToFlag;
  }

  handleSubmit(e) {
    e.preventDefault();
    let {
      Price,
      style,
      style_name,
      item_description,
      assortment_issue,
      image_issue,
      other,
      message
    } = this.state;

    let selectedOption = {
      Price,
      style,
      style_name,
      item_description,
      assortment_issue,
      image_issue,
      other
    };

    let messageValue = this.getReasonsToFlagBrand(selectedOption);
    if (_.isEmpty(messageValue)) {
      toastr.error('ERROR', 'Please select at least one field.');
      return;
    }
    let { pId } = this.props;
    let formData = {
      message: message,
      reason: messageValue,
      isFlagged: 'yes'
    };
    // Flag product
    Helpers.setFlaggedProduct(formData, pId).then(() => {
      // Hide modal
      // pass additional params to parent
      this.props.callback({
        isFlagged: true,
        formData,
        pId
      });
      this.props.doFetchProduct(pId);
    });
  }

  render() {
    let {
      price,
      Price,
      style,
      style_name,
      item_description,
      assortment_issue,
      image_issue,
      other,
      message
    } = this.state;

    return (
      <form className="whyToFlagForm" onSubmit={this.handleSubmit}>
        <FormGroup>
          <div className="field_wrapper">
            <input
              name="style"
              type="checkbox"
              onChange={this.isCheckedOptions}
              checked={style}
            />
            <label className="label-text">Style #</label>
            <span data-tip="Style">
              {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>

          <div className="field_wrapper">
            <input
              name="style_name"
              type="checkbox"
              onChange={this.isCheckedOptions}
              checked={style_name}
            />
            <label className="label-text">Style Name</label>
            <span data-tip="Style Name">
              {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>

          <div className="field_wrapper">
            <input
              name="item_description"
              type="checkbox"
              onChange={this.isCheckedOptions}
              checked={item_description}
            />
            <label className="label-text">Item Description</label>
            <span data-tip="Item Description">
              {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>

          <div className="field_wrapper">
            <input
              name="price"
              id="price"
              className="omni-form-field"
              type="checkbox"
              onChange={this.handlePrice}
              value={price}
            />
            <label id="price-label" htmlFor="price">
              Price
            </label>
            <span data-tip="Price">
              {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>

          {price && (
            <div className="whyToFlagForm__PriceDropdown">
              <Select
                name="Price"
                value={Price}
                onChange={this.handleChange}
                options={priceOptions}
                onChangeArguments
                required="false"
                placeholder="Select an option"
              />
            </div>
          )}

          <div className="field_wrapper">
            <input
              name="assortment_issue"
              type="checkbox"
              onChange={this.isCheckedOptions}
              checked={assortment_issue}
            />
            <label className="label-text">
              Assortment Issue - Remove this item
            </label>
            <span data-tip="Assortment Issue - Remove this item">
              {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>
          <div className="field_wrapper">
            <input
              name="image_issue"
              type="checkbox"
              onChange={this.isCheckedOptions}
              checked={image_issue}
            />
            <label className="label-text">Image Issues</label>
            <span data-tip="To report incorrect, broken or other issues relating to the item image, select this option.">
              {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>
          <div className="field_wrapper">
            <input
              name="other"
              type="checkbox"
              onChange={this.isCheckedOptions}
              checked={other}
            />
            <label className="label-text">Other</label>
            <span data-tip="Other">
              {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>

          <div className="omni-form-field comment-col">
            <ControlLabel className="col-sm-12">
              Please include additional details
            </ControlLabel>
            <textarea
              className="form-control"
              defaultValue={message}
              name="message"
              rows="4"
              cols="50"
              onChange={this.isCheckedOptions}
            />
          </div>

          <ReactTooltip
            className="omni__tooltip"
            place="right"
            type="light"
            effect="solid"
          />
        </FormGroup>
        <div className="text-right">
          <Button id="whyToFlagBtn" className="btn btn-default" type="submit">
            Save
          </Button>
        </div>
      </form>
    );
  }
}

export default (WhyToFlagForm = reduxForm({
  form: 'whyToFlagForm'
})(WhyToFlagForm));
