import React from 'react';
import ReactPaginate from 'react-paginate';

const FileViewPagniation = ({ pageCount, handlePageClick, perPage, page }) => {
  return (
    <div className="po-pagination">
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={5}
        forcePage={page}
        pageRangeDisplayed={8}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
      {/* <p>Showing {perPage} products per page</p> */}
    </div>
  );
};

export default FileViewPagniation;
