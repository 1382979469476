import React, { Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Recaptcha from 'react-recaptcha';
import { omni_axios_instance } from 'utils/axios';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Please enter valid email')
    .required('Required'),
  website: Yup.string()
    .min(4, 'Please enter valid URL')
    .required('Required'),
  companyName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phoneNumber: Yup.string()
    .max(10, 'Too Long!')
    .required('Required'),
  message: Yup.string(),
  recaptcha: Yup.string().required()
});
// const recaptchaRef = React.createRef();

class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    // this.validateForm = this.validateForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.callback = this.callback.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  onSubmit(values, action) {
    omni_axios_instance.post(`/scheduledemos`, values).then(res => {
      if (res.status === 200) {
        toastr.success('SUCCESS', 'Email sent successfully');
        this.recaptchaInstance.reset();
        action.setSubmitting(false);
        action.resetForm();
      }
    });
  }

  callback(e) {
    // console.log('Captcha', e);
    if (!_.isEmpty(e)) {
      this.recaptchaInstance.reset();
    }
  }
  verifyCallback(response) {
    // console.log('Response', response);
  }

  render() {
    const CustomInputComponent = ({
      field, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      ...props
    }) => (
      <div>
        <textarea {...field} {...props} />
      </div>
    );
    return (
      <Fragment>
        <h3 className="contact-form-heading text-center">SCHEDULE A DEMO</h3>
        <p className="contact-form-text text-center">
          Please complete our contact form and a member of our team will be glad
          to assist you.
        </p>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            website: '',
            companyName: '',
            phoneNumber: '',
            message: '',
            recaptcha: ''
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            this.onSubmit(values, actions);
          }}
          render={props => (
            <Form>
              {/* {console.log(props,'form values')} */}
              <div className="row">
                <div className="col-xs-12 col-sm-6 form-group">
                  <label>
                    First Name<sup>*</sup>
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label>
                    Last Name<sup>*</sup>
                  </label>
                  <Field type="text" name="lastName" className="form-control" />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>
                  Email<sup>*</sup>
                </label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-6 form-group">
                  <label>
                    Company Name<sup>*</sup>
                  </label>
                  <Field
                    type="text"
                    name="companyName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="col-xs-12 col-sm-6 form-group">
                  <label>
                    Phone Number<sup>*</sup>
                  </label>
                  <Field
                    type="tel"
                    name="phoneNumber"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>
                  Website<sup>*</sup>
                </label>
                <Field type="text" name="website" className="form-control" />
                <ErrorMessage
                  name="website"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="form-group">
                <label>Message (optional)</label>
                <Field
                  name="message"
                  component={CustomInputComponent}
                  className="form-control"
                >
                  {<textarea name="message" className="form-control" />}
                </Field>
              </div>
              <div className="form-group">
                <div className="recaptcha-margin-top text-center">
                  <Recaptcha
                    name="recaptcha"
                    sitekey="6LfW35AUAAAAADwojEkNqI3_35CZcqOdqQLYYR9I"
                    ref={e => (this.recaptchaInstance = e)}
                    onloadCallback={this.callback}
                    verifyCallback={response => {
                      props.setFieldValue('recaptcha', response);
                    }}
                  />
                  <ErrorMessage
                    name="recaptcha"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-blue btn-block">
                  Submit
                </button>
              </div>
            </Form>
          )}
        />

        <div className="contact-form__contact-info">
          <div className="contactForm__phoneNo">
            <a href="tel:(917)746-9804">PHONE: (917)746-9804</a>
          </div>

          <div className="contactForm__EmailId">
            <a href="mailto:info@sitenalysis.com">
              EMAIL: INFO@SITENALYSIS.COM
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ContactForm;
