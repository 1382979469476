import React from 'react';
import { Modal } from 'react-bootstrap';

class SuccessModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.show || false
    };

    this.onModalHide = this.onModalHide.bind(this);
  }

  onModalHide() {
    this.setState({
      showModal: false
    });
    this.props.callback();
  }

  render() {
    let { mode } = this.props;
    let message;
    if (mode === 'sku') {
      message = 'Your changes have been saved';
    } else {
      message = 'Your message has been sent';
    }
    return (
      <Modal
        animation={false}
        autoFocus={false}
        className="SuccessModal"
        enforceFocus={false}
        show={this.state.showModal}
        onHide={() => this.onModalHide()}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div>
            <p>{message}</p>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SuccessModal;
