import React from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import HeaderLinks from "dashboard/components/Header/HeaderLinks.jsx";
import Auth from "utils/auth";
import _ from "lodash";
// backgroundImage for Sidebar
import dashRoutes from "routes/dashboard.jsx";
// logo for sidebar
import ImageLoader from "dashboard/components/ImageLoader";

import "./sidebar.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearAllBasket } from "./../../actions/retailerBasket";
import { toastr } from "react-redux-toastr";
// sidebar style
// const bgImage = {backgroundImage: "url("+image+")"};

class Sidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/dashboard") !== "" ? true : false,
      openSummary: this.activeRoute("/dashboard/summary") !== "" ? true : false,
      openRetailerStats:
        this.activeRoute("/dashboard/stats") !== "" ? true : false,
      openRetailer:
        this.activeRoute("/dashboard/retailer") !== "" ? true : false,
      openBrands:
        this.activeRoute("/dashboard/products-list") !== "" ? true : false,
      openSocialFeeds:
        this.activeRoute("/dashboard/social-feeds") !== "" ? true : false,
      openForms: this.activeRoute("/forms") !== "" ? true : false,
      openTables: this.activeRoute("/tables") !== "" ? true : false,
      openMaps: this.activeRoute("/maps") !== "" ? true : false,
      openPages: this.activeRoute("/pages") !== "" ? true : false,
    };
    this.handleMinimizeSidebar = this.handleMinimizeSidebar.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  handleMinimizeSidebar() {
    if (window.innerWidth > 992) {
      document.body.classList.toggle("sidebar-mini");
    } else {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  toggleSidebar() {
    if (window.innerWidth < 992) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  render() {
    // get & render brand specific style
    let user = Auth.getUser();
    let logos = "";
    if (!_.isEmpty(user) && !_.isEmpty(user.brand)) {
      logos = user.brand.logo;
    }

    return (
      <div className="sidebar">
        <button
          id="minimizeSidebar"
          className="btn btn-default btn-fill btn-icon close-btn"
          onClick={this.handleMinimizeSidebar}
        >
          <i className="fa fa-close visible-on-sidebar-regular" />
          <i className="fa fa-navicon visible-on-sidebar-mini" />
        </button>
        <div className="logo-col">
          <Link
            to="/dashboard/summary"
            className="text-uppercase text-center simple-text logo"
          >
            <ImageLoader
              image={logos[0]}
              openInNewTab={false}
              zoomable={false}
              alt="brand logo"
            />
          </Link>
        </div>
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <ul className="nav">
            {/* If we are on responsive, we want both links from navbar and sidebar
                            to appear in sidebar, so we render here HeaderLinks */}
            {this.state.width <= 992 ? <HeaderLinks /> : null}
            {/*
                            here we render the links in the sidebar
                            if the link is simple, we make a simple link, if not,
                            we have to create a collapsible group,
                            with the speciffic parent button and with it's children which are the links
                        */}
            {dashRoutes.map((prop, key) => {
              var st = {};
              st[prop["state"]] = !this.state[prop.state];
              if (prop.collapse) {
                return (
                  <li className={this.activeRoute(prop.path)} key={key}>
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={() => this.setState(st)}
                    >
                      <i className={prop.icon} />
                      <p>
                        {prop.name}
                        <b
                          className={
                            this.state[prop.state]
                              ? "caret rotate-180"
                              : "caret"
                          }
                        />
                      </p>
                    </NavLink>
                    <Collapse in={this.state[prop.state]}>
                      <ul className="nav">
                        {prop.views.map((prop, key) => {
                          return (
                            <li
                              className={this.activeRoute(prop.path)}
                              key={key}
                            >
                              <NavLink
                                to={prop.path}
                                className="nav-link"
                                activeClassName="active"
                              >
                                <i className={prop.icon} />
                                {prop.mini}
                                <p className="sidebar-mini font-lighter" />
                                <p className="sidebar-normal font-lighter">
                                  {prop.name}
                                </p>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse>
                  </li>
                );
              } else {
                if (prop.redirect) {
                  return null;
                } else {
                  return (
                    <li
                      className={`${prop.name
                        .split(" ")
                        .join("-")
                        .toLowerCase()} ${this.activeRoute(prop.path)}`}
                      key={key}
                      onClick={this.toggleSidebar}
                    >
                      <NavLink
                        to={prop.path}
                        className="nav-link"
                        activeClassName="active"
                      >
                        <i className={prop.icon} />
                        <p>{prop.name}</p>
                      </NavLink>
                    </li>
                  );
                }
              }
            })}
            {
              <li
                className="inbox"
                onClick={() => {
                  this.props.clearAllBasket();
                  toastr.success("All Baskets Cleared");
                }}
              >
                <a className="nav-link" href="">
                  <i className="fa fa-trash" />
                  <p>Clear All Baskets</p>
                </a>
              </li>
            }
            {Auth.isSiteAdmin() ? (
              <li>
                <NavLink
                  to="/admin/confirm"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="pe-7s-user" />
                  <p className="sidebar-mini font-lighter " />
                  <p className="sidebar-normal font-lighter">
                    Admin/Brand User
                  </p>
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>

          {/* <div className="siteNalysisLogo">
            <div className="siteNalysisLogo-inner">
              <img
                src={`${process.env.PUBLIC_URL}/siteNalysis_Logo.png`}
                alt="SiteNalysis"
                style={{
                  marginTop: 5
                }}
              />
              <div className="powered-by">
                <sub>Powered by OPT</sub>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearAllBasket,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(Sidebar);
