import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
import _ from 'lodash';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import { toastr } from 'react-redux-toastr';

class AssignedRetailersModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.show || false,
      retailerList: [],
      selectedAll: false,
      deSelectedAll: false
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.deSelectAll = this.deSelectAll.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    let { retailers, user, mode } = this.props;
    if (mode === 'add') {
      map(retailers, (item, key) => {
        item.flag = false;
        return item;
      });
      this.setState({ retailerList: retailers });
    } else {
      let selectedRetailers = user.associated_retailers;
      retailers = sortBy(retailers, function(o) {
        return o.name;
      });
      let retailerList = [];
      map(retailers, (item, key) => {
        if (selectedRetailers.length) {
          for (var i = 0; i < selectedRetailers.length; i++) {
            if (selectedRetailers[i]._id === item.retailer._id) {
              item.flag = true;
              break;
            } else {
              item.flag = false;
              console.log('in else');
            }
          }
        } else {
          item.flag = false;
        }
        retailerList.push(item);
      });
      this.setState({ retailerList: retailerList });
    }
  }

  /* componentDidUpdate(prevProps, prevState) {
    let { selectedAll, deSelectedAll, retailerList } = this.state;
    if((prevState.selectedAll !== selectedAll) || (prevState.deSelectedAll !== deSelectedAll)) {
      let newRetailerList = [];
      forEach(retailerList, (item, key) => {
        if((selectedAll === true) && (deSelectedAll === false)) {
          item.isChecked = true;
          newRetailerList.push(item);
        } else if((deSelectedAll === true) && (selectedAll === false)){
          item.isChecked = false;
          newRetailerList.push(item);
        } else {
          newRetailerList.push(item);
        }
      });
      this.setState({
        retailerList: retailerList,
      });
    }
  } */

  handleCheckbox = e => {
    let { retailerList } = this.state;
    _.forEach(retailerList, (item, key) => {
      if (e.target.value === item._id) {
        item.flag = !item.flag;
      }
    });
    this.setState({
      retailerList: retailerList
    });
  };

  selectAll(e) {
    var textinputs = document.querySelectorAll(
      'input[type=checkbox].retailerCheckbox'
    );
    if (e.target.checked) {
      _.each(textinputs, function(ele, idx) {
        ele.checked = true;
      });
      this.setState({
        selectedAll: e.target.checked,
        deSelectedAll: false
      });
    } else {
      _.each(textinputs, function(ele, idx) {
        ele.checked = false;
      });
      this.setState({
        selectedAll: e.target.checked,
        deSelectedAll: true
      });
    }
  }

  deSelectAll(e) {
    var textinputs = document.querySelectorAll(
      'input[type=checkbox].retailerCheckbox'
    );

    if (e.target.checked) {
      _.each(textinputs, function(ele, idx) {
        ele.checked = false;
      });
      this.setState({
        deSelectedAll: e.target.checked,
        selectedAll: false
      });
    } else {
      _.each(textinputs, function(ele, idx) {
        ele.checked = true;
      });
      this.setState({
        deSelectedAll: e.target.checked,
        selectedAll: true
      });
    }
  }

  collectSelectedRetailers() {
    let retailerId = [];
    var textinputs = document.querySelectorAll(
      'input[type=checkbox].retailerCheckbox'
    );
    _.filter(textinputs, function(el) {
      if (el.checked === true) {
        retailerId.push(el.value);
      }
      return retailerId;
    });
    let retailerObj = {
      associated_retailers: retailerId
    };
    return retailerObj;
  }

  handleUpdate(e) {
    let { user } = this.props;
    e.preventDefault();
    let retailerObj = this.collectSelectedRetailers();
    omni_axios_instance.put(`/users/${user._id}`, retailerObj).then(res => {
      if (res.status === 200) {
        toastr.success('SUCCESS', 'Successfully updated');
        this.onModalHide();
      }
    });
  }

  handleContinue() {
    let retailerObj = this.collectSelectedRetailers();
    this.setState(
      {
        showModal: false
      },
      () => {
        this.props.callback({
          showAssignedRetailerModal: false,
          showAddEditModal: true,
          isRefreshFlag: true,
          retailerObj: retailerObj
        });
      }
    );
  }

  onModalHide() {
    let retailerObj = this.collectSelectedRetailers();
    this.setState(
      {
        showModal: false
      },
      () => {
        this.props.callback({
          showAssignedRetailerModal: false,
          showAddEditModal: true,
          isRefreshFlag: true,
          retailerObj: retailerObj
        });
      }
    );
  }

  render() {
    let { showModal, retailerList, selectedAll, deSelectedAll } = this.state;
    let { user, mode, userName } = this.props;
    // console.log("in assigned-retailers-modal", retailerList);
    return (
      <div className="modal-container">
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="assigned-retailers-modal"
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          <Modal.Header closeButton>
            {mode === 'add' || mode === 'edit' ? (
              <Modal.Title>Assigned Retailers for {userName}</Modal.Title>
            ) : (
              <Modal.Title>Assigned Retailers for {user.name}</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <form className="checkListForm">
              <div className="checkListForm__select-deselect-checkboxes">
                <div className="isSelectAll">
                  <input
                    type="checkbox"
                    onChange={this.selectAll}
                    checked={selectedAll}
                  />
                  <label>Select All</label>
                </div>
                <div className="isDeselectAll">
                  <input
                    type="checkbox"
                    onChange={this.deSelectAll}
                    checked={deSelectedAll}
                  />
                  <label>Deselect All</label>
                </div>
              </div>
              <div className="checkListItems">
                {retailerList.map((item, index) => {
                  return (
                    <div className="retailerCheckList" key={index}>
                      <input
                        type="checkbox"
                        className="retailerCheckbox"
                        defaultChecked={item.flag}
                        onChange={e => this.handleCheckbox(e)}
                        defaultValue={item.retailer._id}
                      />
                      <label>{item.retailer.name}</label>
                    </div>
                  );
                })}
              </div>
            </form>
            {this.props.updateHidden ? (
              ''
            ) : (
              <div className="popup-btn">
                <Button onClick={this.onModalHide}>Cancel</Button>
                {mode !== 'add' && mode !== 'edit' ? (
                  <Button bsStyle="primary" onClick={this.handleUpdate}>
                    Update
                  </Button>
                ) : (
                  <Button bsStyle="primary" onClick={this.handleContinue}>
                    Continue
                  </Button>
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AssignedRetailersModal;
