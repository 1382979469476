const FALLBACK_IMG = "assets/img/not-found-image.png";

const OMNI_API_URL = process.env.REACT_APP_OMNI_API_URL;
const STANDALONE_API_URL = process.env.REACT_APP_STANDALONE_API_URL;
const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
const APP_VERSION = process.env.REACT_APP_VERSION;

// console.log('Log: SitenAlysis: process.env is ', process.env);

export {
  OMNI_API_URL,
  FALLBACK_IMG,
  APP_VERSION,
  STANDALONE_API_URL,
  GA_TRACKING_ID,
};
