import {
	FETCH_RETAILER_SCRAPPING_INFO_REQUEST,
	FETCH_RETAILER_SCRAPPING_INFO_SUCCESS,
	FETCH_RETAILER_SCRAPPING_INFO_FAILURE
} from './actionTypes';

/*
 * Retailers Scrapping info
 */

export function doFetchRetailerScrappingInfo(atts) {
  return {
    type: FETCH_RETAILER_SCRAPPING_INFO_REQUEST,
    atts
  };
}

export function doFetchRetailerScrappingInfoFullfilled(payload) {
  return {
    type: FETCH_RETAILER_SCRAPPING_INFO_SUCCESS,
    payload
  };
}

export function doFetchRetailerScrappingInfoError(error) {
  return {
    type: FETCH_RETAILER_SCRAPPING_INFO_FAILURE,
    error
  };
}