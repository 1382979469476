import React from "react";
import { Navbar, Button } from "react-bootstrap";
import "./header.scss";
// links that appear in navbar - they are separated from this component (navbar) so that we can redner them on responsive in sidebar as well

import RetailerSelect from "./RetailerSelect.jsx";
import PeriodSelect from "./PeriodSelect.jsx";
import HeaderLinks from "./HeaderLinks.jsx";
import ImageLoader from "dashboard/components/ImageLoader";
import { Link } from "react-router-dom";
import Auth from "utils/auth";
import _ from "lodash";
// we import here the routes for dashboard pages (links that appear in sidebar) to set navbar's name

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.getPage = this.getPage.bind(this);
  }
  handleBack() {
    this.props.history.goBack();
  }
  getPage(props) {
    let { pathname } = this.props.location;
    // Default is summary view
    let page = "Summary View";
    if (pathname.indexOf("summary") > -1) {
      page = "Summary View";
    } else if (pathname.indexOf("stats") > -1) {
      page = "Retailer List";
    } else if (pathname.indexOf("manage-catalog") > -1) {
      page = "Manage Catalog";
    } else if (pathname.indexOf("message-inbox") > -1) {
      page = "Inbox";
    } else if (pathname.indexOf("product") > -1) {
      page = (
        <Button
          onClick={this.handleBack}
          bsStyle="default"
          className="product-back-btn"
        >
          <i className="fa fa-angle-left" />
          Back
        </Button>
      );
    } else if (pathname.indexOf("retailer") > -1) {
      page = "View By Retailer";
    } else if (pathname.indexOf("files-comparison") > -1) {
      page = "Comparison Report";
    } else if (pathname.indexOf("products-list") > -1) {
      page = "View By Product";
    } else if (pathname.indexOf("setting") > -1) {
      page = "Settings";
    }

    return page;
  }

  // function for responsive that hides/shows the sidebar
  mobileSidebarToggle(e) {
    document.documentElement.classList.toggle("nav-open");
  }

  render() {
    let pageName = this.getPage();
    let user = Auth.getUser();
    let logos = "";
    if (!_.isEmpty(user) && !_.isEmpty(user.brand)) {
      logos = user.brand.logo;
    }
    return (
      <Navbar fluid className="navbar-style">
        <div className="navbar-xs-style">
          <div className="logo-col">
            <Link
              to="/dashboard/summary"
              className="text-uppercase text-center simple-text logo"
            >
              <ImageLoader
                image={logos[0]}
                openInNewTab={false}
                zoomable={false}
                alt="brand logo"
              />
            </Link>
          </div>

          <div className="siteNalysisLogo-header">
            <div className="siteNalysisLogo-inner">
              <img
                src={`${process.env.PUBLIC_URL}/siteNalysis_Logo.png`}
                alt="SiteNalysis"
              />
              <div className="powered-by text-center">
                <sub>Powered by OPT</sub>
              </div>
            </div>
          </div>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </div>
        <div className="navbar-sm-style">
          <Navbar.Header>
            <Navbar.Brand>
              {/* {pageName} */}
              {pageName === "Summary View" || pageName === "Retailer List"
                ? "View By Retailer"
                : pageName}
            </Navbar.Brand>
            {(pageName === "View By Retailer" ||
              "Summary View" ||
              "Retailer List") && <RetailerSelect />}
            {pageName === "View By Product" && <PeriodSelect />}
            {/*    <Navbar.Toggle onClick={this.mobileSidebarToggle} /> */}
          </Navbar.Header>

          {/* Here we import the links that appear in navbar */}
          {window.innerWidth > 992 ? (
            <Navbar.Collapse>
              <HeaderLinks />
            </Navbar.Collapse>
          ) : null}
        </div>
      </Navbar>
    );
  }
}

export default Header;
