import React from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// react component that creates a switch button that changes from on to off mode
import cx from "classnames";
import Card from "dashboard/components/Card/Card.jsx";
import OmniSwitch from "dashboard/components/OmniSwitch";
import ImageLoader from "dashboard/components/ImageLoader";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import Helpers from "utils/helpers";
import ProductPagination from "dashboard/ProductPagination";
import {
  doFetchProducts,
  doFetchProductsUpdate,
} from "dashboard/actions/products";
import { omni_axios_instance } from "./../../../utils/axios";

const retailerProductThArray = [
  {
    name: "Flag",
    sort: false,
  },
  {
    name: "Product Image",
    sort: false,
  },
  {
    name: "Name",
    sort: "name",
    order: "asc",
    isActive: false,
  },
  {
    name: "Price",
    sort: "price",
    order: "asc",
    isActive: false,
  },
  {
    name: "Retailer Sku",
    sort: "style_number",
    order: "asc",
    isActive: false,
  },
  {
    name: "Brand Sku",
    sort: "style_number",
    order: "asc",
    isActive: false,
  },
  {
    name: "Details",
    sort: false,
  },
  {
    name: "OH Price",
    sort: false,
  },
  {
    name: "SD Price",
    sort: false,
  },
  {
    name: "Price Diff",
    sort: false,
  },
];

class RetailerProductsPriceIssueTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tableHeadOpts: retailerProductThArray,
      _id: "",
      map_id: "",
      showLabel: true,
    };
    this.createTableData = this.createTableData.bind(this);
    this.setFlaggedItems = this.setFlaggedItems.bind(this);
    this.doSort = this.doSort.bind(this);
    this.getSort = this.getSort.bind(this);
    this.updateProductMapID = this.updateProductMapID.bind(this);
  }

  async updateProductMapID(_id, map_id) {
    let { products, currentCount, totalCount } = this.props;
    let payload = { oh_map_id: map_id };
    console.log("product update payload", payload);
    await omni_axios_instance
      .put(
        `https://api.pricescraper.net/retailerBrandProductScrapings/${_id}`,
        payload
      )
      .then((response) => {
        console.log("product update response", response);
        if (response.status === 200) {
          this.props.doFetchProductsUpdate({
            _id,
            map_id,
            dataUpdated: { products, currentCount, totalCount },
          });
        }
      })
      .catch((err) => {
        console.log("product update err", err);
      });
  }

  setFlaggedItems(params) {}

  createTableData() {
    let { products: productsList } = this.props;

    if (_.isEmpty(productsList)) {
      return (
        <tr key={"0"}>
          <td className="text-center" colSpan="6">
            <div>No product(s) found!</div>
          </td>
        </tr>
      );
    }

    var tableRows = [];
    productsList.forEach((prop, key) => {
      let currentRetailerId = prop.retailer_brand_scraping;
      tableRows.push(
        <tr key={key} className="RetailerProductsTable-Row">
          <td className="text-center">
            <OmniSwitch
              callback={this.setFlaggedItems}
              isFlagged={prop.isFlagged}
              rId={currentRetailerId}
              productName={prop.name}
              id={prop._id}
            />
            {!this.props.tabName ? (
              this.state._id === prop._id && !this.state.showLabel ? (
                <input
                  type="text"
                  value={this.state.map_id}
                  placeholder="Enter Map Id.."
                  onChange={(e) => {
                    console.log("e.target", e.target.value);
                    let value = e.target.value;
                    this.setState((prevState) => ({
                      ...prevState,
                      map_id: value,
                    }));
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      let { value } = e.target;
                      this.updateProductMapID(prop._id, value);
                      this.setState({ showLabel: true });
                    }
                  }}
                />
              ) : (
                <label
                  onClick={() => {
                    this.setState((prevState) => ({
                      ...prevState,
                      _id: prop._id,
                      map_id: prop.oh_map_id ? prop.oh_map_id : "",
                      showLabel: false,
                    }));
                  }}
                >
                  {prop.oh_map_id ? prop.oh_map_id : "Enter map id..."}
                </label>
              )
            ) : (
              <></>
            )}
          </td>
          <td>
            <div className="img-container Thumbnail-Image margin-auto">
              <Link
                to={`/dashboard/retailer/${currentRetailerId}/product/${prop._id}`}
                className="color-black"
              >
                <ImageLoader
                  openInNewTab={false}
                  zoomable={false}
                  image={prop.image}
                  className="img-responsive"
                />
              </Link>
            </div>
          </td>
          <td>
            <Link
              className={classNames({
                "color-green": prop.type === "change",
              })}
              to={`/dashboard/retailer/${currentRetailerId}/product/${prop._id}`}
            >
              {prop.name}
            </Link>
          </td>
          <td>{Helpers.setProductPrice(prop.price)}</td>
          <td>{prop.style_number}</td>
          <td>
            {(prop.product && prop.product.sku) || prop.brand_sku || "N/A"}
          </td>

          <td>
            <Link
              to={`/dashboard/retailer/${currentRetailerId}/product/${prop._id}`}
            >
              Link
            </Link>
          </td>
          <td>{Helpers.setProductPrice(prop.oh_price)}</td>
          <td>{Helpers.setProductPrice(prop.price)}</td>
          <td>
            {Helpers.setProductPrice(
              parseFloat(prop.oh_price) - parseFloat(prop.price)
            )}
          </td>
        </tr>
      );
    });

    return tableRows;
  }

  doSort(sortBy, oldSortOrder) {
    if (!sortBy) {
      return;
    }
    let { tableHeadOpts } = this.state;
    // get new order
    let newSortOrder = oldSortOrder === "asc" ? "desc" : "asc";
    tableHeadOpts = tableHeadOpts.map((x) => {
      if (x.sort === sortBy) {
        return {
          ...x,
          order: newSortOrder,
        };
      }
      // else we have to reset other columns order
      // to `asc`
      return {
        ...x,
        order: "asc",
      };
    });
    this.setState(
      {
        tableHeadOpts,
      },
      () => {
        let sort = `${sortBy};${newSortOrder}`;
        // let sort = this.getSort();
        this.props.doFetchProducts({
          sort,
          page: this.props.page,
          IS_SORT_FLAG: true,
        });
      }
    );
  }

  getSort() {
    let { tableHeadOpts } = this.state;
    let sortingProps = [];
    tableHeadOpts.forEach((prop, i) => {
      if (!prop.sort) {
        return;
      }
      sortingProps.push(`${prop.sort};${prop.order}`);
    });
    return sortingProps;
  }

  render() {
    let { isLoading, products, totalCount } = this.props;
    let { tableHeadOpts } = this.state;

    let thHtml = tableHeadOpts.map((prop, key) => {
      let sortIndicator = "";
      if (prop.sort) {
        let sortIndicatorClassNames = classNames({
          col: true,
          fa: true,
          sort: true,
          "fa-sort-asc": prop.order === "asc",
          "fa-sort-desc": prop.order === "desc",
        });
        sortIndicator = (
          <i className={sortIndicatorClassNames} aria-hidden="true" />
        );
      }

      let thClassName = classNames({
        thRow: true,
        "Sorting-Enabled": prop.sort,
      });

      return (
        <th
          className={thClassName}
          key={`pdtr-${key}`}
          onClick={() => this.doSort(prop.sort, prop.order)}
        >
          <div className="thRow-Inner">
            <span className="col">{prop.name}</span>
            {sortIndicator}
          </div>
        </th>
      );
    });

    let tbHtml = "";
    if (isLoading) {
      tbHtml = (
        <tr className="Products-Loader">
          <td colSpan="6">
            <i className="fa fa-spin fa-spinner fa-2x fa-fw" />
          </td>
        </tr>
      );
    } else {
      tbHtml = this.createTableData();
    }

    return (
      <div className="product-table-data">
        <div className="export-btn pull-right">
          <label>{totalCount} Items Found</label>
          <button
            id="productlist-export-btn"
            className={cx({
              disabled: products.length === 0,
              "btn btn-default": true,
            })}
            onClick={() => products.length && this.props.exportRetailerInfo()}
          >
            export
            <i className="fa fa-file-excel-o" />
          </button>
        </div>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                textCenter
                tableFullWidth
                content={
                  <div>
                    <ProductPagination productTableList={true} />
                    <Table
                      responsive
                      className="RetailerProductsTable table-bigboy"
                    >
                      <thead className="RetailerProductsTableHead">
                        <tr>{thHtml}</tr>
                      </thead>
                      <tbody className="RetailerProductsTableBody">
                        {tbHtml}
                      </tbody>
                    </Table>
                    <ProductPagination />
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.items,
    isLoading: state.products.isLoading,
    page: state.products.filters.page,
    totalCount: state.products.totalProducts,
    currentCount: state.products.currentProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doFetchProducts,
      doFetchProductsUpdate,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailerProductsPriceIssueTable);
