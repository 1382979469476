import React from 'react';
import { Modal } from 'react-bootstrap';

class UploadFileModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onModalHide() {
    this.setState(
      {
        showModal: false
      },
      () => {
        this.props.callback();
      }
    );
  }

  onChange(e) {
    this.props.onFileInputChange(e);
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  handleUpload() {
    this.props.handleUpload();
  }
  render() {
    const { showModal } = this.state;
    const { fileFormat, fileName } = this.props.file;
    return (
      <Modal id="upload-modal" show={showModal} onHide={this.onModalHide}>
        <form onSubmit={e => e.preventDefault()}>
          <div>
            <div className="file-upload form-group">
              <label className="btn choose-file-btn default-btn">
                <input
                  className="file-upload_btn"
                  type="file"
                  label="File"
                  name="attachment"
                  accept={fileFormat}
                  onChange={this.onChange}
                />
                Choose File
              </label>
              <label className="file-name">{fileName}</label>
            </div>
            <div className="upload-modal__btns">
              <label
                className="btn default-btn upload-btn"
                onClick={this.handleUpload}
              >
                Upload
              </label>
              <span className="cancel-btn" onClick={this.onModalHide}>
                Cancel
              </span>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

export default UploadFileModal;
