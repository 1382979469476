import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SuccessModal from '../SuccessModal';
import Select, { components } from 'react-select';
import { omni_axios_instance } from 'utils/axios';
import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';

const defaultState = {
  selectedBrandSku: '',
  brandSku: '',
  showSuccessModal: false
};
const BrandSkuModal = props => {
  let { show, brandSkuOptions, productId } = props;
  const [state, setState] = useState(defaultState);
  const [errors, setErrors] = useState([]);
  let { selectedBrandSku, brandSku, showSuccessModal } = state;
  const onModalHide = () => {
    let { callback } = props;
    callback && callback();
  };
  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className={'fa fa-sort dropdown-indicator'} />
        </components.DropdownIndicator>
      )
    );
  };
  const styles = {
    control: base => ({
      ...base,
      border: '1px solid lightgrey',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid lightgrey'
      }
    }),
    valueContainer: base => ({
      ...base,
      paddingLeft: selectedBrandSku.value === undefined ? 24 : 5
    })
  };
  const ValueContainer = ({ children, ...props }) => {
    let selectedSku = selectedBrandSku.value;
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <i
              className={selectedSku != undefined ? '' : 'fa fa-search'}
              aria-hidden="true"
              style={{ position: 'absolute', left: 6 }}
            />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };
  const onHandleSelect = optionSelected => {
    setState({
      ...state,
      selectedBrandSku: optionSelected
    });
  };
  const handleInputChange = e => {
    let brandSku = e.target.value;
    setState({
      ...state,
      brandSku
    });
  };
  const onSubmit = e => {
    let { selectedBrandSku } = state;
    e.preventDefault();
    let tErrors = [];
    if (isEmpty(selectedBrandSku.value)) {
      tErrors.push('Please select brand sku.');
    }
    if (!isEmpty(tErrors)) {
      setErrors(tErrors);
      return;
    }
    // Data to be posted
    let formData;

    // brand_sku
    selectedBrandSku.value === 'other'
      ? (formData = {
          brand_sku: brandSku
        })
      : (formData = {
          product: selectedBrandSku.value
        });
    omni_axios_instance
      .put(`/retailerBrandProductScrapings/${productId}`, formData)
      .then(res => {
        if (res.status === 200) {
          toastr.success('SUCCESS', 'Brand Sku updated successfully.');
          setState({
            ...state,
            showSuccessModal: true
          });
        }
      });
  };
  return (
    <div className="modal-container" style={{ height: 200 }}>
      <Modal
        className="brand-sku-modal"
        animation={false}
        autoFocus={false}
        enforceFocus={false}
        show={show}
        onHide={onModalHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit - Brand SKU</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="edit-brandsku-modal__form" onSubmit={onSubmit}>
            {errors.length > 0 && (
              <header className="edit-brandsku-modal__form__errors">
                {errors.map((e, index) => (
                  <p className="error" key={index}>
                    {e}
                  </p>
                ))}
              </header>
            )}
            <div className="form-group edit-brandsku-modal__form--margin">
              <label className="edit-brandsku-modal__form__label">
                Select Brand Sku
              </label>
              <div>
                <Select
                  value={selectedBrandSku}
                  components={{
                    DropdownIndicator,
                    ValueContainer,
                    IndicatorSeparator: () => null
                  }}
                  className="brand-sku-options"
                  options={brandSkuOptions}
                  onChange={onHandleSelect}
                  placeholder="Search Style #"
                  styles={styles}
                />
              </div>
            </div>
            {selectedBrandSku.value === 'other' && (
              <div className="form-group edit-brandsku-modal__form--margin">
                <input
                  type="text"
                  id="brand-sku"
                  name="name"
                  className="form-control edit-brandsku-modal__mesg-name"
                  placeholder="Enter Brand SKU"
                  value={brandSku}
                  onChange={handleInputChange}
                />
              </div>
            )}
            <div className="edit-brandsku-modal__form__form_btn text-right">
              <Button
                type="submit"
                className="btn edit-brandsku-modal__form__saveBtn"
              >
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showSuccessModal && (
        <SuccessModal
          show={showSuccessModal}
          mode="sku"
          callback={onModalHide}
        />
      )}
    </div>
  );
};

export default BrandSkuModal;
