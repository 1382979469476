import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
import Card from '../../../components/Card/Card.jsx';
import AddEditUsersModal from './AddEditUsersModal';
import DeleteUserSuccessfullModal from './DeleteUserSuccessfullModal';
import UsersListPagination from './UsersListPagination';
import isEmpty from 'lodash/isEmpty';
import Truncate from 'react-truncate';
import Auth from 'utils/auth';
import AssignedRetailersModal from './AssignedRetailersModal';
import map from 'lodash/map';
import includes from 'lodash/includes';

class UsersTab extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      users: [],
      totalCount: 0,
      filters: {
        page: 1,
        limit: 10,
        sort: 'name;asc'
      },
      showAddEditModal: {
        isOpen: false,
        mode: 'add'
      },
      user: {},
      showHideDeleteModal: false,
      userToDelete: '',
      showHideAssignedRetailersModal: false,
      retailers: []
    };
    this.handleAddEditModalHide = this.handleAddEditModalHide.bind(this);
    this.queryUpdate = this.queryUpdate.bind(this);
    this.callbackFromDeleteModal = this.callbackFromDeleteModal.bind(this);
    this.hideModalForAssignedRet = this.hideModalForAssignedRet.bind(this);
    this.getData = this.getData.bind(this);
  }

  getData(isOpen = false, mode = 'add', showHideDeleteModal = false) {
    let loginUser = Auth.getUser();
    let brandId = loginUser.brand;
    Promise.all([
      omni_axios_instance.get(`/users?brand=${brandId._id}`),
      omni_axios_instance.get(`/roles`),
      omni_axios_instance.get(`/getRetailersForUsersTab/${brandId._id}`)
    ]).then(([res1, res2, res3]) => {
      if (!isEmpty(res1) && !isEmpty(res2) && !isEmpty(res3)) {
        this.setState({
          users: res1.data.data,
          totalCount: res1.data.totalCount,
          loading: false,
          roles: res2.data,
          retailers: res3.data,
          showAddEditModal: {
            isOpen: isOpen,
            mode: mode
          },
          showHideDeleteModal: showHideDeleteModal
        });
      }
    });
  }
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    let { filters } = this.state;
    if (prevState.filters !== this.state.filters) {
      omni_axios_instance
        .get(
          `/users?page=${filters.page}&limit=${filters.limit}&sort=${
            filters.sort
          }`
        )
        .then(res => {
          if (!isEmpty(res)) {
            this.setState({
              users: res.data.data,
              totalCount: res.data.totalCount,
              loading: false
            });
          }
        });
    }
    /*if(prevState.isRefreshFlag !== this.state.isRefreshFlag) {
      this.setState({
        isRefreshFlag: false
      });
    }*/
  }
  editUser(userInfo) {
    if (isEmpty(userInfo)) {
      return;
    }
    let { roles } = this.state;
    let allRoles = roles;
    let roleObj = [];

    allRoles.forEach(function(role) {
      if (userInfo.roles.length && role._id === userInfo.roles[0]._id) {
        roleObj.push({ value: role._id, label: role.name });
      }
    });
    var arr = userInfo.name.split(' ');
    let newuserInfo = {
      _id: userInfo._id,
      firstname: arr[0],
      lastname: arr[1],
      email: userInfo.email,
      notify: userInfo.notify,
      roles: roleObj,
      associated_retailers: userInfo.associated_retailers
    };
    this.setState({
      showAddEditModal: {
        isOpen: !this.state.showAddEditModal.isOpen,
        mode: 'edit'
      },
      user: newuserInfo
    });
  }
  handleAddEditModalHide() {
    let isOpen = !this.state.showAddEditModal.isOpen;
    let mode = 'add';
    this.getData(isOpen, mode);
    /*this.setState({
      showAddEditModal: {
        isOpen: !this.state.showAddEditModal.isOpen,
        mode: 'add'
      },
    });*/
  }

  queryUpdate(params) {
    var activePage = params.activePage;
    this.setState({
      filters: {
        ...this.state.filters,
        page: activePage
      }
    });
  }

  callbackFromDeleteModal(val) {
    // let showHideDeleteModal = val;
    this.getData();
    /*this.setState({
      showHideDeleteModal: val,
    });*/
  }

  deleteUser(prop) {
    this.setState({
      showHideDeleteModal: !this.state.showHideDeleteModal,
      userToDelete: prop._id
    });
  }

  showPopUpModalForAssignedRetailers(e, props) {
    e.preventDefault();
    this.setState({
      showHideAssignedRetailersModal: true,
      user: props
    });
  }
  //code for react-truncate means show all retailers in a single line
  selectedRetailerList = props => {
    let selectedRetailers = props.associated_retailers || [];
    let retailerList = [];
    const { retailers } = this.state;
    // console.log(selectedRetailers,'selectedRetailers')
    if (isEmpty(selectedRetailers)) {
      return false;
    }
    map(retailers, (item, key) => {
      let isResult = false;
      map(selectedRetailers, selectedRetailer => {
        isResult = includes(selectedRetailer, item.retailer._id);
        isResult && retailerList.push(`${item.retailer.name}`);
      });
    });
    /*  _.map(selectedRetailers, (item, key) => {
      return item.flag && retailerList.push(`${item.retailer.name}`);
    }); */
    let filteredList = retailerList.sort().join(', ');
    // console.log(filteredList,'filteredList')

    if (retailerList.length === retailers.length) {
      return (
        <span className="ellipsis-con">
          <a
            href="#"
            onClick={e => this.showPopUpModalForAssignedRetailers(e, props)}
          >
            All
          </a>
        </span>
      );
    }
    return (
      <Truncate
        lines={1}
        width={250}
        ellipsis={
          <span className="ellipsis-con">
            ...
            <a
              href="#"
              onClick={e => this.showPopUpModalForAssignedRetailers(e, props)}
            >
              view All
            </a>
          </span>
        }
      >
        <span className="retailerText">{`${filteredList}`}</span>
      </Truncate>
    );
  };

  hideModalForAssignedRet(opt) {
    this.getData();
    this.setState({
      showHideAssignedRetailersModal: opt.showAssignedRetailerModal
    });
  }
  render() {
    let {
      loading,
      users,
      totalCount,
      filters,
      showAddEditModal,
      user,
      userToDelete,
      showHideDeleteModal,
      showHideAssignedRetailersModal,
      retailers
    } = this.state;
    let loginUser = Auth.getUser();

    let tableBody;
    if (loading) {
      tableBody = (
        <tbody>
          <tr>
            <td
              className="text-center"
              colSpan={5}
              style={{
                padding: '20% 0'
              }}
            >
              <div className="users-table__body--loader-image">
                <i className="fa fa-spin fa-spinner fa-2x fa-fw" />
              </div>
              <div className="users-table__body--loader-text">
                Loading list, please wait...
              </div>
            </td>
          </tr>
        </tbody>
      );
    } else {
      tableBody = (
        <tbody className="users-table__body">
          {users.map((prop, key) => {
            return (
              <tr key={key} className="users-table__row">
                <td className="user-name">{prop.name}</td>
                <td className="user-emailid">{prop.email}</td>
                <td className="user-roles">
                  {prop.roles.map((role, i) => {
                    return <span key={i}>{role.name}</span>;
                  })}
                </td>
                <td className="user-assigned-retailers">
                  {this.selectedRetailerList(prop)}
                </td>
                {Auth.isBrandAdmin() ? (
                  <td className="actions-btns">
                    {loginUser._id !== prop._id ? (
                      <Button
                        bsStyle="default"
                        className="delete-btn"
                        onClick={e => this.deleteUser(prop)}
                      >
                        Delete
                      </Button>
                    ) : (
                      <Button bsStyle="default" className="delete-btn" disabled>
                        Delete
                      </Button>
                    )}
                    <Button
                      bsStyle="default"
                      className="edit-btn"
                      onClick={e => this.editUser(prop)}
                    >
                      Edit
                    </Button>
                  </td>
                ) : (
                  <></>
                )}
              </tr>
            );
          })}
        </tbody>
      );
    }
    let cardHeader = <></>;
    let htmlContent = (
      <>
        <Card
          title={cardHeader}
          className="card"
          text={
            Auth.isBrandAdmin() ? (
              <div className="add-new-user-btn">
                <Button
                  bsStyle="default"
                  className="card__btn"
                  onClick={this.handleAddEditModalHide}
                >
                  Add New User
                </Button>
              </div>
            ) : (
              ''
            )
          }
          tableFullWidth
          content={
            <Table striped hover responsive className="users-table">
              <thead className="users-table__header">
                <tr className="users-table__row">
                  <th className="username-header">
                    <div className="d-flex">
                      <span>User Name</span>
                    </div>
                  </th>
                  <th className="emailid-header">
                    <div className="d-flex">
                      <span>Email Address</span>
                    </div>
                  </th>
                  <th className="role-header">
                    <div className="d-flex">
                      <span>Role</span>
                    </div>
                  </th>
                  <th className="assigned-retailers-header">
                    <div className="d-flex">
                      <span>Assigned Retailers</span>
                    </div>
                  </th>
                  {Auth.isBrandAdmin() ? (
                    <th className="actions-header">
                      <div className="d-flex justify-content-center">
                        <span>Actions</span>
                      </div>
                    </th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              {tableBody}
            </Table>
          }
        />
        <UsersListPagination
          totalCount={totalCount}
          itemsPerPage={filters.limit}
          callback={this.queryUpdate}
        />
        <AddEditUsersModal
          show={showAddEditModal.isOpen}
          mode={showAddEditModal.mode}
          onHide={this.handleAddEditModalHide}
          user={user}
          retailers={retailers}
        />
        {showHideDeleteModal && (
          <DeleteUserSuccessfullModal
            onHideDeleteModal={this.callbackFromDeleteModal}
            userToDelete={userToDelete}
          />
        )}
        {showHideAssignedRetailersModal && (
          <AssignedRetailersModal
            show={showHideAssignedRetailersModal}
            callback={this.hideModalForAssignedRet}
            retailers={retailers}
            user={user}
            updateHidden={Auth.isBrandAdmin() ? false : true}
          />
        )}
      </>
    );
    return <div className="users-tab__menu container-fluid">{htmlContent}</div>;
  }
}

export default UsersTab;
