import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';
import AddEditUserSuccessfullModal from './AddEditUserSuccessfullModal';
import AssignedRetailersModal from './AssignedRetailersModal';

class AddEditUsersModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      selectedOption: { value: '', label: 'Select one' },
      firstname: '',
      lastname: '',
      errors: [],
      notify: false,
      associatedRetailers: [],
      roles: [],
      addEditSuccessHideShow: false,
      assignedRetailersHideShow: false
    };
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlefirstNameChange = this.handlefirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailIdChange = this.handleEmailIdChange.bind(this);
    this.handleSelectRoleChange = this.handleSelectRoleChange.bind(this);
    this.setErrors = this.setErrors.bind(this);
    this.viewAllAssignedRetailersClick = this.viewAllAssignedRetailersClick.bind(
      this
    );
    this.assignedRetailersFunction = this.assignedRetailersFunction.bind(this);
    this.toggleNotifyChange = this.toggleNotifyChange.bind(this);
    this.addEditSuccessModal = this.addEditSuccessModal.bind(this);
  }
  componentDidMount() {
    omni_axios_instance.get(`/roles`).then(response => {
      this.setState({
        roles: response.data
      });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    let { user, mode } = this.props;
    if (user !== prevProps.user) {
      if (mode === 'edit') {
        let selectedOptionVal = {};
        if (!isEmpty(user.roles)) {
          user.roles.forEach(role => {
            selectedOptionVal = { label: role.label, value: role.value };
          });
        }
        this.setState({
          firstname: user.firstname,
          lastname: user.lastname,
          notify: user.notify,
          selectedOption: selectedOptionVal,
          emailAddress: user.email
        });
      }
    }
    if (mode !== prevProps.mode) {
      if (mode === 'add') {
        this.setState({
          firstname: '',
          lastname: '',
          notify: false,
          selectedOption: { value: '', label: 'Select user' },
          emailAddress: ''
        });
      }
    }
  }

  onCloseModal() {
    this.setState({
      firstname: '',
      lastname: '',
      emailAddress: '',
      notify: false,
      selectedOption: { value: '', label: 'Select one' }
    });
    this.props.onHide(false);
  }

  setErrors(tErrors) {
    this.setState({
      errors: tErrors
    });
  }

  viewAllAssignedRetailersClick() {
    let { firstname, lastname } = this.state;
    let { mode } = this.props;
    if (mode === 'add' && isEmpty(firstname) && isEmpty(lastname)) {
      // alert();
      toastr.success('Please fill up firstname and lastname first.');
    } else {
      this.setState({
        assignedRetailersHideShow: true
      });
    }
  }

  handlefirstNameChange(e) {
    this.setState({
      firstname: e.target.value
    });
  }
  handleLastNameChange(e) {
    this.setState({
      lastname: e.target.value
    });
  }
  handleEmailIdChange(e) {
    this.setState({
      emailAddress: e.target.value
    });
  }
  handleSelectRoleChange(selectedOption1) {
    this.setState({
      selectedOption: selectedOption1
    });
  }
  toggleNotifyChange(e) {
    this.setState({
      notify: e.target.checked
    });
  }
  validateEmail(emailAddress) {
    var reg = /^([a-z0-9_\-\.])+\@([a-z0-9_\-\.])+\.([a-z]{2,4})$/;
    if (reg.test(emailAddress) === false) {
      return false;
    }
    return true;
  }
  onSubmit(e) {
    let {
      firstname,
      lastname,
      selectedOption,
      emailAddress,
      notify,
      associatedRetailers
    } = this.state;
    let { mode } = this.props;
    e.preventDefault();
    let tErrors = [];
    if (!firstname) {
      tErrors.push('Firstname is required.');
    }
    if (!lastname) {
      tErrors.push('Lastname is required.');
    }
    if (emailAddress) {
      let val = this.validateEmail(emailAddress);
      if (val === false) {
        tErrors.push('Invalid Email Address');
      }
    } else {
      tErrors.push('EmailAddress is required.');
    }
    if (isEmpty(selectedOption)) {
      tErrors.push('Please select role.');
    }
    if (!isEmpty(tErrors)) {
      this.setErrors(tErrors);
      return;
    }
    let authUser = Auth.getUser();
    let formData = {
      name: firstname + ' ' + lastname,
      email: emailAddress,
      notify: notify,
      user_email_bcc: authUser.user_email_bcc,
      roles: [selectedOption.value],
      associated_retailers: associatedRetailers
    };
    let promise = {},
      mesg = '';
    if (mode === 'edit') {
      promise = omni_axios_instance.put(
        `/users/${this.props.user._id}`,
        formData
      );
      mesg = 'User updated successfully';
    } else {
      promise = omni_axios_instance.post(`/registerAndNotify`, formData);
      mesg = 'Message created successfully';
    }
    promise
      .then(res => {
        this.setState({
          addEditSuccessHideShow: true,
          firstname: '',
          lastname: '',
          emailAddress: '',
          notify: false,
          selectedOption: { value: '', label: 'Select one' }
        });
        toastr.success('SUCCESS', mesg);
      })
      .catch(function(error) {
        console.log(error);
      });
    // this.props.onHide(false);
  }

  addEditSuccessModal(val) {
    //hide success modal after closing modal
    this.setState({
      addEditSuccessHideShow: val
    });
    this.props.onHide(false);
  }

  assignedRetailersFunction(val) {
    let obj = val.retailerObj;
    this.setState({
      assignedRetailersHideShow: val.showAssignedRetailerModal,
      associatedRetailers: obj.associated_retailers
    });
  }

  render() {
    let {
      firstname,
      lastname,
      emailAddress,
      errors,
      addEditSuccessHideShow,
      assignedRetailersHideShow,
      selectedOption,
      roles,
      notify
    } = this.state;
    let { retailers, mode, user } = this.props;
    let options = [];
    roles.forEach(role => {
      options.push({ value: `${role._id}`, label: `${role.name}` });
    });
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.onCloseModal}
          container={this}
          className="add-edit-user-modal"
        >
          <Modal.Header closeButton>
            {mode === 'edit' ? (
              <Modal.Title>Edit User</Modal.Title>
            ) : (
              <Modal.Title>Add User</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <form
              className="add-edit-user-modal__form"
              onSubmit={this.onSubmit}
            >
              {errors.length > 0 && (
                <header className="add-edit-user-modal__form__errors">
                  {errors.map((err, index) => (
                    <p className="error" key={index}>
                      {err}
                    </p>
                  ))}
                </header>
              )}
              <div className="form-group add-edit-user-modal__form--margin">
                <label
                  htmlFor="first-name"
                  className="add-edit-user-modal__form__label"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="first-name"
                  name="firstname"
                  className="form-control add-edit-user-modal__first-name"
                  onChange={this.handlefirstNameChange}
                  placeholder="enter first name"
                  defaultValue={firstname}
                />
              </div>
              <div className="form-group add-edit-user-modal__form--margin">
                <label
                  htmlFor="last-name"
                  className="add-edit-user-modal__form__label"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="last-name"
                  name="lastname"
                  className="form-control add-edit-user-modal__last-name"
                  onChange={this.handleLastNameChange}
                  placeholder="enter last name"
                  defaultValue={lastname}
                />
              </div>

              <div className="form-group add-edit-user-modal__form--margin">
                <label
                  htmlFor="last-name"
                  className="add-edit-user-modal__form__label"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email-address"
                  name="emailAddress"
                  className="form-control add-edit-user-modal__email-id"
                  onChange={this.handleEmailIdChange}
                  placeholder="enter email address"
                  defaultValue={emailAddress}
                  disabled={mode === 'edit' ? 'disabled' : ''}
                />
              </div>
              <div className="form-group add-edit-user-modal__form--margin">
                <label className="add-edit-user-modal__form__label">role</label>
                <Select
                  options={options}
                  onChange={this.handleSelectRoleChange}
                  placeholder="Select one"
                  defaultValue={selectedOption}
                />
              </div>
              <div className="form-group add-edit-user-modal__form--margin">
                <Button
                  className="btn add-edit-user-modal__form__assignnedRetailerBtn"
                  onClick={this.viewAllAssignedRetailersClick}
                >
                  View all assigned retailers
                </Button>
              </div>
              <div className="add-edit-user-modal__form__submitpart">
                {mode === 'edit' && (
                  <div className="notify-block">
                    <input
                      type="checkbox"
                      id="notify"
                      name="notify"
                      className="add-edit-user-modal__notify"
                      defaultValue={notify}
                      onChange={this.toggleNotifyChange}
                    />
                    <label htmlFor="notify">notify user</label>
                  </div>
                )}
                <div className="add-edit-user-modal__form__btns">
                  {mode === 'edit' ? (
                    <Button
                      type="submit"
                      className="btn add-edit-user-modal__form__updateMesgBtn"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className="btn add-edit-user-modal__form__createMesgBtn"
                    >
                      Create
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {addEditSuccessHideShow && (
          <AddEditUserSuccessfullModal
            callbackForAddEdit={this.addEditSuccessModal}
            mode={mode}
          />
        )}
        {assignedRetailersHideShow && (
          <AssignedRetailersModal
            show={assignedRetailersHideShow}
            callback={this.assignedRetailersFunction}
            mode={mode}
            user={this.props.user}
            userName={firstname + ' ' + lastname}
            retailers={retailers}
          />
        )}
      </>
    );
  }
}

export default AddEditUsersModal;
