import {
  FETCH_POSTOFFICE_REQUEST,
  FETCH_POSTOFFICE_SUCCESS,
  FETCH_POSTOFFICE_FAILURE
} from '../actions/actionTypes';

let initialState = {
  filters: {
    sort: 'date;desc',
    approvedStatus: '',
    flag: '',
    page: 1,
    limit: 20
  },
  isLoading: false,
  data: {},
  errors: []
};

function postOffice(state = initialState, action) {
  switch (action.type) {
    case FETCH_POSTOFFICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: []
      };

    case FETCH_POSTOFFICE_SUCCESS:
      let { data } = action.payload;
      return Object.assign({}, state, {
        isLoading: false,
        data: data
      });

    case FETCH_POSTOFFICE_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        data: {},
        errors: action.payload
          ? action.payload.errors
          : ['Oops, blank response!']
      });

    default:
      return state;
  }
}

export default postOffice;
