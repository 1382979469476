import React from 'react';
import ReportABugModal from 'dashboard/components/ReportABugModal';
import { Link } from 'react-router-dom';
//test
class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      appVersion: process.env.REACT_APP_VERSION
    };
  }

  render() {
    let { appVersion } = this.state;
    return (
      <footer className="dash-footer">
        <div className="footer-wrap">
          <div className="contact-button">
            <ReportABugModal callback={this.toggleContactModal} />
          </div>
          <div className="copyright color-black">
            <span className="color-white privacy-policy-link">
              <Link className="color-black" to="/privacypolicy">
                Terms & Conditions
              </Link>
            </span>
            <span className="">
              &copy;&nbsp;
              <a
                rel="noopener noreferrer"
                className="color-black"
                target="_blank"
                href="/"
              >
                OPT
              </a>
              &nbsp;
              {new Date().getFullYear()}
            </span>
            &nbsp;|&nbsp;
            <span className="appVersion">v {appVersion}</span>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
