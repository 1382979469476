import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';

const InboxPagination = props => {
  const [activePage, setPage] = useState(1);
  const totalPages = Math.ceil(props.totalCount / props.itemsPerPage);
  const pagination = activePage => {
    props.callback({
      activePage
    });
  };

  const handlePagination = e => {
    let page = e.target.text || e.target.textContent;
    let { itemsPerPage, totalCount } = props;

    const totalPages = Math.ceil(totalCount / itemsPerPage);
    let map = {
      first: 1,
      last: totalPages,
      lastButtonText: 'Last',
      firstButtonText: 'First'
    };
    // use map later
    if (page === map.firstButtonText) {
      page = map.first;
    } else if (page === map.lastButtonText) {
      page = map.last;
    }
    const activePage = Number(page);
    setPage(activePage);
    pagination(activePage);
  };
  return (
    <div className="pagination-container">
      <Pagination
        first={totalPages > 1 ? 'First' : false}
        last={totalPages > 1 ? 'Last' : false}
        items={totalPages}
        maxButtons={10}
        boundaryLinks={true}
        ellipsis={true}
        activePage={activePage}
        onClick={e => {
          if (e.target.classList.contains('disabled')) {
            return false;
          }
          handlePagination(e);
        }}
      />
    </div>
  );
};

export default InboxPagination;
