import { omni_axios_instance } from 'utils/axios';
import {
  // doFetchNotificationData,
  doFetchNotificationDataFullfilled,
  doFetchNotificationDataError
} from '../actions/notification';
import queryString from 'querystringify';

/* let pushState = qs => {
  // console.log('@qs =>', qs)
  // Push new search query by excluding default params
  window.appHistory.replace({
    search: qs
  });
}; */
export default function thunkFetchNotificationData(action) {
  return function(dispatch, getState) {
    // dispatch(doFetchNotificationData());
    const state = getState();
    let { filters: oldAtts } = state.admin.notification;
    let newAtts = Object.assign(oldAtts, action && action.atts);
    let qs = queryString.stringify(newAtts);
    // Push new state
    // pushState(qs);
    return omni_axios_instance
      .get(`/notifications?${qs}`)
      .then(response => dispatch(doFetchNotificationDataFullfilled(response)))
      .catch(error => dispatch(doFetchNotificationDataError(error.xhr)));
  };
}
