import {
  FETCH_ALL_RET_PRODUCTS_REQUEST,
  FETCH_ALL_RET_PRODUCTS_SUCCESS,
  FETCH_ALL_RET_PRODUCTS_FAILURE
} from 'dashboard/actions/actionTypes';

let initialState = {
  items: [],
  filters: {
    page: 1,
    limit: 25
  },
  isLoading: false,
  errors: [],
  currentCount: 0, // total products for this page/iteration
  next: 0, // next page
  totalCount: 0 // total products
};

function allRetailersProducts(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_RET_PRODUCTS_REQUEST:
      // `action.atts` is nothing but latest filter
      // parse new filters
      let filters = Object.assign({}, state.filters, action.atts);
      return Object.assign({}, state, {
        filters,
        isLoading: true,
        errors: []
      });

    case FETCH_ALL_RET_PRODUCTS_SUCCESS:
      let { data } = action.payload;
      return Object.assign({}, state, {
        isLoading: false,
        errors: [],
        items: data.result || data,
        totalProducts: data.totalCount || 0,
        currentProducts: data.currentCount || 0
      });

    case FETCH_ALL_RET_PRODUCTS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        items: [],
        errors: action.payload
          ? action.payload.errors
          : ['Oops, blank response!']
      });

    case '@@router/LOCATION_CHANGE':
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
}

export default allRetailersProducts;
