import React, { Component } from 'react';
import ForgotPasswordModal from './ForgotPasswordModal';

export default class ForgotPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordModal: false
    };
    this.modalClose = this.modalClose.bind(this);
    this.forgotPasswordModalClose = this.forgotPasswordModalClose.bind(this);
  }

  componentDidMount() {
    this.setState({
      showForgotPasswordModal: true
    });
  }

  forgotPasswordModalClose() {
    console.log('forgotPasswordModalClose');
    this.setState({ showForgotPasswordModal: false });
    window.location.href = window.location.origin;
  }

  modalClose() {
    // console.log('modalClose')
  }

  render() {
    let { showForgotPasswordModal } = this.state;
    console.log(showForgotPasswordModal, 'showForgotPasswordModal');
    return (
      <div>
        <ForgotPasswordModal
          show={showForgotPasswordModal}
          onHide={this.forgotPasswordModalClose}
          onHideLoginModal={this.modalClose}
        />
      </div>
    );
  }
}
