import React from 'react';
import Select from 'react-select';
import { omni_axios_instance } from 'utils/axios';
import axios from 'axios';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';
import SearchedItems from './SearchedItems';

class Filters extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			filterType: '',
			selectedBrand: {value: '', label:'Select One'},
			selectedRetailer: {value: '', label:'Select One'},
			results: [],
			retailersList: [],
			brandsList: [],
		}
		this.handleRetailerChange = this.handleRetailerChange.bind(this);
		this.handleBrandChange = this.handleBrandChange.bind(this);
		this.handleClickResetBtn = this.handleClickResetBtn.bind(this);
		this.getRetailersResults = this.getRetailersResults.bind(this);
		this.getBrandsResults = this.getBrandsResults.bind(this);
		this.onHandleSubmit = this.onHandleSubmit.bind(this);
	}

	componentDidMount() {
		let retailerOptions = [];
		let brandOptions = [];
		function getRetailers() {
			return omni_axios_instance.get('/retailers');
		}
		function getBrands() {
			return omni_axios_instance.get('/brands');
		}
		axios
		.all([getRetailers(), getBrands()])
	  .then(axios.spread((retailers, brands) => {
	  	_.forEach(retailers.data, function(retailer){
	    	retailerOptions.push({ value: retailer._id, label: retailer.name });
	    });
	    _.forEach(brands.data, function(brand){
	    	brandOptions.push({ value: brand._id, label: brand.name });
	    });
	    this.setState({
		  	retailersList: retailerOptions,
		  	brandsList: brandOptions
		  });
	  }))
	  .catch(error => console.log(error));
	}

	handleClickResetBtn() {
		this.setState({
			filterType: '',
			selectedRetailer: {value: '', label:'Select One'},
			selectedBrand: {value: '', label:'Select One'}
		});
	}

	getResults(filterType) {
		let { selectedRetailer, selectedBrand } = this.state;
		let url;
		if(filterType === 'retailer') {
			let __rId = selectedRetailer.value;
			url = `/BrandRetailerExport?retailer=${__rId}&exportFlag=false`;
		} else {
			let __bId = selectedBrand.value;
			url = `/BrandRetailerExport?brand=${__bId}&exportFlag=false`;
		}
		omni_axios_instance
		.get(url)
		.then(response => {
      this.setState({
				results: response.data.data,
				filterType
			});
    })
		.catch(error => console.log(error));
	}

	getRetailersResults() {
		let { selectedRetailer } = this.state;
		if(isEmpty(selectedRetailer.value)) {
			toastr.error('ERROR', 'Please select retailer.');
      return;
		}
		this.getResults('retailer');
	}

	getBrandsResults() {
		let { selectedBrand } = this.state;
		if(isEmpty(selectedBrand.value)) {
      toastr.error('ERROR', 'Please select brand.');
      return;
		}
		this.getResults('brand');
	}

	handleRetailerChange(selected) {
		this.setState({
			selectedRetailer: selected,
		});
	}

	handleBrandChange(selected) {
		this.setState({
			selectedBrand: selected,
		});
	}

	onHandleSubmit(e) {
		e.preventDefault();
	}

	render() {
		let {
			filterType,
			retailersList,
			brandsList,
			results,
			selectedRetailer,
			selectedBrand
		} = this.state;

		let status;
		if(!isEmpty(selectedRetailer.value)) {
			status = selectedRetailer;
		}
		if(!isEmpty(selectedBrand.value)) {
			status = selectedBrand;
		}
		return (
			<div className="permissions-menu">
				<h2 className="text-center">Permissions</h2>
				<div id="permissions-tab">
					<div className="back-btn-div">
						<a className="back-btn" href="#/admin">
							<i className="fa fa-angle-left" aria-hidden="true"></i>
							<span>Back</span>
						</a>
					</div>
					<div className="permissions-tab__form">
						<form onSubmit={this.onHandleSubmit}>
							<div className="permissions-tab__filters">
								{ (filterType === 'retailer' || filterType === '') &&
									<div className="form-group row" id="retailer-select">
										<label htmlFor="retailer">Filter by Retailer</label>
										<Select
											className="react-select"
											options={retailersList}
											onChange={this.handleRetailerChange}
											value={selectedRetailer}
											placeholder="Select One"
										/>
										<div className="search-style">
											<button
												className="btn btn-default"
												id="search-retailers"
												onClick={this.getRetailersResults}
											>
												Search
											</button>
										</div>
									</div>
								}
								{ (filterType === 'brand' || filterType === '') &&
									<div className="form-group row" id="brand-select">
										<label htmlFor="brand">Filter by Brand</label>
										<Select
											className="react-select"
											options={brandsList}
											onChange={this.handleBrandChange}
											value={selectedBrand}
											placeholder="Select One"
										/>
										<div className="search-style">
											<button
												className="btn btn-default"
												id="search-brands"
												onClick={this.getBrandsResults}
											>
												Search
											</button>
										</div>
									</div>
								}
							</div>
							<div className="reset-btn-div">
								<button
									className="btn btn-default"
									id="reset-btn"
									onClick={this.handleClickResetBtn}
								>
									Reset
								</button>
							</div>
						</form>
					</div>

				</div>
				{filterType !== '' &&
					<SearchedItems
						filterType={filterType}
						results={results}
						selectionStatus={status}
					/>
				}
			</div>
		);
	}
}

export default Filters;