import {
  FETCH_POSTOFFICE_REQUEST,
  FETCH_POSTOFFICE_SUCCESS,
  FETCH_POSTOFFICE_FAILURE
} from './actionTypes';

/*
 * action creators
 */

export function doFetchPostOfficeData(atts) {
  return {
    type: FETCH_POSTOFFICE_REQUEST,
    atts
  };
}

export function doFetchPostOfficeDataFullfilled(payload) {
  return {
    type: FETCH_POSTOFFICE_SUCCESS,
    payload
  };
}

export function doFetchPostOfficeDataError(error) {
  return {
    type: FETCH_POSTOFFICE_FAILURE,
    error
  };
}
