import React from 'react';
import './Card.scss';

export class OmniStatsCard extends React.PureComponent {
  setStatsIcon(statsProp, stats) {
    let { current, prev } = stats[statsProp];

    const downContent = (
      <span className="down-color">
        <i className="fa fa-caret-down" />
      </span>
    );
    const upContent = (
      <span className="text-success">
        <i className="fa fa-caret-up" />
      </span>
    );

    if (current <= prev) {
      return downContent;
    } else {
      return upContent;
    }
  }

  setStatTitle(key) {
    let vText = '';
    if ('totalProducts' === key) {
      vText = 'Total Products';
    } else if ('flaggedProducts' === key) {
      vText = 'Total Flagged Products';
    } else if ('totalRetailers' === key) {
      vText = 'Total Retailers';
    }
    return vText;
  }

  setIconText(statsIconText) {
    if (!statsIconText) return;
    return <span>( {statsIconText} )</span>;
  }

  render() {
    let { statsProp, stats } = this.props;
    let { current } = stats[statsProp];
    return (
      <div className="card card-stats">
        <div className="content">
          <div className="row text-left">
            <div className="col-xs-12 stats-header">
              <h5>{this.setStatTitle(statsProp)}</h5>
            </div>
            <div className="col-xs-12 col-md-12 ">
              <div className="stats-value-col">
                <span>
                  {current} {'  '}
                </span>
                {/* {this.setStatsIcon(statsProp, stats)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OmniStatsCard;
