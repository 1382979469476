import React from 'react';
import { Grid, Row, Col, Modal, FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { renderInputField } from 'utils/form-helpers';
import { toastr } from 'react-redux-toastr';

let InviteTeamMembeForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Field
          name="email"
          label="Email"
          placeholder="invite member using email-id"
          component={renderInputField}
          fieldClass="form-control"
          componentClass="omni-form-field"
          type="email"
        />
      </FormGroup>
    </form>
  );
};

InviteTeamMembeForm = reduxForm({
  form: 'inviteTeamMembeForm'
})(InviteTeamMembeForm);

class InviteTeamMemberModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  onModalHide() {
    let { callback } = this.props;
    this.setState(
      {
        showModal: false
      },
      () => {
        callback && callback();
      }
    );
  }

  submitForm(values) {
    if (_.isEmpty(values)) {
      toastr.error('ERROR', 'Please enter Email ID!');
      return;
    }

    toastr.success('SUCCESS', 'Invitation sent successfully.');
    // Hide modal
    this.onModalHide();
  }

  render() {
    const { showModal } = this.state;

    return (
      <Modal animation={false} show={showModal} onHide={this.onModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            <Row className="flagged-item-container">
              <Col xs={12}>
                <InviteTeamMembeForm onSubmit={this.submitForm} />
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
      </Modal>
    );
  }
}

export default InviteTeamMemberModal;
