import React from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import PostOfficeHeader from "./post-office/Header/Header";
import FilesList from "./post-office";
import Administration from "./post-office/Administration";
import FileView from "./post-office/FileView";
import Auth from "utils/auth";
import store from "store";
import _ from "lodash";
import {
  UserRetailerAssociation,
  RetailerListAssociation,
} from "./user-retailer-association";
import Notification from "./notification-center";
import AdminHomePage from "./common/AdminHomePage";
import AdminHeader from "./common/AdminHeader";
import doFetchNotificationData from "./thunks/thunkNotificationData";
import { connect } from "react-redux";
import "./admin.scss";
import Users from "./manage-users";
import Permissions from "./permissions";

class Admin extends React.PureComponent {
  componentWillMount() {
    // If User is not logged in then redirect to login page.
    /* let user = Auth.getUser();
    if (_.isEmpty(user)) {
      store.set('omni_deeplink', {
        path: this.props.location.pathname,
        qs: this.props.location.search
      });
      this.props.history.push('/login');
    } */
  }

  componentDidMount() {
    this.props.doFetchNotificationData();
    let user = Auth.getUser();
    let isAdmin = _.find(user.roles, function (o) {
      return o.name === "ADMIN";
    });
    if (_.isEmpty(isAdmin)) {
      store.set("omni_deeplink", {
        path: this.props.location.pathname,
        qs: this.props.location.search,
      });
      this.props.history.push("/login");
    }
  }
  render() {
    let { notification } = this.props;
    let content = "";

    content = (
      <div className="admin-route">
        <Switch>
          <Route exact path="/admin/post-office" component={FilesList} />
          <Route
            exact
            path="/admin/user-retailer-association"
            component={UserRetailerAssociation}
          />
          <Route
            exact
            path="/admin/retailers"
            component={RetailerListAssociation}
          />
          <Route exact path="/admin/manage-users" component={Users} />
          <Route exact path="/admin/permissions" component={Permissions} />
          <Route
            exact
            path="/admin/post-office/settings"
            component={Administration}
          />
          <Route
            exact
            path="/admin/post-office/notification"
            component={Notification}
          />
          <Route
            exact
            path="/admin/post-office/comparison/:fId"
            component={FileView}
          />
          <Route exact path="/admin" component={AdminHomePage} />
        </Switch>
      </div>
    );
    // console.log(this.props.location.pathname);
    let pathName = this.props.location.pathname;
    const adminHeader = pathName.includes("post-office") ? (
      <PostOfficeHeader unseenCount={notification.unseenCount} />
    ) : (
      <AdminHeader isBrandView />
    );
    return (
      <div id="post-office" className="container-fluid">
        {adminHeader}
        {content}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { notification: state.admin.notification.data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchNotificationData: () => {
      dispatch(doFetchNotificationData());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
