import React, { useState } from "react";
import { Grid, Modal } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { omni_axios_instance } from "./../../utils/axios";

export default function FileUploadModal(props) {
  let { hide, show } = props;
  const [file, setFile] = useState({
    fileName: "",
    selectedFile: "",
  });

  const onFileChange = (event) => {
    console.log("event: ", event.target.files[0], "\n", event.target.files);
    // setSelectedFile(event.target.files[0]);
    // console.log("selectedFile", selectedFile);

    let selectedFile = event.target.files[0];
    let fileName = selectedFile.name;
    setFile({
      fileName,
      selectedFile,
    });
  };

  const handleUpload = async () => {
    let { fileName, selectedFile } = file;
    const formData = new FormData();
    formData.append("file", selectedFile);
    if (fileName.length > 0) {
      await omni_axios_instance
        .post(
          `https://api.pricescraper.net/retailerBrandProductScrapings/importOhData/:retailer_brand_product_scraping`,
          formData
        )
        .then((response) => {
          if (response.data.data) {
            toastr.success("SUCCESS", "File uploaded successfully.");
            // this.setState({
            //   fileHeaders: response.data.data,
            //   count: response.data.count
            // });
            // this.handleSelect('match-data');
            // this.handleClose();
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Please select file.");
    }
  };

  return (
    <div className="modal-container">
      <Modal
        animation={false}
        autoFocus={false}
        enforceFocus={false}
        className="FileUploaModal"
        show={show}
        onHide={() => {
          hide(false);
          setFile({
            fileName: "",
            selectedFile: "",
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className>File Upload Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="control-label">Select File To Upload :</label>
            <input type="file" onChange={onFileChange} />
            <button
              className="btn btn-default Retailer-ContactForm__footer__rightcol-btn"
              onClick={handleUpload}
            >
              Upload
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
