import { combineReducers } from 'redux';
import {
  SET_SEARCH,
  REFRESH_EXPORT_HISTORY
} from 'dashboard/actions/actionTypes';

function searchString(state = '', action) {
  switch (action.type) {
    case SET_SEARCH:
      return action.searchStr;

    default:
      return state;
  }
}

function refreshExportHistory(state = false, action) {
  switch (action.type) {
    case REFRESH_EXPORT_HISTORY:
      return action.val;

    default:
      return state;
  }
}

const common = combineReducers({
  searchString,
  refreshExportHistory
});
export default common;
