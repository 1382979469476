import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  ControlLabel
} from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import reactCSS from 'reactcss';
import queryString from 'querystringify';
import moment from 'moment';
// import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const styles = reactCSS({
  default: {
    export: {
      textTransform: 'uppercase',
      color: '#000',
      fontSize: '15px',
      fontWeight: '700',
      borderColor: '#000'
    },
    datePicker: {
      paddingLeft: '5px'
    },
    selectDate: {
      display: 'flex',
      alignItems: 'center'
    }
  }
});

function ManageReportsForm() {
  let date = new Date();
  const [currentDate, setDate] = useState(date);
  const exportReports = () => {
    let month;
    month = moment(new Date(`${currentDate}`)).format('MM-YYYY');
    let query = {
      month: month
    };
    let user = Auth.getUser();
    let brandId = user.brand._id;
    let title = user.brand.name;
    query = queryString.stringify(query);
    omni_axios_instance
      .get(`/report_summary/${brandId}?${query}`, {
        responseType: 'blob'
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        let fileName = `${title}_${month}.xlsx`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        console.log('error', error);
      });
  };
  return (
    <div className="export-reports">
      <Form className="export-reports-con">
        <Row>
          <Col sm={4}>
            <FormGroup>
              <div className="select-date" style={styles.selectDate}>
                <ControlLabel>Select Month:</ControlLabel>
                <div style={styles.datePicker}>
                  <DatePicker
                    className="form-control"
                    selected={currentDate}
                    onChange={value => setDate(value)}
                    minDate={new Date('Aug, 2018')}
                    maxDate={new Date()}
                    placeholderText="Date Picker Here"
                    dateFormat="MMM yyyy"
                    showMonthYearPicker
                  />
                </div>
              </div>
            </FormGroup>
          </Col>
          <Col sm={8}>
            <FormGroup className="text-right">
              <Button
                style={styles.export}
                id="export-reports-btn"
                bsStyle="default"
                onClick={() => exportReports()}
              >
                export
                <i className="fa fa-file-excel-o" />
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ManageReportsForm;
