import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import confirm from 'dashboard/components/confirm';
import {
  Row,
  Col,
  Table,
  FormGroup,
  FormControl,
  Button
} from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
// import { bindActionCreators } from 'redux';
// import { doFetchContactInfo } from 'dashboard/actions/contactInfo';
import thunkFetchContactInfo from '../thunks/thunkFetchContactInfo';
import Auth from 'utils/auth';

class ContactInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.addContact = this.addContact.bind(this);
    this.deleteContact = this.deleteContact.bind(this);

    this.state = {
      name: '',
      lastName: '',
      email: '',
      role: ''
    };
  }

  handleChange(e) {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value
    });
  }

  deleteContact(id, emailId) {
    confirm('Are you sure you want to delete this contact ?').then(
      result => {
        // `proceed` callback
        omni_axios_instance.delete(`/addressBooks/${id}`).then(response => {
          toastr.success('SUCCESS', 'Contact deleted successfully.');
          /*this.props.doFetchContactInfo({
              isRefresh: true
            });*/
          this.props.doFetchContactInfo();
        });
        console.log('proceed called');
      },
      result => {
        // `cancel` callback
      }
    );
  }

  addContact(e) {
    e.preventDefault();
    let user = Auth.getUser();
    let brandId = user.brand._id;
    let { retailer } = this.props;
    let { name, lastName, email, role } = this.state;
    let data = {
      firstName: name,
      lastName: lastName,
      email: email,
      brand: brandId,
      retailer: retailer,
      role: role
    };
    omni_axios_instance.post(`/addressBooks`, data).then(response => {
      toastr.success('SUCCESS', 'Contact added successfully.');
      this.setState(
        {
          name: '',
          lastName: '',
          email: '',
          role: ''
        },
        () => this.props.doFetchContactInfo()
      );
    });
  }

  render() {
    let { contactInfo } = this.props;
    let { retailer_contacts } = contactInfo;
    let contactData = '';
    const isBrandAdmin = Auth.isBrandAdmin();

    if (_.isEmpty(retailer_contacts)) {
      contactData = (
        <div className="alert alert-info">Contact(s) not found.</div>
      );
    } else {
      contactData = (
        <Table
          responsive
          className="ContactInfoTable Table-Scrollable table-bigboy"
        >
          <thead className="ContactInfoTableHead">
            <tr>
              <th>
                <div className="thRow-Inner">
                  <span className="col">NAME</span>
                </div>
              </th>
              <th>
                <div className="thRow-Inner">
                  <span className="col">EMAIL</span>
                </div>
              </th>
              <th>
                <div className="thRow-Inner">
                  <span className="col">ROLE</span>
                </div>
              </th>
              {isBrandAdmin && (
                <th>
                  <div className="thRow-Inner">
                    <span className="col">ACTION</span>
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="ContactInfoTableBody">
            {retailer_contacts.map((item, key) => (
              <tr key={item._id} className="ContactInfoTable-Row">
                <td>
                  {item.firstName} {item.lastName}
                </td>
                <td>{item.email}</td>
                <td>{item.role}</td>
                {isBrandAdmin && (
                  <td className="delete-contact">
                    <i
                      onClick={() => this.deleteContact(item._id, item.email)}
                      className="fa fa-trash"
                      aria-hidden="true"
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }

    return (
      <div className="contact-info-tab">
        {contactData}
        <form onSubmit={this.addContact}>
          <FormGroup controlId="formBasicText">
            <p className="contact-form-text">
              Add an Additional Point of Contact:
            </p>
            <Row>
              <Col sm={3}>
                <FormControl
                  type="text"
                  value={this.state.name}
                  name="name"
                  placeholder="First Name"
                  onChange={this.handleChange}
                  required
                />
              </Col>
              <Col sm={3}>
                <FormControl
                  type="text"
                  value={this.state.lastName}
                  name="lastName"
                  placeholder="Last Name"
                  onChange={this.handleChange}
                  required
                />
              </Col>
              <Col sm={3}>
                <FormControl
                  type="email"
                  name="email"
                  value={this.state.email}
                  placeholder="Email"
                  onChange={this.handleChange}
                  required
                />
              </Col>
              <Col sm={3}>
                <FormControl
                  type="text"
                  name="role"
                  value={this.state.role}
                  placeholder="Role"
                  onChange={this.handleChange}
                  required
                />
              </Col>
            </Row>
            <FormControl.Feedback />
          </FormGroup>
          <div className="text-right">
            <Button type="submit">
              <strong>SUBMIT</strong>
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

// const mapDispatchToProps = dispatch => {
//   return bindActionCreators(
//     {
//       doFetchContactInfo
//     },
//     dispatch
//   );
// };
const mapDispatchToProps = dispatch => {
  return {
    doFetchContactInfo: value => dispatch(thunkFetchContactInfo())
  };
};

const mapStateToProps = state => {
  return {
    contactInfo: state.contactInfo,
    retailer_contacts: state.contactInfo.retailer_contacts
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContactInfo)
);
