import React from 'react';
import { omni_axios_instance } from 'utils/axios';
class Requirement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      flagValues: {}
    };
  }

  componentDidMount() {
    omni_axios_instance.get(`/getFlagValues`).then(response => {
      this.setState({
        flagValues: response.data
      });
    });
  }
  render() {
    const { caution, require_review, unflagged } = this.state.flagValues;
    return (
      <div className="po-requirement-con">
        <p className="po-review">
          Requires Review <span>{require_review}</span>
        </p>
        <p className="po-caution">
          Caution
          <span>{caution}</span>
        </p>
        <p className="po-unflagged">
          Unflagged
          <span>{unflagged}</span>
        </p>
      </div>
    );
  }
}

export default Requirement;
