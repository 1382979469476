import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

class ReatailerPopUp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.show || false,
      retailerList: [],
      selectedAll: false
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  componentDidMount() {
    let { selectedRetailers } = this.props;
    let { retailers } = selectedRetailers;
    retailers = _.sortBy(retailers, function(o) {
      return o.name;
    });
    this.setState({ retailerList: retailers });
  }

  handleCheckbox = (e, value) => {
    let { retailerList } = this.state;
    _.forEach(retailerList, (item, key) => {
      if (e.target.value === item._id) {
        item.flag = !item.flag;
      }
    });

    this.setState({
      retailerList: retailerList
    });
  };

  selectAll = e => {
    e.preventDefault();
    let { retailerList } = this.state;
    // console.log(selectedAll, e.target.checked);
    _.forEach(retailerList, (item, key) => {
      item.flag = e.target.checked;
    });

    this.setState({
      retailerList: retailerList,
      selectedAll: e.target.checked
    });
  };

  handleUpdate(e) {
    e.preventDefault();
    let { selectedRetailers, getBrand } = this.props;
    let retailers = [];
    var textinputs = document.querySelectorAll(
      'input[type=checkbox].retailerCheckbox'
    );
    _.filter(textinputs, function(el) {
      return retailers.push({ retailer: el.value, flag: el.checked });
    });

    let retailerObj = Object.assign({}, selectedRetailers, {
      retailers: retailers
    });

    omni_axios_instance
      .put(`/brands/${retailerObj._id}`, retailerObj)
      .then(res => {
        if (res.status === 200) {
          toastr.success('SUCCESS', 'Successfully updated');
          this.onModalHide();
          getBrand();
        }
      });
  }

  onModalHide() {
    let { callback } = this.props;
    this.setState(
      {
        showModal: false
      },
      () => {
        callback && callback();
      }
    );
  }
  render() {
    let { showModal, retailerList } = this.state;
    let { selectedRetailers } = this.props;
    // console.log(selectedRetailers,'selectedRetailers')
    return (
      <div className="modal-container">
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="RetailerPopUpModal"
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedRetailers.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="checkListForm">
              {/*  <div className="isSelectAll">
                <input type="checkbox" onChange={(e) => this.selectAll(e)} checked={selectedAll ? true : false}/>
                 <label>Select All</label>
              </div> */}
              <div className="checkListItems">
                {retailerList.map((item, index) => {
                  if (!_.isEmpty(item.retailer)) {
                    return (
                      <div className="retailerCheckList" key={index}>
                        <input
                          type="checkbox"
                          className="retailerCheckbox"
                          defaultChecked={item.flag ? true : false}
                          onChange={e => this.handleCheckbox(e, item)}
                          value={item.retailer._id}
                        />
                        <label>{item.retailer.name}</label>
                      </div>
                    );
                  }
                })}
              </div>
            </form>
            <div className="popup-btn">
              <Button onClick={this.onModalHide}>Cancel</Button>
              <Button bsStyle="primary" onClick={e => this.handleUpdate(e)}>
                Update
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ReatailerPopUp;
