import React from 'react';
import { Row, Col, Table, Tab, Nav, NavItem, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import UploadFileModal from './UploadFileModal';
import SuccessModal from './SuccessModal';
import Auth from 'utils/auth';
import { omni_axios_instance } from 'utils/axios';
import { toastr } from 'react-redux-toastr';
import merge from 'lodash/merge';
import classNames from 'classnames';

const fileArr = [];
const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i className="fa fa-sort" />
      </components.DropdownIndicator>
    )
  );
};
const customStyles = {
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: 'black',
      marginBottom: 10
    };
  },
  control: base => ({
    ...base,
    height: 20,
    minHeight: 28,
    padding: 0
  }),
  indicatorsContainer: base => ({
    ...base,
    height: 24
  }),
  container: defaultStyles => {
    return {
      ...defaultStyles,
      width: '70%'
    };
  },
  valueContainer: defaultStyles => {
    return {
      ...defaultStyles,
      height: 22
    };
  },
  option: defaultStyles => {
    return {
      ...defaultStyles,
      padding: 10
    };
  }
};
class ImportContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      eventKey: 'choose-type',
      show: false,
      fileFormat: '',
      count: '',
      fileName: '',
      firstName: null,
      lastName: null,
      email: null,
      retailer: null,
      role: null,
      selectedOption: null,
      selectedFile: null,
      fileHeaders: [],
      rules: null,
      flag: 'ignore'
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRadioSelect = this.handleRadioSelect.bind(this);
    this.onNext = this.onNext.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImport = this.onImport.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
  }

  onImport() {
    let { rules, selectedFile, flag } = this.state;
    let user = Auth.getUser();
    let brandId = user.brand._id;
    const formData = new FormData();
    let temp = JSON.stringify(rules);
    formData.append('rules', temp);
    formData.append('file', selectedFile);
    formData.append('flag', flag);
    omni_axios_instance
      .post(`/addressBook/import/${brandId}`, formData)
      .then(response => {
        toastr.success('SUCCESS', 'Contact imported successfully.');
        this.setState({
          fileHeaders: null,
          count: null,
          firstName: null,
          lastName: null,
          email: null,
          retailer: null,
          role: null
        });
        this.props.onSuccessModalShow();
        this.handleSelect('choose-type');
      })
      .catch(error => {
        console.log(error);
      });
  }
  handleSelect(eventKey) {
    let obj = {
      eventKey
    };
    if (eventKey === 'choose-type') {
      obj = {
        eventKey,
        fileHeaders: null,
        count: null
      };
    }
    this.setState(obj);
  }
  onNext() {
    let { rules } = this.state;
    let checkFields = ['firstName', 'lastName', 'email', 'retailer', 'role'];
    let result = checkFields.every(key => rules && rules.hasOwnProperty(key));
    if (result) {
      this.handleSelect('review');
    } else {
      alert('Please select all fields');
    }
  }
  handleRadioSelect(event) {
    let flag = event.target.value;
    this.setState({
      flag
    });
  }
  handleChange = (selectedOption, stateName) => {
    let obj = { [stateName.name]: selectedOption.value };
    fileArr.push(obj);
    let rules = merge.apply(null, [{}].concat(fileArr));
    this.setState({
      [stateName.name]: selectedOption,
      rules
    });
  };

  handleUpload() {
    let { fileName, selectedFile } = this.state;
    const formData = new FormData();
    formData.append('file', selectedFile);
    if (fileName.length > 0) {
      omni_axios_instance
        .post(`/getAddressBookHeaders`, formData)
        .then(response => {
          if (response.data.data) {
            toastr.success('SUCCESS', 'File uploaded successfully.');
            this.setState({
              fileHeaders: response.data.data,
              count: response.data.count
            });
            this.handleSelect('match-data');
            this.handleClose();
          } else {
            alert(response.data.message);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      alert('Please select file.');
    }
  }
  handleClose() {
    this.setState({ show: false, fileFormat: '', fileName: '' });
  }
  onFileInputChange(e) {
    let selectedFile = e.target.files[0];
    let fileName = selectedFile.name;
    this.setState({
      fileName,
      selectedFile
    });
  }
  handleShow(fileFormat) {
    this.setState({ show: true, fileFormat });
  }
  getChooseTypeTab() {
    const leftCol = width => {
      if (width <= 1380) {
        return classNames({
          'col-sm-5': true
        });
      } else {
        return classNames({
          'col-sm-offset-3': true,
          'col-sm-3': true
        });
      }
    };
    const rightCol = width => {
      if (width <= 1380) {
        return classNames({
          'col-sm-7': true
        });
      } else {
        return classNames({
          'col-sm-6': true
        });
      }
    };
    let chooseTypeTab = (
      <div className="choose-type-tab">
        <Row className="choose-import-type-con">
          <div className={leftCol(window.innerWidth)}>Choose import type:</div>
          <div className={`${rightCol(window.innerWidth)} upload__btn`}>
            <div className="import-type__btnsblock">
              <div className="import-type__btns">
                <Button
                  className="default-btn upload-csv"
                  onClick={() => this.handleShow('.csv')}
                >
                  Upload a csv file
                </Button>
                <Button
                  className="default-btn upload-xls"
                  onClick={() => this.handleShow('.xlsx')}
                >
                  Upload an excel file
                </Button>
              </div>
            </div>
          </div>
        </Row>
        <Row className="import-type__sampledownloads">
          <div className={leftCol(window.innerWidth)}>
            Download sample file types:
          </div>
          <div className={rightCol(window.innerWidth) + ' sample-downloads'}>
            Sample:
            <a
              className="sample-csv"
              href="./sample-files/sample.csv"

              // onClick={() => (window.location = '/sample-files/sample.csv')}
            >
              Contacts (CSV)
            </a>
            or
            <a
              className="sample-xls"
              href="./sample-files/sample.xlsx"

              // onClick={() => (window.location = '/sample-files/sample.xlsx')}
            >
              Contacts (excel)
            </a>
          </div>
        </Row>
      </div>
    );

    return chooseTypeTab;
  }
  getMatchDataTab() {
    let {
      fileHeaders,
      firstName,
      lastName,
      email,
      retailer,
      role
    } = this.state;
    let matchDataTab = '';
    let options = [];
    fileHeaders &&
      fileHeaders.map((option, key) => {
        return options.push({ value: option, label: option });
      });
    matchDataTab = (
      <div className="match-data-tab">
        <h5 className="match-data-tab__title">Match Your data</h5>
        <p className="match-data-tab__info">
          You can preview rows from your file to ensure that data will be
          imported into the correct field
        </p>
        <Table className="match-data-table" bordered condensed hover>
          <thead>
            <tr>
              <th>Select a Field</th>
              <th>Your file row</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="field-select">
                  <Select
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null
                    }}
                    styles={customStyles}
                    className="field-select__box"
                    name="firstName"
                    value={firstName}
                    onChange={this.handleChange}
                    options={options}
                    onChangeArguments
                    required
                    placeholder="No Match, Select..."
                  />
                </div>
              </td>
              <td className="file-row">First Name</td>
            </tr>
            <tr>
              <td>
                <div className="field-select">
                  <Select
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null
                    }}
                    styles={customStyles}
                    className="field-select__box"
                    name="lastName"
                    value={lastName}
                    onChange={this.handleChange}
                    options={options}
                    onChangeArguments
                    required
                    placeholder="No Match, Select..."
                  />
                </div>
              </td>
              <td className="file-row">LastName</td>
            </tr>
            <tr>
              <td>
                <div className="field-select">
                  <Select
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null
                    }}
                    styles={customStyles}
                    className="field-select__box"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    options={options}
                    onChangeArguments
                    required
                    placeholder="No Match, Select..."
                  />
                </div>
              </td>
              <td className="file-row">Email Address</td>
            </tr>
            <tr>
              <td>
                <div className="field-select">
                  <Select
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null
                    }}
                    styles={customStyles}
                    className="field-select__box"
                    name="retailer"
                    value={retailer}
                    onChange={this.handleChange}
                    options={options}
                    onChangeArguments
                    required
                    placeholder="No Match, Select..."
                  />
                </div>
              </td>
              <td className="file-row">Store Name</td>
            </tr>
            <tr>
              <td>
                <div className="field-select">
                  <Select
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null
                    }}
                    styles={customStyles}
                    className="field-select__box"
                    name="role"
                    value={role}
                    onChange={this.handleChange}
                    options={options}
                    onChangeArguments
                    required
                    placeholder="No Match, Select..."
                  />
                </div>
              </td>
              <td className="file-row">Roles/Responsibilities</td>
            </tr>
          </tbody>
        </Table>
        <div className="match-data-btns">
          <Button className="default-btn next-btn" onClick={this.onNext}>
            Next
          </Button>
          <Button
            className="default-btn cancel-btn"
            onClick={() => this.handleSelect('choose-type')}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    return matchDataTab;
  }
  getReviewTab() {
    let { count } = this.state;
    let reviewTab = (
      <div className="review-tab">
        <div className="tab-title">Review and Finalize</div>
        <div className="review-con">
          <p className="contact-numbers">
            Your file conatins {count} contacts who will be added or updated.
          </p>
          <p className="handle-files">
            How do you want to handle duplicates we found in your file?
          </p>
          <div className="review-radios">
            <div>
              <input
                type="radio"
                onClick={this.handleRadioSelect}
                name="review"
                value="ignore"
                defaultChecked
              />
              <span>
                Ignore the contact in the file and keep the information as it is
              </span>
            </div>
            <div>
              <input
                type="radio"
                onClick={this.handleRadioSelect}
                name="review"
                value="update"
              />
              <span>
                Update the existing contact with the data in the file I uploaded
              </span>
            </div>
          </div>

          <div className="import-file-btns">
            <Button className="import-btn default-btn" onClick={this.onImport}>
              import
            </Button>
            <Button
              className="cancel-btn"
              onClick={() => this.handleSelect('choose-type')}
            >
              cancel
            </Button>
          </div>
        </div>
      </div>
    );
    return reviewTab;
  }
  render() {
    let { eventKey, fileFormat, fileName, show } = this.state;
    let { showSuccessModal } = this.props;
    let file = {
      fileFormat,
      fileName
    };
    return (
      <>
        <div className="import-contacts">
          <Tab.Container
            id="tabs-for-contact"
            activeKey={eventKey}
            // defaultActiveKey={'choose-type'}
            onSelect={this.handleSelect}
            className=""
          >
            <Col sm={12} className="">
              <Row className="clearfix">
                <Nav bsStyle="tabs">
                  <NavItem
                    eventKey="choose-type"
                    disabled={
                      eventKey !== 'choose-type' || 'match-data' ? false : true
                    }
                  >
                    <h4>Step1: Choose Type</h4>
                  </NavItem>
                  <NavItem
                    eventKey="match-data"
                    disabled={eventKey !== 'match-data'}
                  >
                    <h4>Step2: Match Data</h4>
                  </NavItem>
                  <NavItem eventKey="review" disabled={eventKey !== 'review'}>
                    <h4>Step3: Review</h4>
                  </NavItem>
                </Nav>
              </Row>
              <Row>
                <Col sm={12} className="import-contact__steps">
                  <Tab.Content animation={false}>
                    <Tab.Pane eventKey="choose-type">
                      {this.getChooseTypeTab()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="match-data">
                      {this.getMatchDataTab()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="review">{this.getReviewTab()}</Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Col>
          </Tab.Container>
        </div>
        {show && (
          <UploadFileModal
            callback={this.handleClose}
            onFileInputChange={this.onFileInputChange}
            handleUpload={this.handleUpload}
            file={file}
          />
        )}
        {showSuccessModal && (
          <SuccessModal
            callback={this.props.onSuccessModalClose}
            onContinue={this.props.onContinue}
          />
        )}
      </>
    );
  }
}
export default ImportContact;
