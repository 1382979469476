import {
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILURE
} from './actionTypes';

export function doFetchNotificationData(atts) {
  return { type: FETCH_NOTIFICATION_REQUEST, atts };
}

export function doFetchNotificationDataFullfilled(payload) {
  return { type: FETCH_NOTIFICATION_SUCCESS, payload };
}

export function doFetchNotificationDataError(error) {
  return { type: FETCH_NOTIFICATION_FAILURE, error };
}
