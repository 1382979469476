import React from 'react';
import Filters from './Filters';
import './permissions.scss';


const Permissions = () => {
	return (
		<div className="container">
			<Filters />
		</div>
	);
}
export default Permissions;