import React from 'react';
import {
  Form,
  FormGroup,
  // FormControl,
  Col,
  ControlLabel,
  Row,
  Button
} from 'react-bootstrap';
import CreatableSelect from 'react-select/lib/Creatable';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { omni_axios_instance } from 'utils/axios';

class Administration extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      threshHoldsData: {},
      isChecked:false,
      adminSettingId: '',
      selectedEmails: []
    };
    this.settingChange = this.settingChange.bind(this);
    this.adminSaveSettings = this.adminSaveSettings.bind(this);
    this.ignoreImageDataHandle = this.ignoreImageDataHandle.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    // this.getThresholdData = this.getThresholdData.bind(this);
  }

  componentDidMount() {
    this.getProductDetailsRobot();
  }

  handleChangeEmail(value) {
    let { threshHoldsData } = this.state;
    let emails = _.map(value, 'value');
    threshHoldsData.emailAlerts = emails;
    this.setState({
      selectedEmails: value,
      threshHoldsData
      
    });
  }

  getProductDetailsRobot = () => {
    omni_axios_instance.get('/settings/threshold').then(res => {
      let emailAlerts = _.map(res.data.settings.emailAlerts, (item, index) => {
        return {
          label: item,
          value: item
        };
      });
      this.setState({
        threshHoldsData: res.data.settings,
        isChecked: res.data.settings.ignoreImageData,
        adminSettingId: res.data._id,
        selectedEmails: emailAlerts
      });
    });
  };

  getThresholdData = (product = {}, param, threshHoldValue) => {
    if (_.isEmpty(product)) {
      return;
    }
    let threshHoldData = _.map(product, (vObj, key) => {
      return (
        <div key={key} className="grid-text">
          <div className="left-item">{`Diff. Column: "${key}"`}</div>
          <div className="right-item">
            <form>
              <input
                className="inputValue"
                type="text"
                value={`${vObj}%`}
                onChange={e => this.settingChange(e, key, param, product)}
              />
            </form>
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="grid-text">
          <div className="left-item-heading">{threshHoldValue} Robot</div>
        </div>
        {threshHoldData}
      </div>
    );
  };

  ignoreImageDataHandle(e, key) {
    let { threshHoldsData } = this.state;
    let obj = threshHoldsData;
    obj[key] = e.target.checked;

    this.setState({
      threshHoldsData: obj,
      isChecked: e.target.checked
    });
  }

  settingChange(e, key, param, productValue) {
    let { threshHoldsData } = this.state;
    let obj = threshHoldsData;
    let value = parseInt(e.target.value, 10);
    value = value >= 1 ? value : 0;
    obj[param][key] = value;
    this.setState({
      threshHoldsData: obj
    });
  }

  adminSaveSettings(e) {
    e.preventDefault();
    let newSettings = {};
    let { threshHoldsData, adminSettingId } = this.state;
    newSettings.settings = threshHoldsData;
    omni_axios_instance
      .put(`/settings/${adminSettingId}`, newSettings)
      .then(response => {
        toastr.success('SUCCESS', 'Settings updated.');
        this.getProductDetailsRobot();
      });
  }

  render() {
    const { isChecked, threshHoldsData, selectedEmails } = this.state;

    return (
      <div className="po-administration">
        <div className="onSetting-nav-btn">
          <Link to="/admin/post-office" className="backBtn">
            <i className="fa fa-angle-left" aria-hidden="true" />
            <span>Back</span>
          </Link>

          {/* <Link to="/dashboard/summary" className="link-to-nav">
            <span>Dashboard</span>
          </Link>

          <Link to="/admin/user-retailer-association" className="link-to-nav">
            <span>Show/Hide Retailer</span>
          </Link> */}
        </div>

        <Row className="threshHoldCon">
          <Col sm={4} className="po-admin-form">
            <p>Administration</p>
            <Form>
              <FormGroup
                className="inputControls"
                controlId="formHorizontalEmail"
              >
                <Col componentClass={ControlLabel} sm={12}>
                  Email Alert:
                </Col>
                <Col sm={10} className="emailSelection">
                  <CreatableSelect
                    isMulti
                    simpleValue
                    type="email"
                    value={selectedEmails}
                    isClearable={false}
                    placeholder="Select..."
                    options={false}
                    onChange={this.handleChangeEmail}
                    autoComplate="email"
                    inputProps={{ autoComplete: 'off' }}
                  />
                </Col>
              </FormGroup>

              {/*    <FormGroup
                className="inputControls"
                controlId="formHorizontalPassword"
              >
                <Col componentClass={ControlLabel} sm={12}>
                  Password:
                </Col>
                <Col className="password" sm={10}>
                  <FormControl
                    className="input-sm"
                    type="password"
                    placeholder="Password"
                  />
                </Col>
              </FormGroup> */}
            </Form>
          </Col>
          <Col sm={8} className="po-thresholds">
            <p>Thresholds</p>

            <div className="grid-content">
              <Row className="show-grid">
                <Col xs={12} sm={4}>
                  <div className="grid-text image-data-checked">
                    <div className="left-item-heading ">Ignore image data?</div>
                    <div className="right-item">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={e =>
                          this.ignoreImageDataHandle(e, 'ignoreImageData')
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="show-grid">
                {/* Product Details */}
                <Col xs={12} sm={4} md={4}>
                  {this.getThresholdData(
                    threshHoldsData.product,
                    'product',
                    'Product Details'
                  )}
                </Col>
                {/*Brand LP */}

                <Col xs={12} sm={4} md={4}>
                  {this.getThresholdData(
                    threshHoldsData.brand,
                    'brand',
                    'Brand LP '
                  )}
                  {}
                </Col>
                {/* Homepage */}
                <Col xs={12} sm={4} md={4}>
                  {this.getThresholdData(
                    threshHoldsData.homepage,
                    'homepage',
                    'Homepage'
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="text-right">
          <Button
            bsStyle="primary"
            className="po-admin-save-btn"
            onClick={e => this.adminSaveSettings(e)}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}
export default Administration;
