import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { omni_axios_instance } from 'utils/axios';
import EmailPreviewModal from '../components/EmailPreviewModal';

class EmailHistory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      emailHistory: [],
      showModal: false,
      emailData: ''
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.emailPreview = this.emailPreview.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let { eventKey } = nextProps;
    if (eventKey === 'emailHistory') {
      this.getEmailHistory();
    }
  }

  onModalHide() {
    this.setState({ showModal: false });
  }

  getEmailHistory() {
    // let brandId = user.brand._id;
    // let user = Auth.getUser();
    let { retailerId } = this.props;
    omni_axios_instance
      .get(`/email_history?retailer_brand_scraping=${retailerId}`)
      .then(response => {
        this.setState({
          emailHistory: response.data
        });
      });
  }
  emailPreview(data) {
    this.setState({ emailData: data, showModal: true });
  }

  showPreview(id, e) {
    e.preventDefault();
    // let {show} =this.state;
    console.log(id);
    this.setState({
      show: true
    });
  }

  render() {
    let { emailHistory, showModal, emailData } = this.state;
    let htmlContent = '';

    if (_.isEmpty(emailHistory)) {
      htmlContent = (
        <div>
          <div className="alert alert-info">Email history(s) not found.</div>
        </div>
      );
    } else {
      htmlContent = (
        <div className="Scrollable-Content">
          {emailHistory.map((item, key) => (
            <div className="email-history-record" key={item._id}>
              <div className="email-history-record-lcol">
                <strong>{item.subject}</strong>
              </div>
              <div className="email-history-record-lcol">
                Emailed on{' '}
                {moment(new Date(`${item.createdAt}`)).format('MM/DD/YYYY')}
              </div>
              <div
                className="email-history-record-lcol"
                onClick={() => this.emailPreview(item.htmlResponse)}
              >
                <i className="fa fa-eye" /> Preview
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="email-history-tab">
        <EmailPreviewModal
          showModal={showModal}
          onModalHide={this.onModalHide}
          emailData={emailData}
        />

        {htmlContent}
      </div>
    );
  }
}

export default EmailHistory;
