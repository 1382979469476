import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

// Frontend dashboard reducers
import retailers from "dashboard/reducers/retailers";
import months from "dashboard/reducers/months";
import retailer from "dashboard/reducers/retailer";
import product from "dashboard/reducers/product";
import products from "dashboard/reducers/products";
import allRetailersProducts from "dashboard/reducers/allRetailersProducts";
import common from "dashboard/reducers/common";
import month from "dashboard/reducers/month";
import stats from "dashboard/reducers/stats";
import contactInfo from "dashboard/reducers/contactInfo";
import retailerScrappingInfo from "dashboard/reducers/retailerScrappingInfo";
import retailerBasket from "./dashboard/reducers/retailerBasket";
// Admin reducers
import admin from "admin/reducers";

export default (history) =>
  combineReducers({
    months,
    month,
    retailers,
    retailer,
    product,
    products,
    allRetailersProducts,
    common,
    contactInfo,
    stats,
    router: connectRouter(history),
    form: formReducer,
    toastr: toastrReducer,
    admin,
    retailerScrappingInfoData: retailerScrappingInfo,
    retailerBasket,
  });

// export default rootReducer;
