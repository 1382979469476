import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { omni_axios_instance } from 'utils/axios';
import { toastr } from 'react-redux-toastr';
const SignupSchema = Yup.object().shape({
  sitenalysis_Username: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  users__email: Yup.string()
    .email('Enter valid email')
    .required('Required')
});

class ForgotPasswordModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeLoginModal = this.closeLoginModal.bind(this);
  }
  componentDidMount() {
    this.closeLoginModal();
  }

  handleSubmit(values, actions) {
    // Method : POST /forgotPassword   parameter  : email (req.body.email)
    actions.setSubmitting(true);
    // console.log('values', values);
    omni_axios_instance
      .post(`/forgotPassword`, {
        email: values.users__email,
        userName: values.sitenalysis_Username
      })
      .then(res => {
        if (res.status === 200) {
          toastr.success(
            'SUCCESS',
            'Please check your Email to reset your Password'
          );
          this.props.onHide();
        }
      });
    actions.setSubmitting(false);
  }

  closeLoginModal() {
    this.props.onHideLoginModal && this.props.onHideLoginModal();
  }
  render() {
    let { show, onHide } = this.props;
    return (
      <Modal
        id="forgot-password-modal"
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            FORGOT PASSWORD
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="container-fluid">
          <p className="">
            To retrieve your password enter your username and email address.
          </p>
          <Formik
            initialValues={{
              sitenalysis_Username: '',
              users__email: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={this.handleSubmit}
            render={({ errors, touched, isSubmitting }) => (
              <Form id="forgot-password__form">
                <div className="form-group">
                  <label>User Name</label>
                  <Field
                    type="text"
                    name="sitenalysis_Username"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="sitenalysis_Username"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <Field
                    type="text"
                    name="users__email"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="users__email"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group">
                  <Button
                    id="forgot-password__submit-btn"
                    type="submit"
                    className="btn btn-default"
                    disabled={isSubmitting}
                  >
                    SUBMIT
                  </Button>
                </div>
              </Form>
            )}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default ForgotPasswordModal;
