import { omni_axios_instance } from 'utils/axios';
import _ from 'lodash';
import {
  doFetchPostOfficeData,
  doFetchPostOfficeDataFullfilled,
  doFetchPostOfficeDataError
} from '../actions/postOffice';
import queryString from 'querystringify';
import omit from 'lodash/omit';

let pushState = qs => {
  // console.log('@qs =>', qs)
  // Push new search query by excluding default params
  window.appHistory.replace({
    search: qs
  });
};
// default remove unwanted fields
function _doCleanUp(atts) {
  return omit(atts, ['IS_PAGINATION_FLAG']);
}

export default function thunkFetchPostOfficeData(action) {
  return function(dispatch, getState) {
    dispatch(doFetchPostOfficeData(action));
    console.log(action, 'action');
    const state = getState();
    let { filters: oldAtts } = state.admin.postOffice;
    let newAtts = Object.assign(oldAtts, action);

    newAtts = _doCleanUp(newAtts);
    let flagValue = ['require_review', 'caution', 'unflagged'];
    if (_.includes(flagValue, newAtts.approvedStatus)) {
      newAtts['flag'] = newAtts.approvedStatus;
      delete newAtts['approvedStatus'];
    }
    let qs = queryString.stringify(newAtts);

    // Push new state
    pushState(qs);

    // console.log('qs', qs);
    return omni_axios_instance
      .get(`/getFiles?${qs}`)
      .then(response => dispatch(doFetchPostOfficeDataFullfilled(response)))
      .catch(error => dispatch(doFetchPostOfficeDataError(error.xhr)));
  };
}
