import React from 'react';
import { Link } from 'react-router-dom';
class Settings extends React.PureComponent {
  render() {
    return (
      <div className="po-settings-con">
        <Link to="/admin/post-office/settings">
          <i className="fa fa-cog" />
        </Link>
        <p>Settings</p>
      </div>
    );
  }
}
export default Settings;
