import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
import { history } from '../configureStore';
import Auth from 'utils/auth';
export default class BrandSelectionModal extends React.Component {
  state = { brands: [], brandId: null };
  saveSelection = () => {
    let { brandId, brands } = this.state;
    if (!brandId) {
      return;
    }
    let brand = brands.find(brand => brand._id == brandId);
    console.log('brand', brand);
    Auth.setAdminAsBrandUser(brand);
    history.push('/dashboard/summary');
  };
  onSelectBrand = e => {
    this.setState({ brandId: e.target.value });
  };
  render() {
    let { brands } = this.state;
    let { show, handleClose } = this.props;
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Brand</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select onChange={this.onSelectBrand}>
            <option value="">--Select Brand --</option>
            {brands.map(brand => (
              <option value={brand._id} key={brand._id}>
                {brand.name}
              </option>
            ))}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.saveSelection}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  getBrands() {
    omni_axios_instance.get('/getBrands').then(res => {
      // console.log('brands', res);
      this.setState({
        brands: res.data
      });
    });
  }
  componentDidMount() {
    this.getBrands();
  }
}
