export const CURRENT_VIEWING_PERIOD = "CURRENT_VIEWING_PERIOD";
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

// All retailers for current brand & period
export const FETCH_RETAILERS_REQUEST = "FETCH_RETAILERS_REQUEST";
export const FETCH_RETAILERS_SUCCESS = "FETCH_RETAILERS_SUCCESS";
export const FETCH_RETAILERS_FAILURE = "FETCH_RETAILERS_FAILURE";

// Single retailer
export const FETCH_RETAILER_REQUEST = "FETCH_RETAILER_REQUEST";
export const FETCH_RETAILER_SUCCESS = "FETCH_RETAILER_SUCCESS";
export const FETCH_RETAILER_FAILURE = "FETCH_RETAILER_FAILURE";

/*
 * get months for current brand
 */
export const FETCH_MONTHS_REQUEST = "FETCH_MONTHS_REQUEST";
export const FETCH_MONTHS_SUCCESS = "FETCH_MONTHS_SUCCESS";
export const FETCH_MONTHS_FAILURE = "FETCH_MONTHS_FAILURE";

/*
 * Single month
 */
export const FETCH_MONTH_REQUEST = "FETCH_MONTH_REQUEST";
export const FETCH_MONTH_SUCCESS = "FETCH_MONTH_SUCCESS";
export const FETCH_MONTH_FAILURE = "FETCH_MONTH_FAILURE";

/**
 * single product
 */
export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";

/**
 * Multiple products
 */
export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const FETCH_PRODUCTS_UPDATE = "FETCH_PRODUCTS_UPDATE";

/**
 * All retailers products
 */
export const FETCH_ALL_RET_PRODUCTS_REQUEST = "FETCH_ALL_RET_PRODUCTS_REQUEST";
export const FETCH_ALL_RET_PRODUCTS_SUCCESS = "FETCH_ALL_RET_PRODUCTS_SUCCESS";
export const FETCH_ALL_RET_PRODUCTS_FAILURE = "FETCH_ALL_RET_PRODUCTS_FAILURE";

/**
 * STATS API
 */
export const FETCH_STATS_REQUEST = "FETCH_STATS_REQUEST";
export const FETCH_STATS_SUCCESS = "FETCH_STATS_SUCCESS";
export const FETCH_STATS_FAILURE = "FETCH_STATS_FAILURE";

// common actions
export const SET_ACTIVE_PRODUCT = "SET_ACTIVE_PRODUCT";
export const SET_ACTIVE_MONTH = "SET_ACTIVE_MONTH";
export const SET_ACTIVE_RETAILER = "SET_ACTIVE_RETAILER";

export const SET_SEARCH = "SET_SEARCH";
export const INIT_SEARCH = "INIT_SEARCH";
export const REFRESH_EXPORT_HISTORY = "REFRESH_EXPORT_HISTORY";

// Refresh views
export const DONT_REFRESH = "DONT_REFRESH";
export const DO_REFRESH = "DO_REFRESH";

// Contacts List
export const FETCH_CONTACTINFO_REQUEST = "FETCH_CONTACTINFO_REQUEST";
export const FETCH_CONTACTINFO_SUCCESS = "FETCH_CONTACTINFO_SUCCESS";
export const FETCH_CONTACTINFO_FAILURE = "FETCH_CONTACTINFO_FAILURE";

// Retailer Scrapping Info
export const FETCH_RETAILER_SCRAPPING_INFO_REQUEST =
  "FETCH_RETAILER_SCRAPPING_INFO_REQUEST";
export const FETCH_RETAILER_SCRAPPING_INFO_SUCCESS =
  "FETCH_RETAILER_SCRAPPING_INFO_SUCCESS";
export const FETCH_RETAILER_SCRAPPING_INFO_FAILURE =
  "FETCH_RETAILER_SCRAPPING_INFO_FAILURE";

// Retailer Basket Export
export const ADD_BASKET_PRODUCT = "ADD_BASKET_PRODUCT";
export const REMOVE_BASKET_PRODUCT = "REMOVE_BASKET_PRODUCT";
export const CLEAR_BASKET = "CLEAR_BASKET";
export const CLEAR_ALL_BASKET = "CLEAR_ALL_BASKET";
