import React from 'react';
import { Modal, ControlLabel } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { omni_axios_instance } from 'utils/axios';
import { toastr } from 'react-redux-toastr';
import * as Yup from 'yup';

const UserSchema = Yup.object().shape({
  user_Name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('User Name Required'),
  user_Email: Yup.string()
    .email('Please enter valid email')
    .required('Email Required'),
  user_Password: Yup.string()
    .max(20, 'Too Long!')
    .required('Password Required'),
  user_Brand: Yup.string().required('Brand is required'),
  user_Role: Yup.string().required('Role is required')
});

class UserDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.show || false,
      brands: [],
      roles: []
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchBrands = this.fetchBrands.bind(this);
    this.fetchRoles = this.fetchRoles.bind(this);
  }
  componentDidMount() {
    this.fetchBrands();
    this.fetchRoles();
  }

  handleSubmit(values, actions) {
    let roles = [];
    roles.push(values.user_Role);
    let user_Data = {
      name: values.user_Name,
      password: values.user_Password,
      email: values.user_Email,
      brand: values.user_Brand,
      roles: roles
    };
    omni_axios_instance
      .post(`/users`, user_Data)
      .then(response => {
        toastr.success('SUCCESS', 'User added successfully.');
        this.onModalHide();
      })
      .catch(error => {
        // https://github.com/axios/axios#handling-errors
      });
  }
  fetchBrands() {
    omni_axios_instance.get(`/brands`).then(res => {
      this.setState({
        brands: res.data
      });
    });
  }
  fetchRoles() {
    omni_axios_instance.get(`/roles`).then(res => {
      this.setState({
        roles: res.data
      });
    });
  }

  onModalHide() {
    let { callback } = this.props;
    this.setState(
      {
        showModal: false
      },
      () => {
        callback && callback();
      }
    );
  }
  render() {
    let { showModal, brands, roles } = this.state;

    return (
      <div className="modal-container">
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="UserPopUpModal"
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          <Modal.Header closeButton className="modal-header-con">
            <Modal.Title>User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                user_Name: '',
                user_Password: '',
                user_Email: '',
                user_Brand: '',
                user_Role: ''
              }}
              validationSchema={UserSchema}
              onSubmit={(values, actions) => {
                this.handleSubmit(values, actions);
              }}
              render={({ errors, touched, isSubmitting }) => (
                <Form id="user-form">
                  <div className="form-group">
                    <ControlLabel>Name</ControlLabel>
                    <Field
                      type="text"
                      name="user_Name"
                      className="form-control"
                      placeholder="Enter Name"
                    />
                    <ErrorMessage
                      name="user_Name"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <ControlLabel>Email</ControlLabel>
                    <Field
                      type="email"
                      name="user_Email"
                      className="form-control"
                      placeholder="Enter Email"
                    />
                    <ErrorMessage
                      name="user_Email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <ControlLabel>Password</ControlLabel>
                    <Field
                      type="password"
                      name="user_Password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <ErrorMessage
                      name="user_Password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <ControlLabel>Brand</ControlLabel>
                    <Field
                      name="user_Brand"
                      component="select"
                      className="form-control"
                    >
                      <option value="">Select Brand</option>
                      {brands.map((option, key) => {
                        return (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="user_Brand"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <ControlLabel>Role</ControlLabel>
                    <Field
                      name="user_Role"
                      component="select"
                      className="form-control"
                    >
                      <option value="">Select Role</option>
                      {roles.map((option, key) => {
                        return (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="user_Role"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-primary">
                      Add User
                    </button>
                  </div>
                </Form>
              )}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default UserDetailsModal;
