import React from 'react';
import './inbox.scss';
import { Grid } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Messages from './components/Messages';

const Inbox = () => {
  return (
    <div className="page" id="inbox">
      <Grid fluid>
        <Messages />
      </Grid>
    </div>
  );
};

export default Inbox;
