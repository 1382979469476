import React from 'react';
import Banner from './Banner';
import ContactForm from './ContactForm';
import ImageLoader from 'dashboard/components/ImageLoader';
import queryString from 'querystringify';
import './home.scss';

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.aboutElRef = React.createRef();
    this.demoElRef = React.createRef();
    this.scrollTo = this.scrollTo.bind(this);
  }

  componentDidUpdate() {
    let { location } = this.props;
    this.scrollTo(location.search);
  }

  componentDidMount() {
    let { location } = this.props;
    setTimeout(() => {
      this.scrollTo(location.search);
    }, 700);
  }

  scrollTo(search = '') {
    if (!search) {
      return;
    }
    let sQuery = queryString.parse(search);
    if (sQuery.to === 'about') {
      window.scrollTo(0, this.aboutElRef.current.offsetTop + 100);
    }
    if (sQuery.to === 'demo') {
      window.scrollTo(0, this.demoElRef.current.offsetTop + 100);
    }
  }

  render() {
    return (
      <div id="home">
        <Banner />
        <div id="about-sitenalysis" ref={this.aboutElRef}>
          <h3 className="home__heading">WHY SITENALYSIS ?</h3>
          <section>
            <div className="row sitenalysis-reasons">
              <div className="col-xs-12 col-sm-6">
                <ImageLoader
                  image={`${process.env.PUBLIC_URL}/website-images/D1.jpg`}
                  openInNewTab={false}
                  zoomable={false}
                  alt="brand logo"
                />
              </div>
              <div className="col-xs-12 col-sm-6">
                <p className="text-wrapped adj-text-style">
                  Dozens to hundreds of these digital billboards directly
                  influence how customers perceive you as they’re where over 70%
                  of customers start, continue or end their product journey
                  today. As a consequence, regularly checking each website to
                  ensure that your Brand and products are properly presented has
                  become a critical brand management task.
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className="row sitenalysis-reasons">
              <div className="hidden-xs col-sm-6">
                <p className="text-wrapped">
                  It can take up to three hours a month - for each of your
                  Retailers’ websites - to do a careful and thorough review of
                  your Brand’s landing and individual catalog product pages.
                  From rights usage to incorrect pricing and assortments, one
                  “bad apple” can have serious legal and business implications.
                </p>
              </div>
              <div className="col-xs-12 col-sm-6">
                <ImageLoader
                  image={`${process.env.PUBLIC_URL}/website-images/D2.jpg`}
                  openInNewTab={false}
                  zoomable={false}
                  alt="brand logo"
                />
              </div>
              <div className="col-xs-12 hidden-sm hidden-md hidden-lg">
                <p className="text-wrapped">
                  It can take up to three hours a month - for each of your
                  Retailers’ websites - to do a careful and thorough review of
                  your Brand’s landing and individual catalog product pages.
                  From rights usage to incorrect pricing and assortments, one
                  “bad apple” can have serious legal and business implications.
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className="row sitenalysis-reasons">
              <div className="col-xs-12 col-sm-6">
                <ImageLoader
                  image={`${process.env.PUBLIC_URL}/website-images/D3.jpg`}
                  openInNewTab={false}
                  zoomable={false}
                  alt="brand logo"
                />
              </div>
              <div className="col-xs-12 col-sm-6">
                <p className="text-wrapped">
                  Your team needs a more efficient and cost-effective way to
                  monitor, audit and protect your Brand across all of your
                  Retailers’ websites. {/* SiteNalysis is your solution! */}
                </p>
                <h3 className="sitenalysis-reasons-color-text">
                  SiteNalysis is your solution!
                </h3>
              </div>
            </div>
          </section>
        </div>
        <div id="schedule-demo" ref={this.demoElRef}>
          <ContactForm />
        </div>
      </div>
    );
  }
}

export default Home;
