import React, { useState } from "react";
import { Grid, Modal } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { omni_axios_instance } from "./../../utils/axios";
import { bindActionCreators } from "redux";
import { addBasketProduct } from "../actions/retailerBasket";
import { connect } from "react-redux";

// export default function BasketBulkImportModal(props) {
class BasketBulkImportModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: "",
      unAddedItems: [],
      showAlert: false,
    };
    this.handleImport = this.handleImport.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
  }

  async fetchItems(serialNo, retailerID) {
    console.log({ serialNo, retailerID });
    let data;
    await omni_axios_instance
      .get(
        `https://api.pricescraper.net/getBatchItems?retailerId=${retailerID}&serialNo=${serialNo}`
      )
      .then((response) => {
        if (response.data.data) {
          // console.log(response);
          // toastr.success("SUCCESS", "File uploaded successfully.");
          // // this.setState({
          // //   fileHeaders: response.data.data,
          // //   count: response.data.count
          // // });
          // // this.handleSelect('match-data');
          // // this.handleClose();
          data = response.data.data;
        } else {
          alert("item not found");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  }

  async handleImport() {
    let { retailer, retailerBasket } = this.props;
    let bulkItems = document.querySelector("#bulk_product_values").value;
    // console.log("bulkItems :", bulkItems);
    // console.log("bulkItems type :", typeof bulkItems);
    if (bulkItems === "") {
      toastr.error("Error", "Add atleast one product.");
      return;
    } else {
      bulkItems = bulkItems.replace(/\r\n/g, "\n").split("\n");
      // console.log("bulkItems :", bulkItems);
      if (bulkItems[bulkItems.length - 1] === "") {
        bulkItems.pop();
      }
      // this.setState((prevState) => ({
      //   items: [...prevState.items, ...bulkItems],
      // }));
      // console.log("this.state.items :", this.state.items);
    }

    let productArray = [];
    let remainingItems = [];

    let currItems = bulkItems;

    for (let i = 0; i < currItems.length; i++) {
      //   let data;
      let data = await this.fetchItems(currItems[i], retailer.optId);
      // console.log("type of data: ", typeof data);
      // console.log("data: ", data);
      if (data && data.length !== 0 && data[0].customerId === retailer.optId) {
        // console.log("data: ", data);
        let index = retailerBasket.findIndex(
          (item) =>
            item.customerId === retailer.optId && item.serialNo === currItems[i]
        );
        if (index !== -1) {
          toastr.success("Already Added!");
        } else {
          this.props.addBasketProduct(retailer.optId, data[0]);
          toastr.success("Product Added!");
        }
        productArray.push(data[0]);
      } else {
        // remainingItems.push(currItems);

        remainingItems.push(
          data && data.length !== 0
            ? `SerialNo: ${data[0].serialNo} | Retailer ID: ${data[0].customerId} | Retailer Name: ${data[0].customerName}`
            : `SerialNo: ${currItems[i]} | Invalid Serial No.`
        );
      }
    }

    this.setState({ unAddedItems: remainingItems });

    productArray = [];

    if (remainingItems.length !== 0 && remainingItems[0])
      this.setState({ showAlert: true });
  }

  render() {
    let { hide, show } = this.props;
    return (
      <div className="modal-container">
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="FileUploaModal"
          show={show}
          onHide={() => {
            hide(false);
            this.setState({
              items: "",
              unAddedItems: [],
              showAlert: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className>Bulk Items Import</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="control-label">Enter Serials:</label>
              <textarea
                id="bulk_product_values"
                className="form-control"
                // onChange={this.getValue.bind(this)}
                // onChange={this.handleInputChange}
                // value={this.state.items}
              />
              <button
                className="btn btn-default Retailer-ContactForm__footer__rightcol-btn"
                onClick={this.handleImport}
              >
                Import
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="FileUploaModal"
          show={this.state.showAlert}
          onHide={() => {
            this.setState({ showAlert: false });
            this.setState({
              unAddedItems: [],
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className>Items Not Imported</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.state.unAddedItems.map((item, i) => {
                return <label key={i}>{item}</label>;
              })}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log({ state });
  return {
    products: state.products.items,
    isLoading: state.products.isLoading,
    page: state.products.filters.page,
    totalCount: state.products.totalProducts,
    currentCount: state.products.currentProducts,
    retailerScrappingInfoData: state.retailerScrappingInfoData,
    retailer: state.retailer,
    retailerBasket: state.retailerBasket.items.filter(
      (item) => item.customerId === state.retailer.optId
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addBasketProduct,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasketBulkImportModal);
