import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { omni_axios_instance } from "./../../utils/axios";

export default function EditRetailerPopUp(props) {
  console.log("retailer props: ", props);
  let { show, hide, retailer, getRetailers } = props;
  const [state, setState] = useState({
    name: "",
    optId: "",
    slug: "",
    website: "",
  });

  const handleRetailerReset = () => {
    setState({
      name: "",
      optId: "",
      slug: "",
      website: "",
    });
  };

  const setRetailerDetails = () => {
    setState({
      ...state,
      name: retailer.name ? retailer.name : "",
      optId: retailer.optId ? retailer.optId : "",
      slug: retailer.slug ? retailer.slug : "",
      website: retailer.website ? retailer.website : "",
    });
  };

  const saveRetailer = async () => {
    let id = retailer && retailer._id ? retailer._id : "";
    let payload = state;
    if (id) {
      await omni_axios_instance
        .put(`https://api.pricescraper.net/retailers/${id}`, payload)
        .then((response) => {
          console.log("response:", response);
        })
        .catch((error) => console.log(error));
    } else {
      await omni_axios_instance
        .post(`https://api.pricescraper.net/retailers/`, payload)
        .then((response) => {
          console.log("response:", response);
        })
        .catch((error) => console.log(error));
    }
    getRetailers();
    hide();
  };

  useEffect(() => {
    if (retailer) {
      setRetailerDetails();
    }
  }, [retailer]);

  return (
    <div className="modal-container">
      <Modal
        animation={false}
        autoFocus={false}
        enforceFocus={false}
        className="EditRetailerPopUpModal"
        show={show}
        onHide={() => hide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{retailer.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="retailer_edit">
            <div className="retailer_input">
              <label> Name:</label>
              <input
                className="form-control"
                type="text"
                placeholder="Name..."
                value={state.name}
                onChange={(e) => {
                  setState({ ...state, name: e.target.value });
                }}
              />
            </div>
            <div className="retailer_input">
              <label> Opt ID:</label>
              <input
                className="form-control"
                type="text"
                placeholder="Opt ID..."
                value={state.optId}
                onChange={(e) => {
                  setState({ ...state, optId: e.target.value });
                }}
              />
            </div>
            <div className="retailer_input">
              <label>Slug:</label>
              <input
                className="form-control"
                type="text"
                placeholder="Slug..."
                value={state.slug}
                onChange={(e) => {
                  setState({ ...state, slug: e.target.value });
                }}
              />
            </div>
            <div className="retailer_input">
              <label>Website:</label>
              <input
                className="form-control"
                type="text"
                placeholder="Website..."
                value={state.website}
                onChange={(e) => {
                  setState({ ...state, website: e.target.value });
                }}
              />
            </div>
            <div className="retailer_input">
              <button onClick={() => handleRetailerReset()}>Clear</button>
              <button onClick={() => saveRetailer()}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
