import { omni_axios_instance } from 'utils/axios';

import {
  doFetchProduct,
  doFetchProductFullfilled,
  doFetchProductError
} from 'dashboard/actions/product';

export default function thunkFetchProduct(action) {
  return function(dispatch, getState) {
    dispatch(doFetchProduct(action));
    return omni_axios_instance
      .get(`/retailerBrandProductScrapings/${action}`)
      .then(response => {
        dispatch(doFetchProductFullfilled(response));
      })
      .catch(error => dispatch(doFetchProductError(error.xhr)));
  };
}
