import React from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import Button from 'components/CustomButton/CustomButton.jsx';
import { reduxForm } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import Helpers from 'utils/helpers';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

const brandLogoOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Logo is missing', label: 'Logo is missing' },
  { value: 'Old logo is shown', label: 'Old logo is shown' },
  {
    value: 'There is different issue with brand logo',
    label: 'There is different issue with brand logo'
  }
];

const brandMarketingVisualsOptions = [
  { value: '', label: 'Select an option' },
  {
    value: 'Brand marketing visuals are missing from the website',
    label: 'Brand marketing visuals are missing from the website'
  },
  {
    value:
      'There are usage rights with this campaign and they must be updated immediately',
    label:
      'There are usage rights with this campaign and they must be updated immediately'
  },
  {
    value:
      'There is an old campaign ( no usage rights issues ) and updates are recommended',
    label:
      'There is an old campaign ( no usage rights issues ) and updates are recommended'
  }
];

const brandBioOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Brand bio is missing', label: 'Brand bio is missing' },
  {
    value: 'Brand bio has typo and/or requires updates',
    label: 'Brand bio has typo and/or requires updates'
  },
  {
    value: 'There is different issue with our brand bio',
    label: 'There is different issue with our brand bio'
  }
];

class FlagBrandForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isBrandLogo: false,
      isBrandBio: false,
      isBrandBioErr: false,
      isBrandMarketingVisuals: false,
      image_issue: false,
      brand_logo: null,
      brand_Bio: null,
      brand_marketing_visuals_campaign: null,
      other: false,
      message: '',
      selectedOption: []
    };

    this.isCheckedOptions = this.isCheckedOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (selectedOption, stateName) => {
    selectedOption.key = stateName.name;
    this.setState({ [stateName.name]: selectedOption });
  };

  isCheckedOptions(e) {
    let value =
      e.target.type === 'checkbox'
        ? !this.state[e.target.name]
        : e.target.value;
    this.setState({
      [e.target.name]: value
    });
  }

  getReasonsToFlagBrand(values) {
    let reasonsToFlag = [];
    _.forEach(values, (item, key) => {
      if ((item && !_.isEmpty(item.value)) || item === true) {
        if (key === 'other') {
          reasonsToFlag.push({ name: 'Other', value: '' });
        } else if (key === 'brand_marketing_visuals_campaign') {
          reasonsToFlag.push({
            name: 'Brand Marketing Visual/Campaign',
            value: item.value
          });
        } else if (key === 'image_issue') {
          reasonsToFlag.push({
            name: 'Image Issues',
            value:
              'To report incorrect, broken or other issues relating to the item image, select this option.'
          });
        } else {
          let temp = key.split('_');
          let selectedItems = temp.map((item, index) => {
            return (
              item
                .toString()
                .charAt(0)
                .toUpperCase() + item.slice(1)
            );
          });
          reasonsToFlag.push({
            name: selectedItems.join(' '),
            value: item.value
          });
        }
      }
    });
    return reasonsToFlag;
  }

  handleSubmit(e) {
    e.preventDefault();
    let { retailerId } = this.props;
    let {
      brand_logo,
      brand_Bio,
      brand_marketing_visuals_campaign,
      message,
      image_issue,
      other
    } = this.state;

    let selectedOption = {
      brand_logo,
      brand_Bio,
      brand_marketing_visuals_campaign,
      image_issue,
      other
    };

    let messageValue = this.getReasonsToFlagBrand(selectedOption);
    // Api call here...
    if ((brand_Bio && _.isEmpty(brand_Bio.value)) || _.isEmpty(messageValue)) {
      this.setState({ isBrandBioErr: true });
      toastr.error('ERROR', 'Please select at least one field.');
      return;
    }

    let formData = {
      message: message,
      reason: messageValue,
      isFlagged: 'yes'
    };
    // Flag brand
    Helpers.setBrandFlag(formData, retailerId).then(() => {
      // Hide modal
      // pass additional params to parent
      this.props.callback({
        isFlagged: true,
        formData,
        retailerId
      });
      // this.props.doFetchProduct(retailerBrandScrappingid);
    });
  }

  render() {
    // const { handleSubmit } = this.props;
    let {
      isBrandLogo,
      isBrandBio,
      brand_logo,
      brand_Bio,
      brand_marketing_visuals_campaign,
      isBrandMarketingVisuals,
      image_issue,
      other,
      isBrandBioErr
    } = this.state;

    return (
      <form id="flagBrandProfileForm" onSubmit={this.handleSubmit}>
        <FormGroup>
          <div className="field_wrapper">
            <input
              id="flagBrandProfileForm__brandLogo"
              type="checkbox"
              name="isBrandLogo"
              onChange={this.isCheckedOptions}
              checked={isBrandLogo}
            />
            <label htmlFor="flagBrandProfileForm__brandLogo">Brand Logo</label>
            <span data-tip="Examples - The brand bio is incomplete and/or requires changes">
              {/* <i className="fa fa-info-circle" aria-hidden="true" /> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>
          {/* 		<Select value={selectedOption} onChange={this.handleChange} options={options} /> */}

          {isBrandLogo && (
            <div className="flagBrandProfileForm__dropdown">
              <Select
                name="brand_logo"
                value={brand_logo}
                onChange={this.handleChange}
                options={brandLogoOptions}
                onChangeArguments
                required="false"
                placeholder="Select an option"
              />
            </div>
          )}

          <div className="field_wrapper">
            <input
              type="checkbox"
              id="flagBrandProfileForm__brandBio"
              name="isBrandBio"
              onChange={this.isCheckedOptions}
              checked={isBrandBio}
            />
            <label htmlFor="flagBrandProfileForm__brandBio">Brand Bio</label>
            <span data-tip="Examples - The brand bio is incomplete and/or requires changes">
              {/* <i className="fa fa-info-circle" aria-hidden="true" /> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>

          {isBrandBio && (
            <div className="flagBrandProfileForm__dropdown">
              <Select
                name="brand_Bio"
                value={brand_Bio}
                onChange={this.handleChange}
                options={brandBioOptions}
                onChangeArguments
                required
                placeholder="Select an option"
              />
              {isBrandBioErr && (
                <span className="error-msg">This is required field</span>
              )}
            </div>
          )}

          <div className="field_wrapper">
            <input
              type="checkbox"
              id="flagBrandProfileForm__brandMarkrtting"
              name="isBrandMarketingVisuals"
              onChange={this.isCheckedOptions}
              checked={isBrandMarketingVisuals}
            />
            <label htmlFor="flagBrandProfileForm__brandMarkrtting">
              Brand Marketing Visuals/Campaign
            </label>
            <span data-tip="Examples - Brand Visuals are missing, there are usage rights issues with the campaign or old visuals are being shown ">
              {/* <i className="fa fa-info-circle" aria-hidden="true" /> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>

          {isBrandMarketingVisuals && (
            <div className="flagBrandProfileForm__dropdown">
              <Select
                name="brand_marketing_visuals_campaign"
                value={brand_marketing_visuals_campaign}
                onChange={this.handleChange}
                options={brandMarketingVisualsOptions}
                onChangeArguments
                placeholder="Select an option"
              />
            </div>
          )}
          <div className="field_wrapper">
            <input
              name="image_issue"
              type="checkbox"
              onChange={this.isCheckedOptions}
              checked={image_issue}
            />
            <label className="label-text">Image Issues</label>
            <span data-tip="To report incorrect, broken or other issues relating to the item image, select this option.">
              {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>
          <div className="field_wrapper other-field">
            <input
              label="Other"
              name="other"
              type="checkbox"
              id="flagBrandProfileForm__Other"
              onChange={this.isCheckedOptions}
              checked={other}
            />

            <label className="other-option"> Other </label>
            <span data-tip="Other">
              {/* <i className="fa fa-info-circle" aria-hidden="true" /> */}
              <img
                className="img-icon"
                src={`${process.env.PUBLIC_URL}/info-icon.png`}
                alt="info-icon"
              />
            </span>
          </div>

          <div className=" comment-col">
            <ControlLabel className="col-sm-12">
              Please include additional details
            </ControlLabel>
            <textarea
              className="form-control"
              defaultValue={this.state.message}
              name="message"
              rows="4"
              cols="50"
              onChange={this.isCheckedOptions}
            />
          </div>
          <ReactTooltip
            className="omni__tooltip omni__tooltip--right"
            place="right"
            type="light"
            effect="solid"
          />
        </FormGroup>
        <div className="text-right">
          <Button
            id="flagBrandProfileForm__btn"
            className="btn btn-primary"
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    );
  }
}

export default (FlagBrandForm = reduxForm({
  form: 'flagBrandPrfileForm'
})(FlagBrandForm));
