import React from "react";
import UserRetailerList from "admin/user-retailer-association/UserRetailerList";
import RetailerList from "./RetailerList";
import { omni_axios_instance } from "utils/axios";
import "./user-retailer-association.scss";

export class UserRetailerAssociation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userList: {},
      brands: [],
    };
    this.getBrands = this.getBrands.bind(this);
  }
  componentDidMount() {
    this.getBrands();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps, "nextProps");
  }

  getBrands() {
    omni_axios_instance.get("/brands").then((res) => {
      console.log("brands", res);
      this.setState({
        brands: res.data,
      });
    });
  }

  render() {
    let { brands } = this.state;
    return (
      <div className="user-retailer-association">
        <UserRetailerList brands={brands} getBrands={this.getBrands} />
      </div>
    );
  }
}
export class RetailerListAssociation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userList: {},
      retailers: [],
    };
    this.getRetailers = this.getRetailers.bind(this);
  }
  componentDidMount() {
    this.getRetailers();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps, "nextProps");
  }

  async getRetailers() {
    await omni_axios_instance.get("/retailers").then((res) => {
      console.log("retailers", res);
      this.setState({
        retailers: res.data,
      });
    });
  }

  render() {
    let { retailers } = this.state;
    return (
      <div className="user-retailer-association">
        <RetailerList retailers={retailers} getRetailers={this.getRetailers} />
      </div>
    );
  }
}

// export default UserRetailerAssociation;
