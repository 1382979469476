import React from 'react';
import { Modal } from 'react-bootstrap';
import LoginFormComponent from './LoginFormComponent';

const LoginModal = props => {
  return (
    <Modal
      id="login-modal"
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <LoginFormComponent
          showForgotPasswordModal_callback={
            props.showForgotPasswordModal_callback
          }
          onModalHide={props.onHide}
        />
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
