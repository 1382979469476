import React from 'react';
import _ from 'lodash';
import NotificationDetailsModal from './NotificationDetailsModal';
import TimeAgo from 'react-timeago';
import NotificationPagination from './Pagination';
import classNames from 'classnames';
import { omni_axios_instance } from 'utils/axios';
import doFetchNotificationData from 'admin/thunks/thunkNotificationData';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';

const FileListLoader = props => (
  <ContentLoader
    height={200}
    width={500}
    speed={2}
    primaryColor="#ded7d759"
    secondaryColor="#efefef"
    {...props}
  >
    <rect x="250" y="15" rx="0" ry="0" width="500" height="15" />
    <rect x="0" y="45" rx="0" ry="0" width="500" height="15" />
    <rect x="0" y="65" rx="0" ry="0" width="500" height="15" />
    <rect x="0" y="85" rx="0" ry="0" width="500" height="15" />
    <rect x="0" y="105" rx="0" ry="0" width="500" height="15" />
    <rect x="250" y="140" rx="0" ry="0" width="500" height="15" />
  </ContentLoader>
);

class NotificationList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      notification: []
    };
    this.showPopUpModal = this.showPopUpModal.bind(this);
    this.hidePopUpModal = this.hidePopUpModal.bind(this);
  }

  hidePopUpModal() {
    this.setState({
      showModal: false
    });
  }
  seenNotification(id) {
    omni_axios_instance
      .put(`/notifications/${id}`, { seenFlag: true })
      .then(res => {
        if (res.status === 200) {
          this.props.doFetchNotificationData();
        }
      });
  }

  showPopUpModal(e, item) {
    this.setState(
      {
        showModal: true,
        notification: item
      },
      () => {
        if (item.seenFlag === false) {
          this.seenNotification(item._id);
        }
      }
    );
  }
  render() {
    let { showModal, notification } = this.state;
    let { notifications } = this.props;
    if (_.isEmpty(notifications)) {
      return (
        <p>
          <FileListLoader />
        </p>
      );
    }

    const isSeen = flag =>
      classNames({
        notificationListItem: true,
        isSeen: !flag && true
      });
    return (
      <div className="notification-list container">
        <div className="notification-list-con">
          <NotificationPagination />
          {notifications.map((item, index) => {
            return (
              <div
                key={index}
                onClick={e => this.showPopUpModal(e, item)}
                className={isSeen(item.seenFlag)}
              >
                <div className="notification-subject">
                  <p>{item.subject.split(':')[1]}</p>
                  <p>
                    <b>{item.retailer}</b>
                  </p>
                </div>
                <span className="notification-subject_created">
                  <TimeAgo className="time_created" date={item.createdAt} />
                </span>
              </div>
            );
          })}
          <NotificationPagination />
        </div>
        {showModal && (
          <NotificationDetailsModal
            show={showModal}
            callback={this.hidePopUpModal}
            notification={notification}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doFetchNotificationData: value => {
      dispatch(doFetchNotificationData({ atts: value }));
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(NotificationList)
);
