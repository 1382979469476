import React from 'react';
import { ControlLabel } from 'react-bootstrap';
import { isIE } from 'react-device-detect';
class Filter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  onFilterChange(e) {
    let filter = e.target.value;
    this.props.selectedFilter(filter);
  }

  render() {
    return (
      <div className="filter-by-select-container">
        <ControlLabel className="title-col">Filter By:</ControlLabel>
        <div className="select-col">
          <select
            name="filter-types"
            id="filter-type-select"
            className={isIE ? 'filter-ie' : 'filter-default'}
            onChange={this.onFilterChange}
          >
            <option value="-1">All Retailer Websites </option>
            <option value="retailerWithProducts">
              Retailer Websites with product pages
            </option>
            <option value="retailerWithoutProducts">
              Retailer Websites without product pages
            </option>
            <option value="retailerWithModule">
              Retailer Websites that use module
            </option>
          </select>
        </div>
      </div>
    );
  }
}

export default Filter;
