import React from "react";
import { Grid, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Auth from "utils/auth";
import { omni_axios_instance } from "utils/axios";
import thunkFetchStats from "dashboard/thunks/thunkFetchStats";
import OmniStatsCard from "dashboard/components/Card/OmniStatsCard.jsx";
import Helpers from "utils/helpers";
import { retailerThArray } from "variables/Variables.jsx";
import "./dashboard.scss";
import Card from "dashboard/components/Card/Card.jsx";
import moment from "moment";
import _ from "lodash";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";
import classNames from "classnames";
import Filter from "./components/Filter/Filter";
import FilterForReps from "./components/Filter/FilterForReps";
import ReactTooltip from "react-tooltip";
import Truncate from "react-truncate";

class SummaryView extends React.PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      retailers: [],
      retailerDataFilter: [],
      sortKey: "name",
      sortOrder: "asc",
      isLoading: false,
      associatedRetailers: [],
      mainList: [],
      users: [],
    };
    this.selectedFilter = this.selectedFilter.bind(this);
    this.selectedFilterForRep = this.selectedFilterForRep.bind(this);
    this.filterOptionsData = this.filterOptionsData.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.selectedRetailerList = this.selectedRetailerList.bind(this);
    this.assignedPersColumn = this.assignedPersColumn.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    Helpers.scrollToTop();
  }

  selectedRetailerList = (
    retailerDataFilter,
    associatedRetailers,
    mainUsers
  ) => {
    let mainSrappingData = retailerDataFilter;
    let users = [];
    forEach(retailerDataFilter, (retailers, i) => {
      forEach(associatedRetailers, (item, key) => {
        if (retailers.retailer._id === item.retailer._id) {
          retailers.users = item.users;
        }
      });
    });
    this.setState({
      retailers: retailerDataFilter,
      retailerDataFilter: retailerDataFilter,
      isLoading: false,
      associatedRetailers: associatedRetailers,
      mainList: mainSrappingData,
      users: mainUsers,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    let user = Auth.getUser();
    let brandId = user.brand._id;
    let userId = user._id;
    this.setState({
      isLoading: true,
    });

    Promise.all([
      omni_axios_instance.get(`/RetailerScraping/${brandId}?user=${userId}`),
      omni_axios_instance.get(`/getAssignedReps?brand=${brandId}`),
      omni_axios_instance.get(`/users?brand=${brandId}`),
    ])
      .then(([res1, res2, res3]) => {
        if (!isEmpty(res1) && !isEmpty(res2) && !isEmpty(res3)) {
          let getSortedData = _.sortBy(res1.data, [
            function (o) {
              return o.retailer.name;
            },
          ]);
          if (this._isMounted) {
            this.selectedRetailerList(getSortedData, res2.data, res3.data.data);
          }
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });

    this.props.doFetchStats();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  selectedFilterForRep(val) {
    let { retailers, sortOrder, sortKey } = this.state;
    let filterData = filter(retailers, (o) => {
      if (val !== "-1") {
        for (var i = 0; i < o.users.length; i++) {
          if (o.users[i] === val) {
            return o;
          }
        }
      } else {
        return o;
      }
    });
    let sortedData = orderBy(filterData, [sortKey], [sortOrder]);

    this.setState({
      retailerDataFilter: sortedData,
    });
  }

  selectedFilter(filter) {
    let { retailers, sortOrder, sortKey } = this.state;
    let filterData = _.filter(retailers, (o) => {
      return filter !== "-1"
        ? o.brand_retailer && o.brand_retailer.webSiteType === filter
        : o;
    });

    let sortedData = _.orderBy(filterData, [sortKey], [sortOrder]);

    this.setState({
      retailerDataFilter: sortedData,
    });
  }

  handleSort(e, newSortKey) {
    let { retailerDataFilter, sortOrder } = this.state;
    let newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    let sortedData = _.orderBy(
      retailerDataFilter,
      [newSortKey],
      [newSortOrder]
    );

    this.setState({
      retailerDataFilter: sortedData,
      sortKey: newSortKey,
      sortOrder: newSortOrder,
    });
  }

  createOmniStats() {
    let { stats } = this.props;
    return Object.keys(stats).map((prop, i) => {
      return (
        <Col lg={3} sm={6} key={i}>
          <OmniStatsCard stats={stats} statsProp={prop} />
        </Col>
      );
    });
  }
  filterOptionsData(item) {
    let content = "";
    if (isEmpty(item.brand_retailer)) {
      return content;
    }
    let { webSiteType } = item.brand_retailer;
    if (webSiteType === "retailerWithoutProducts") {
      content = Helpers.checkRetailerModule({
        isModule: false,
        imgType: "light",
      });
    } else if (
      webSiteType === "retailerWithModule" ||
      webSiteType === "retailerWithProductsandModule"
    ) {
      content = Helpers.checkRetailerModule({
        isModule: true,
        brandName: item.brand_name,
        imgType: "light",
        abcWithProduct: webSiteType === "retailerWithProductsandModule",
      });
    } else {
      content = "";
    }
    return content;
  }

  getContentLoader(role) {
    let { users } = this.state;
    /*let user = Auth.getUser();
    let role = '';
    if(!isEmpty(user.roles)) {
      role = user.roles[0].name;
    }*/
    /*let reactContentLoader = (
      <ContentLoader
        height={440}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="12" />
        <rect x="0" y="20" rx="0" ry="0" width="100%" height="12" />
        <rect x="0" y="40" rx="0" ry="0" width="100%" height="12" />
        <rect x="0" y="60" rx="0" ry="0" width="100%" height="12" />
      </ContentLoader>
    );*/

    return (
      <div>
        <Row>
          <Col lg={12} sm={12}>
            <Card
              title={"Retailers"}
              text={
                <div className="summary-header">
                  <div className="summary-info">
                    (Click Retailer name for more details)
                  </div>
                  <div className="summary-filter">
                    {(role === "BRAND_ADMIN" || role === "ADMIN") && (
                      <FilterForReps
                        selectedFilter={this.selectedFilterForRep}
                        users={users}
                      />
                    )}
                    <Filter selectedFilter={this.selectedFilter} />
                  </div>
                </div>
              }
              tableFullWidth
              content={
                <Table responsive>
                  <thead>
                    <tr>
                      {retailerThArray.map((prop, key) => {
                        if (role === "ADMIN" || role === "BRAND_ADMIN") {
                          return (
                            <th className={`heading-${key}`} key={`th-${key}`}>
                              {prop.name}
                              {prop.name !== "Assigned reps" && (
                                <i className="fa fa-sort" />
                              )}
                            </th>
                          );
                        }
                        if (role == "User" && prop.name !== "Assigned reps") {
                          return (
                            <th className={`heading-${key}`} key={`th-${key}`}>
                              {prop.name}
                              {prop.name !== "Assigned reps" && (
                                <i className="fa fa-sort" />
                              )}
                            </th>
                          );
                        }
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {role === "BRAND_ADMIN" || role === "ADMIN" ? (
                        <td
                          className="text-center"
                          colSpan={5}
                          style={{
                            padding: "20% 0",
                          }}
                        >
                          <span>Loading list, please wait...</span>
                          <br />
                          <br />
                          <i className="fa fa-spin fa-spinner fa-2x fa-fw" />
                        </td>
                      ) : (
                        <td
                          className="text-center"
                          colSpan={4}
                          style={{
                            padding: "20% 0",
                          }}
                        >
                          <span>Loading list, please wait...</span>
                          <br />
                          <br />
                          <i className="fa fa-spin fa-spinner fa-2x fa-fw" />
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
  assignedPersColumn(prop, role) {
    if (role === "BRAND_ADMIN" || role === "ADMIN") {
      return (
        <Truncate
          lines={1}
          width={150}
          ellipsis={
            <span className="ellipsis-con">
              ...
              <span
                className="view-all__label_color"
                data-tip={prop.users && prop.users.sort().join(", ")}
              >
                view all
              </span>
              <ReactTooltip
                className="omni__tooltip omni__tooltip--right"
                place="right"
                type="light"
                effect="solid"
              />
            </span>
          }
        >
          <span className="retailerText">
            {prop.users && prop.users.sort().join(", ")}
          </span>
        </Truncate>
      );
    }
  }
  render() {
    let { isLoading, sortKey, sortOrder, users } = this.state;
    let user = Auth.getUser();
    let role = "";
    if (!isEmpty(user.roles)) {
      role = user.roles[0].name;
    }
    let htmlContent = "";
    if (isLoading) {
      htmlContent = this.getContentLoader(role);
    } else {
      let { retailerDataFilter } = this.state;
      let timeZone = new Date().getTimezoneOffset();
      htmlContent = (
        <div>
          <Row>
            <Col lg={12} sm={12}>
              <Card
                title={"Retailers"}
                text={
                  <div className="summary-header">
                    <div className="summary-info">
                      (Click Retailer name for more details)
                    </div>
                    <div className="summary-filter">
                      {(role === "BRAND_ADMIN" || role === "ADMIN") && (
                        <FilterForReps
                          selectedFilter={this.selectedFilterForRep}
                          users={users}
                        />
                      )}
                      <Filter selectedFilter={this.selectedFilter} />
                    </div>
                  </div>
                }
                tableFullWidth
                content={
                  <Table striped hover responsive className="retailer-list">
                    <thead>
                      <tr>
                        {retailerThArray.map((prop, key) => {
                          let newThClasses = classNames({
                            fa: true,
                            "fa-sort": true,
                            "align-self-end fa-sort-up":
                              prop.value === sortKey && sortOrder === "asc",
                            "align-self-baseline fa-sort-down":
                              prop.value === sortKey && sortOrder === "desc",
                          });
                          if (role === "ADMIN" || role === "BRAND_ADMIN") {
                            return (
                              <th
                                className={`heading-${key}`}
                                key={`th-${key}`}
                                onClick={(e) => {
                                  this.handleSort(e, prop.value);
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <span>{prop.name}</span>
                                  {prop.name !== "Assigned reps" && (
                                    <i className={newThClasses} />
                                  )}
                                </div>
                              </th>
                            );
                          }
                          if (role == "User" && prop.name !== "Assigned reps") {
                            return (
                              <th
                                className={`heading-${key}`}
                                key={`th-${key}`}
                                onClick={(e) => {
                                  this.handleSort(e, prop.value);
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <span>{prop.name}</span>
                                  {prop.name !== "Assigned reps" && (
                                    <i className={newThClasses} />
                                  )}
                                </div>
                              </th>
                            );
                          }
                          {
                            /*return (
                            <th
                              className={`heading-${key}`}
                              key={`th-${key}`}
                              onClick={e => {
                                this.handleSort(e, prop.value);
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <span>{prop.name}</span>
                                {prop.name !== 'Assigned reps' && <i className={newThClasses} />}
                              </div>
                            </th>
                          );*/
                          }
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {!_.isEmpty(retailerDataFilter) ? (
                        retailerDataFilter.map((prop, key) => {
                          return (
                            <tr key={key}>
                              <td>{prop.retailer.optId}</td>
                              <td className="retailer-name">
                                <Link
                                  to={`/dashboard/retailer/${prop.retailer._id}`}
                                >
                                  {prop.retailer.name}
                                </Link>
                                {this.filterOptionsData(prop)}
                              </td>
                              {(role === "BRAND_ADMIN" || role === "ADMIN") && (
                                <td>{this.assignedPersColumn(prop, role)}</td>
                              )}
                              <td>{prop.totalProducts}</td>
                              <td>{prop.flaggedProduct}</td>
                              <td>
                                {moment(prop.approvedDate)
                                  .add(timeZone, "minutes")
                                  .format("MM/DD/YY")}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          {role === "BRAND_ADMIN" || role === "ADMIN" ? (
                            <td className="text-left" colSpan={5}>
                              <p className="alert alert-info">
                                No Retailer(s) found.
                              </p>
                            </td>
                          ) : (
                            <td className="text-left" colSpan={4}>
                              <p className="alert alert-info">
                                No Retailer(s) found.
                              </p>
                            </td>
                          )}
                        </tr>
                      )}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Row className="omni-stat-cards">
            {!_.isEmpty(retailerDataFilter) && this.createOmniStats()}
          </Row>
        </div>
      );
    }

    return (
      <div id="summaryView" className="page">
        <Grid fluid>{htmlContent}</Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stats: state.stats.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchStats: () => {
      dispatch(thunkFetchStats());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryView);
