import HomePage from '../home';
import LoginPage from '../login/LoginPage.jsx';
import RegisterPage from '../login/RegisterPage.jsx';
import ResetPassword from '../login/ResetPassword.jsx';
import ForgotPasswordScreen from '../login/ForgotPasswordScreen';

var pagesRoutes = [
  {
    path: '/',
    name: 'Login Page',
    component: LoginPage
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login Page',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPasswordScreen
  },
  {
    path: '/activate-account',
    name: 'activate Password',
    component: ResetPassword
  }
];

export default pagesRoutes;
