import React from 'react';
import { Row, Col, Modal, Tab, Nav, NavItem } from 'react-bootstrap';
import WhyToFlagForm from 'dashboard/components/Forms/WhyToFlagForm';
import RetailerContactForm from 'dashboard/components/Forms/RetailerContactForm';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import thunkFetchProduct from 'dashboard/thunks/thunkFetchProduct';

class WhyToFlagModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      eventKey: 'flag',
      showModal: true
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.onModalHide = this.onModalHide.bind(this);
  }

  handleSelect(eventKey) {
    this.setState({
      eventKey
    });
  }

  onModalHide(params) {
    this.setState(
      {
        showModal: false
      },
      () => {
        this.props.callback(params);
      }
    );
  }

  render() {
    const { showModal, eventKey } = this.state;
    let { rId, pId, productName, retailer, filters } = this.props;
    return (
      <Modal
        animation={false}
        show={showModal}
        onHide={() => this.onModalHide({ isFlagged: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Flag for {productName} ({retailer.name}){' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="flagged-form">
          <Tab.Container
            id="tabs-with-FormModal"
            activeKey={eventKey}
            onSelect={this.handleSelect}
          >
            <Row className="clearfix">
              <Col sm={12}>
                <Nav bsStyle="tabs">
                  <NavItem eventKey="flag">
                    <h4>FLAG REASON</h4>
                  </NavItem>
                  <NavItem eventKey="contact">
                    <h4>CONTACT RETAILER</h4>
                  </NavItem>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content animation={false}>
                  <Tab.Pane eventKey="flag">
                    <p>What is wrong with this item (select all that apply)?</p>
                    <WhyToFlagForm
                      rId={rId}
                      pId={pId}
                      filters={filters}
                      callback={this.onModalHide}
                      doFetchProduct={this.props.doFetchProduct}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="contact">
                    <RetailerContactForm
                      source={'product'}
                      whyToFlag={true}
                      rId={rId}
                      pId={pId}
                      callback={this.onModalHide}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doFetchProduct: value => {
      dispatch(thunkFetchProduct(value));
    }
  };
};

const mapStateToProps = state => {
  return {
    retailer: state.retailer,
    filters: state.products.filters
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WhyToFlagModal)
);
