import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { withRouter } from 'react-router-dom';
import thunkFetchMonths from 'dashboard/thunks/thunkFetchMonths';
import uniqid from 'uniqid';
import { setActiveMonth } from 'dashboard/actions/month';
class PeriodSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onPeriodSelectChange = this.onPeriodSelectChange.bind(this);
  }

  onPeriodSelectChange(event) {
    let value = event.target.value;
    let { periods } = this.props;
    let period = find(periods, {
      value
    });
    this.props.thunkFetchMonth(period);
  }

  componentDidMount() {
    this.props.doFetchMonths({
      forAllRetailers: true
    });
  }

  /* componentWillReceiveProps(nextProps) {
    let { periods, period: selectedPeriod } = nextProps;
    // This means we fetched periods and its not selected yet
    // For first time we have to select first option
    if (periods.length && isEmpty(selectedPeriod)) {
      this.props.setActivsetActiveMontheMonth(periods[0]);
    }
  } */

  render() {
    let { periods, period } = this.props;
    let opts = [
      {
        value: 0,
        label: 'Select Period'
      }
    ];
    periods.forEach((rObj, index) => {
      opts.push({
        value: rObj.value,
        label: rObj.name
      });
    });

    let selectedPeriod = 0;
    if (!isEmpty(period)) {
      selectedPeriod = period.value;
    }

    return (
      <div className="period-select-container navbar-select-container">
        <select
          name="period-select"
          className="form-control"
          value={selectedPeriod}
          onChange={this.onPeriodSelectChange}
        >
          {opts.map((option, key) => {
            let optionKey = uniqid('select_');
            return (
              <option key={optionKey} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    periods: state.months.items,
    isLoading: state.months.isLoading,
    period: state.month
  };
};
/* const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setActiveMonth,
      doFetchMonths
    },
    dispatch
  );
}; */

const mapDispatchToProps = dispatch => {
  return {
    setActiveMonth: value => {
      dispatch(setActiveMonth(value));
    },
    doFetchMonths: value => {
      dispatch(
        thunkFetchMonths({
          rId: value
        })
      );
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PeriodSelect)
);
