import {
  ADD_BASKET_PRODUCT,
  REMOVE_BASKET_PRODUCT,
  CLEAR_BASKET,
  CLEAR_ALL_BASKET,
} from "../actions/actionTypes";
import { toastr } from "react-redux-toastr";

let initialState = {
  items: [],
  alreadyAddedFlag: false,
};

function retailerBasket(state = initialState, action) {
  // console.log("retailerBasket action    : ", action);
  let product = action.product;
  let optID = action.optID;
  switch (action.type) {
    case ADD_BASKET_PRODUCT:
      //   let index =
      //     state.length !== 0
      //       ? state
      //           .filter((retailer) =>
      //             Object.keys(retailer).includes(retailerID)
      //           )[0]
      //           .retailerID.indexOf(product)
      //       : 0;
      let index = state.items.findIndex(
        (item) =>
          item.customerId === optID && item.serialNo === product.serialNo
      );
      if (index !== -1) {
        // toastr.success("SUCCESS", "Product Already Added.");
        return { items: state.items, alreadyAddedFlag: true };
      } else {
        // let updatedState = state.map((retailer) => {
        //   if (Object.keys(retailer).includes(retailerID)) {
        //     return { retailerID: [...retailer.retailerID, product] };
        //   }
        //   return retailer;
        // });
        let updatedItems = [...state.items, product];
        // toastr.success("SUCCESS", "Product Added.");
        return { items: updatedItems, alreadyAddedFlag: false };
      }
    case REMOVE_BASKET_PRODUCT:
      // let updatedState = state.map((retailer) => {
      //   if (Object.keys(retailer).includes(retailerID)) {
      //     let products = retailer.retailerID.filter((prod) => prod !== product);
      //     return products ? { retailerID: products } : "";
      //   }
      //   return retailer;
      // });
      let updatedState = state.items.filter(
        (item) => item.serialNo !== product.serialNo
      );
      // toastr.success("SUCCESS", "Product Removed.");
      return { items: updatedState, alreadyAddedFlag: false };
    case CLEAR_BASKET:
      let updatedItems = state.items.filter(
        (item) => item.customerId !== optID
      );
      // toastr.success("SUCCESS", "Basket Cleared.");
      return { items: updatedItems, alreadyAddedFlag: false };
    case CLEAR_ALL_BASKET:
      // toastr.success("SUCCESS", "Basket Cleared.");
      return { items: [], alreadyAddedFlag: false };
    default:
      return state;
  }
}

export default retailerBasket;
