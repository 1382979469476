import React from 'react';
import { Modal, Button } from 'react-bootstrap';
// import { omni_axios_instance } from 'utils/axios';
// import { toastr } from 'react-redux-toastr';

class SuccessModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.onContinue = this.onContinue.bind(this);
  }

  onModalHide() {
    this.setState(
      {
        showModal: false
      },
      () => {
        this.props.callback();
      }
    );
  }

  handleSubmit(e) {
    e.preventDefault();
  }
  onContinue() {
    this.props.onContinue();
  }
  render() {
    const { showModal } = this.state;
    return (
      <Modal id="success-modal" show={showModal} onHide={this.onModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Completed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="success-modal-con">
            The file was successfully uploaded. To review your contacts, click
            on the "Contact" tab located on any Retatiler's profile. For
            assistance, please contact support@sitenaylsis.com.
          </div>
          <div className="success-modal__btns">
            <Button
              className="default-btn continue-btn"
              onClick={this.onContinue}
            >
              Continue
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SuccessModal;
