import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Auth from 'utils/auth';

const SignupSchema = Yup.object().shape({
  sitenalysis_Username: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  sitenalysis_Password: Yup.string()
    .max(20, 'Too Long!')
    .required('Required')
});

class LoginFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.forgotPasswordClick = this.forgotPasswordClick.bind(this);
  }

  handleSubmit(values, actions) {
    actions.setSubmitting(true);
    // console.log('values', values);
    // ( userId:values.sitenalysis_Username)
    Auth.login(
      {
        email: values.sitenalysis_Username,
        password: values.sitenalysis_Password
      },
      {
        failedRedirect: '/'
      }
    );
    actions.setSubmitting(false);
    return;
  }

  forgotPasswordClick(e) {
    e.preventDefault();
    this.props.showForgotPasswordModal_callback();
    this.props.onModalHide();
  }

  render() {
    return (
      <div className="login-form">
        <h4 className="form-heading">LOGIN TO YOUR ACCOUNT</h4>
        <h4 className="form-heading-mob">
          LOGIN TO <br /> YOUR ACCOUNT
        </h4>
        <Formik
          initialValues={{
            sitenalysis_Username: '',
            sitenalysis_Password: ''
          }}
          validationSchema={SignupSchema}
          onSubmit={this.handleSubmit}
          render={({ errors, touched, isSubmitting }) => (
            <Form id="login-form">
              <div className="form-group">
                <Field
                  type="text"
                  name="sitenalysis_Username"
                  className="form-control"
                  placeholder="Username"
                />
                <ErrorMessage
                  name="sitenalysis_Username"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-group">
                <Field
                  type="password"
                  name="sitenalysis_Password"
                  className="form-control"
                  placeholder="Password"
                />
                <ErrorMessage
                  name="sitenalysis_Password"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-gray btn-block"
                  disabled={isSubmitting}
                >
                  LOGIN
                </button>
              </div>
            </Form>
          )}
        />
        <div className="forgot-password-link">
          <span onClick={this.forgotPasswordClick}> Forgot Password </span>
        </div>
      </div>
    );
  }
}

export default LoginFormComponent;
