import React from 'react';
import { EditorState, Modifier } from 'draft-js';
import { omni_axios_instance } from 'utils/axios';
import forEach from 'lodash/forEach';
import replace from 'lodash/replace';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

export default class Placeholders extends React.Component {
  state = {
    open: false,
    placeholderOptions: []
  };

  componentDidMount() {
    omni_axios_instance
      .get(`/getvariable`)
      .then(res => {
        let variables = res.data.data;
        let placeholderOptions = variables.map(variable => ({
          key: variable,
          value: `{${variable}}`,
          text: variable
        }));
        this.setState({
          placeholderOptions
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  openPlaceholderDropdown = () => {
    let { mode, name } = this.props;
    if (mode !== 'add' && mode !== 'edit' && isEmpty(name)) {
      alert('Please select target email address.');
    } else {
      this.setState({ open: !this.state.open });
    }
  };

  addPlaceholder: Function = (placeholder): void => {
    const { editorState, onChange, variablesInfo, name, mode } = this.props;
    let text = placeholder.value;

    if (mode !== 'add' && mode !== 'edit') {
      forEach(variablesInfo, (value, key) => {
        text = replace(text, `{${key}}`, value);
      });
      if (includes(text, '{User_name}')) {
        text = replace(text, '{User_name}', name);
      }
    }

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-placeholder'));
  };

  render() {
    let { placeholderOptions, open } = this.state;
    return (
      <div
        onClick={this.openPlaceholderDropdown}
        className="rdw-block-wrapper rdw-placeholder-dropdown-wrapper"
        aria-label="rdw-block-control"
      >
        <div
          className="rdw-dropdown-wrapper rdw-block-dropdown rdw-placeholder-dropdown"
          aria-label="rdw-dropdown"
        >
          <div className="rdw-dropdown-selectedtext" title="Placeholders">
            <span>Insert Variable</span>
            <div className={`rdw-dropdown-caretto${open ? 'close' : 'open'}`} />
          </div>
          {open ? (
            <ul className="rdw-dropdown-optionwrapper">
              {placeholderOptions.map(item => {
                return (
                  <li
                    onClick={() => this.addPlaceholder(item)}
                    key={item.key}
                    className="rdw-dropdownoption-default rdw-dropdown-option"
                  >
                    {item.text}
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
}
