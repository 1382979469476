import React from 'react';
import { Modal, Form, FormControl, FormGroup } from 'react-bootstrap';
import Button from 'components/CustomButton/CustomButton.jsx';
import { omni_axios_instance } from 'utils/axios';
import { toastr } from 'react-redux-toastr';

class ReportABugModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      files: [],
      flag: 0
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.reportBug = this.reportBug.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value ? target.value : ''
    });
  }

  onFileInputChange(e) {
    let { files } = this.state;
    let fileArray = [];
    fileArray = Array.from(e.target.files);
    fileArray.map((file, index) => {
      return files.push(fileArray[index]);
    });

    this.setState({
      files: Array.from(files)
    });
  }
  removeFile(e, index) {
    e.preventDefault();
    let { files } = this.state;
    files.splice(index, 1);
    this.setState({
      files: Array.from(files)
    });
  }
  onModalHide() {
    let { callback } = this.props;
    this.setState(
      {
        showModal: false
      },
      () => {
        callback && callback();
      }
    );
  }

  reportBug(e) {
    console.log('e', e);
    e.preventDefault();
    let { name, email, message, phone, files } = this.state;
    let phoneValue = phone ? phone : '';
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phoneValue);
    formData.append('message', message);
    // loop through files
    for (var i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    console.log(formData, 'formData');

    omni_axios_instance.post(`/contact_us`, formData, config).then(response => {
      toastr.success('SUCCESS', 'Form submitted successfully.');
      this.setState({
        name: '',
        email: '',
        message: '',
        phone: '',
        showModal: false,
        files: []
      });
    });
  }

  render() {
    let { showModal, files } = this.state;
    // console.log(files);
    return (
      <div className="modal-container">
        <Button
          wd
          className="report-bug-btn"
          onClick={() => this.setState({ showModal: true })}
        >
          <i className="fa fa-bug" />
          Report a Bug
        </Button>

        <Modal
          id="report-bug-modal"
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report a Bug to OPT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.reportBug} encType="multipart/form-data">
              <FormGroup controlId="formBasicName">
                <FormControl
                  type="text"
                  name="name"
                  value={this.state.name || ''}
                  placeholder="Name"
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <FormGroup controlId="formBasicEmail">
                <FormControl
                  type="email"
                  value={this.state.email}
                  name="email"
                  placeholder="Email Address"
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <FormGroup controlId="formBasicNumber">
                <FormControl
                  type="tel"
                  value={this.state.phone}
                  name="phone"
                  placeholder="Phone"
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="formControlsTextarea">
                <FormControl
                  rows="4"
                  name="message"
                  value={this.state.message}
                  componentClass="textarea"
                  placeholder="How we can help you.?"
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>

              <FormGroup controlId="fileInput">
                <div className="form-footer-lcol">
                  <ul className="attachments">
                    {files.map((file, index) => {
                      return (
                        <li key={index} className="attachment-name">
                          {file.name}
                          <button
                            className="remove-file"
                            onClick={e => this.removeFile(e, index)}
                          >
                            <i className="remove-icon fa fa-times" />
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  {/*  <input
                    className="attachment-uploader form-control"
                    type="file"
                    label="File"
                    name="attachment"
                    multiple
                    onChange={this.onFileInputChange}
                  /> */}
                </div>
              </FormGroup>
              <FormGroup controlId="fileInput " className="form-footer">
                <div className="form-footer-attach">
                  <div className="fileUpload">
                    <input
                      id="uploadFile"
                      placeholder="Choose File"
                      disabled="disabled"
                    />
                    <span>Attach a File</span>
                    <input
                      id="uploadBtn"
                      className="upload form-control"
                      type="file"
                      label="File"
                      name="attachment"
                      multiple
                      onChange={this.onFileInputChange}
                    />
                  </div>
                </div>
                <div className="form-footer-rcol">
                  <Button className="submit-btn" type="submit">
                    Submit
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ReportABugModal;
