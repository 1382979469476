import React from "react";
import { Navbar, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginFormComponent from "../login/LoginFormComponent";

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.showMobileLoginModal = this.showMobileLoginModal.bind(this);
  }

  componentDidMount() {
    let body = document.querySelector("body");
    body.classList.remove("off-canvas-sidebar");
  }

  showMobileLoginModal(e) {
    e.preventDefault();
    this.props.showMobileLoginModal__callback();
  }

  render() {
    return (
      <header id="header">
        <Navbar fluid>
          <Navbar.Header>
            <Navbar.Brand>
              <div className="siteNalysisLogo-header">
                <div className="siteNalysisLogo-inner">
                  <a
                    onClick={(e) =>
                      (window.location.href = window.location.origin)
                    }
                    href="#"
                    style={{
                      fontSize: "34px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    {/* <img
                      src={`${process.env.PUBLIC_URL}/siteNalysis_Logo.png`}
                      alt="SiteNalysis"
                    /> */}
                    PRICE SCRAPER
                  </a>
                </div>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>

          <Navbar.Collapse>
            {/* <ul className="nav navbar-nav">
              <li>
                <a
                  onClick={(e) =>
                    (window.location.href = window.location.origin + "/#about")
                  }
                  href="#"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  onClick={(e) =>
                    (window.location.href =
                      window.location.origin + "/#schedule-demo")
                  }
                  href="#"
                >
                  Schedule a Demo
                </a>
              </li>
              <li className="hidden-sm hidden-md hidden-lg">
                <Link to="#login-modal" onClick={this.showMobileLoginModal}>
                  Login
                </Link>
              </li>
              <li id="login-dropdown-menu" className="hidden-xs">
                <Dropdown id="login-dropdown">
                  <Dropdown.Toggle>Login</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <LoginFormComponent
                      showForgotPasswordModal_callback={
                        this.props.showForgotPasswordModal_callback
                      }
                      onModalHide={this.props.onHide}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul> */}
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}

export default Header;
