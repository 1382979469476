import {
  FETCH_RETAILERS_REQUEST,
  FETCH_RETAILERS_SUCCESS,
  FETCH_RETAILERS_FAILURE
} from 'dashboard/actions/actionTypes';

let initialState = {
  items: [],
  isLoading: false,
  errors: []
};

function retailers(state = initialState, action) {
  const atts = action.atts || {};
  switch (action.type) {
    case FETCH_RETAILERS_REQUEST:
      // Silent ajax request
      // We don't need to show loader
      // We don't want to make `items` array empty
      if (atts.silent) {
        return Object.assign({}, state, { errors: [] });
      } else {
        return Object.assign({}, state, {
          isLoading: true,
          errors: [],
          items: []
        });
      }

    case FETCH_RETAILERS_SUCCESS:
      let retailers = action.payload || [];
      /* if (!isEmpty(action.payload)) {
        retailers = action.payload.filter(retailer => retailer.totalProducts > 0);
      } */
      return Object.assign({}, state, {
        isLoading: false,
        errors: [],
        items: retailers
      });

    case FETCH_RETAILERS_FAILURE:
      return Object.assign({}, state, action.atts, {
        isLoading: false,
        items: [],
        errors: action.payload
          ? action.payload.errors
          : ['Oops, blank response!']
      });

    default:
      return state;
  }
}

export default retailers;
