import React from 'react';
import { ControlLabel } from 'react-bootstrap';
import { isIE } from 'react-device-detect';

class FilterForReps extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  onFilterChange(e) {
    let filter = e.target.value;
    this.props.selectedFilter(filter);
  }

  render() {
    let { users } = this.props;
    return (
      <div className="filter-by-rep-name">
        <ControlLabel className="title-col">Filter By Rep Name:</ControlLabel>
        <div className="select-col">
          <select
            name="filter-by-repnames"
            id="filter-type-select"
            className={isIE ? 'filter-ie' : 'filter-default'}
            onChange={this.onFilterChange}
          >
            <option value="-1">Select One</option>
            {users.map((user, key) => {
              return (
                <option key={key} value={user.name}>
                  {user.name}{' '}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
}

export default FilterForReps;
