import React from 'react';

class Footer extends React.PureComponent {
  render() {
    return (
      <footer id="footer">
        <div className="footer__text">&copy; OC ONLINE INC. 2019</div>
      </footer>
    );
  }
}

export default Footer;
