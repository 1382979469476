import React from 'react';
import { Modal, Button } from 'react-bootstrap';

class WelcomeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
    this.props.callback(false); // for hide modal
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ show: false });
    this.props.callback(false); // for hide modal
  }

  render() {
    let { show } = this.state;

    return (
      <>
        <Modal id="welcome-modal" show={show} onHide={this.handleClose}>
          <Modal.Header closeButton />
          <Modal.Body>
            <form className="welcome-modal__form" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  type="checkbox"
                  name="terms-and-conditions"
                  id="terms-and-conditions"
                />
                <label forhtml="terms-and-conditions">
                  Terms and Conditions
                </label>
              </div>
              <Button type="submit">Submit</Button>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default WelcomeModal;
