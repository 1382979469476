import {
  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE
} from 'dashboard/actions/actionTypes';

let initialState = {
  items: [],
  isLoading: false,
  errors: []
};

function stats(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: [],
        items: []
      });

    case FETCH_STATS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: [],
        items: action.payload
      });

    case FETCH_STATS_FAILURE:
      return Object.assign({}, state, action.atts, {
        isLoading: false,
        items: [],
        errors: action.payload
          ? action.payload.errors
          : ['Oops, blank response!']
      });

    default:
      return state;
  }
}

export default stats;
