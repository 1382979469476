import {
  ADD_BASKET_PRODUCT,
  REMOVE_BASKET_PRODUCT,
  CLEAR_BASKET,
  CLEAR_ALL_BASKET,
} from "./actionTypes";
export function addBasketProduct(optID, product) {
  return {
    type: ADD_BASKET_PRODUCT,
    product,
    optID,
  };
}

export function removeBasketProduct(optID, product) {
  return {
    type: REMOVE_BASKET_PRODUCT,
    product,
    optID,
  };
}
export function clearBasket(optID) {
  return {
    type: CLEAR_BASKET,
    optID,
  };
}
export function clearAllBasket() {
  return {
    type: CLEAR_ALL_BASKET,
  };
}
