import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { omni_axios_instance } from 'utils/axios';
import queryString from 'querystringify';
import UserDetailsModal from './UserDetailsModal';

const columns = [
  {
    Header: 'Name',
    accessor: 'name', // String-based value accessors!
    className: 'table-cell-edit'
  },
  {
    Header: 'Email',
    accessor: 'email', // String-based value accessors!
    minWidth: 200
  },
  {
    Header: 'Brand',
    accessor: 'brand.name',
    className: 'table-cell-edit'
  }
];

class UserList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sort: [],
      pages: null,
      limit: 10,
      loading: false,
      showModal: false
    };
    this.fetchData = this.fetchData.bind(this);
    this.showPopUpModal = this.showPopUpModal.bind(this);
    this.hidePopUpModal = this.hidePopUpModal.bind(this);
  }
  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  };
  showPopUpModal(e, item) {
    this.setState({
      showModal: true,
      notification: item
    });
  }

  hidePopUpModal() {
    this.setState({
      showModal: false
    });
  }

  fetchData(state, instance) {
    this.setState({ loading: true });
    let sortingOpts = [];
    if (state.sorted) {
      state.sorted.forEach(function(element) {
        let id = element.id === 'brand.name' ? 'brand' : element.id;
        let order = element.desc === true ? 'desc' : 'asc';
        sortingOpts.push(`${id};${order}`);
      });
    }
    let obj = {
      page: ++state.page,
      limit: state.pageSize,
      sort: sortingOpts,
      keyword: state.filtered
    };
    let qs = queryString.stringify(obj);
    omni_axios_instance.get(`/users?${qs}`).then(res => {
      let data = res.data;
      let pages = Math.ceil(data.totalCount / state.pageSize);
      this.setState({
        data: res.data,
        pages: pages,
        loading: false
      });
    });
  }

  render() {
    let { data, pages, limit, loading, showModal } = this.state;
    let users = data.data;

    return (
      <div className="container">
        <h2 className="text-left"> Manage Users </h2>
        <div className="back-btn-div">
          <Link className="back-btn" to="/admin">
            <i className="fa fa-angle-left" aria-hidden="true" />
            <span>Back</span>
          </Link>
        </div>
        <div className="add-user-con text-right">
          <button className="btn btn-primary" onClick={this.showPopUpModal}>
            Add Users
          </button>
        </div>

        <div id="users-table-container">
          <ReactTable
            data={users}
            id="users-table"
            pages={pages}
            defaultPageSize={limit}
            columns={columns}
            minRows={0}
            manual
            server-side
            loading={loading}
            onFetchData={this.fetchData}
            filterable={true}
            showPaginationTop={false}
            defaultFilterMethod={(filter, row) =>
              this.filterCaseInsensitive(filter, row)
            }
            className="-striped"
          />
        </div>
        {showModal && (
          <UserDetailsModal show={showModal} callback={this.hidePopUpModal} />
        )}
      </div>
    );
  }
}

export default UserList;
