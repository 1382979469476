import React from "react";
import {
  Grid,
  Row,
  Col,
  Table,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// react component that creates a switch button that changes from on to off mode
import Card from "dashboard/components/Card/Card.jsx";
import OmniSwitch from "dashboard/components/OmniSwitch";
import ImageLoader from "dashboard/components/ImageLoader";
import cx from "classnames";
import { bindActionCreators } from "redux";
import Helpers from "utils/helpers";
import ProductPagination from "dashboard/ProductPagination";
import {
  doFetchProducts,
  doFetchProductsUpdate,
} from "dashboard/actions/products";
import RetailerContactModal from "dashboard/components/RetailerContactModal";
import Truncate from "react-truncate";
import { omni_axios_instance } from "./../../../utils/axios";

const retailerProductThArray = [
  {
    name: "Flag",
    sort: false,
  },
  {
    name: "Product Image",
    sort: false,
  },
  {
    name: "Name (Current)",
    sort: "name",
    order: "asc",
    isActive: false,
  },
  {
    name: "Price",
    sort: "price",
    order: "asc",
    isActive: false,
  },
  {
    name: "Retailer Sku",
    sort: "style_number",
    order: "asc",
    isActive: false,
  },
  {
    name: "Brand Sku",
    sort: "style_number",
    order: "asc",
    isActive: false,
  },
  {
    name: "Since last scan",
    sort: false,
  },
  {
    name: "Flagged reason",
    sort: false,
  },
  {
    name: "Changed From",
    sort: false,
  },
];

class RetailerLastScrapeProductsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tableHeadOpts: retailerProductThArray,
      showRetailerContactModal: false,
      _id: "",
      map_id: "",
      showLabel: true,
    };
    this.createTableData = this.createTableData.bind(this);
    this.setFlaggedItems = this.setFlaggedItems.bind(this);
    this.openRetailerContactModal = this.openRetailerContactModal.bind(this);
    this.showReadMoreText = this.showReadMoreText.bind(this);
    this.onRetailerContactModalClose =
      this.onRetailerContactModalClose.bind(this);

    this.doSort = this.doSort.bind(this);
    this.getSort = this.getSort.bind(this);
    this.updateProductMapID = this.updateProductMapID.bind(this);
  }

  async updateProductMapID(_id, map_id) {
    let { products, currentCount, totalCount } = this.props;
    let payload = { oh_map_id: map_id };
    console.log("product update payload", payload);
    await omni_axios_instance
      .put(
        `https://api.pricescraper.net/retailerBrandProductScrapings/${_id}`,
        payload
      )
      .then((response) => {
        console.log("product update response", response);
        if (response.status === 200) {
          this.props.doFetchProductsUpdate({
            _id,
            map_id,
            dataUpdated: { products, currentCount, totalCount },
          });
        }
      })
      .catch((err) => {
        console.log("product update err", err);
      });
  }

  setFlaggedItems(params) {}

  openRetailerContactModal() {
    this.setState({
      showRetailerContactModal: true,
    });
  }

  onRetailerContactModalClose() {
    this.setState({
      showRetailerContactModal: false,
    });
  }

  getSinceLastScrapeCell(product) {
    // Default
    let sinceLastScrape = {
      cellColor: "color-black",
      lastScrape: "N/A",
      changedFrom: "N/A",
      flaggedReason: "N/A",
      isRowDisabled: false,
      change_price: "",
      change_name: "",
    };

    if ("added" === product.type) {
      _.extend(sinceLastScrape, {
        cellColor: "color-green",
        lastScrape: "New!",
      });
    } else if ("deleted" === product.type) {
      _.extend(sinceLastScrape, {
        cellColor: "color-red",
        lastScrape: "Removed",
        isRowDisabled: true,
      });
    } else if ("change" === product.type) {
      let lastScrape = "Product Updated";
      let changedFrom = "";
      let change_name = "";
      let change_price = "";

      let changedProps = _.clone(product.change);
      changedProps = _.omit(changedProps, ["flagMessage"]);

      _.forEach(changedProps, (value, key) => {
        if (key === "isFlagged") {
          changedFrom += "";
        } else {
          change_price += key === "price" ? Helpers.setProductPrice(value) : "";
          change_name += key === "name" ? value : "";
          let changeValue =
            key === "price" ? Helpers.setProductPrice(value) : value;
          key = _.replace(key, "_", " ");
          key = _.capitalize(key);
          changedFrom += `<strong>${key}</strong>: ${changeValue}<br/>`;
        }
      });

      _.extend(sinceLastScrape, {
        cellColor: "color-black",
        lastScrape,
        changedFrom,
        change_name,
        change_price,
      });
    }

    return sinceLastScrape;
  }

  createTableData() {
    let { products: productsList } = this.props;

    if (_.isEmpty(productsList)) {
      return (
        <tr key={"0"}>
          <td className="text-center" colSpan="8">
            <div>No product(s) found!</div>
          </td>
        </tr>
      );
    }

    var tableRows = [];
    productsList.forEach((prop, key) => {
      let currentRetailerId = prop.retailer_brand_scraping;
      let sinceLastScrape = this.getSinceLastScrapeCell(prop);

      let falggedReasonTdHtml = sinceLastScrape.flaggedReason;
      if (prop.isFlagged) {
        let additionalDetails = Helpers.getFlaggedReason(prop);
        falggedReasonTdHtml = additionalDetails;
      }

      // #2
      const changedFromPopover = (
        <Popover id={`changed-from-popover-${key}`} title="Changed From">
          <div
            dangerouslySetInnerHTML={{ __html: sinceLastScrape.changedFrom }}
          />
        </Popover>
      );

      let changedFromEllipsisHtml = (
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="left"
          overlay={changedFromPopover}
        >
          <button className="btn-link">...Read more</button>
        </OverlayTrigger>
      );

      tableRows.push(
        <tr
          key={key}
          className={cx({
            "RetailerLastScrapeProductsTable-Row": true,
            disabled: sinceLastScrape.isRowDisabled,
          })}
        >
          <td className="text-center">
            <OmniSwitch
              callback={this.setFlaggedItems}
              isFlagged={prop.isFlagged}
              rId={currentRetailerId}
              type={prop.type}
              productName={prop.name}
              id={prop._id}
            />
            {!this.props.tabName ? (
              this.state._id === prop._id && !this.state.showLabel ? (
                <input
                  type="text"
                  value={this.state.map_id}
                  placeholder="Enter Map Id.."
                  onChange={(e) => {
                    console.log("e.target", e.target.value);
                    let value = e.target.value;
                    this.setState((prevState) => ({
                      ...prevState,
                      map_id: value,
                    }));
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      let { value } = e.target;
                      this.updateProductMapID(prop._id, value);
                      this.setState({ showLabel: true });
                    }
                  }}
                />
              ) : (
                <label
                  onClick={() => {
                    this.setState((prevState) => ({
                      ...prevState,
                      _id: prop._id,
                      map_id: prop.oh_map_id ? prop.oh_map_id : "",
                      showLabel: false,
                    }));
                  }}
                >
                  {prop.oh_map_id ? prop.oh_map_id : "Enter map id..."}
                </label>
              )
            ) : (
              <></>
            )}
          </td>
          <td>
            <div className="img-container Thumbnail-Image margin-auto">
              <Link
                to={`/dashboard/retailer/${currentRetailerId}/product/${prop._id}`}
                className="color-black"
              >
                <ImageLoader
                  openInNewTab={false}
                  zoomable={false}
                  image={prop.image}
                  className="img-responsive"
                />
              </Link>
            </div>
          </td>
          <td>
            <Link
              className={cx({
                "color-green": prop.type === "change",
                "color-black": prop.type === "deleted",
              })}
              to={`/dashboard/retailer/${currentRetailerId}/product/${prop._id}`}
            >
              {prop.name}
              <div
                className={cx({
                  "color-gray": prop.type === "change",
                  "data-changed": true,
                })}
              >
                {sinceLastScrape.change_name &&
                  `(${sinceLastScrape.change_name})`}
              </div>
            </Link>
          </td>
          <td
            className={cx({
              "color-green": prop.type === "change",
              "data-changed": true,
            })}
          >
            {Helpers.setProductPrice(prop.price)}
            <div
              className={cx({
                "color-gray": prop.type === "change",
                "data-changed": true,
              })}
            >
              {sinceLastScrape.change_price &&
                `(${sinceLastScrape.change_price})`}
            </div>
          </td>
          <td>{prop.style_number}</td>
          <td>
            {(prop.product && prop.product.sku) || prop.brand_sku || "N/A"}
          </td>

          <td
            className={sinceLastScrape.cellColor}
            dangerouslySetInnerHTML={{ __html: sinceLastScrape.lastScrape }}
          />
          <td className="td__flagged-reason">
            <div dangerouslySetInnerHTML={{ __html: falggedReasonTdHtml }} />
          </td>
          <td className="td__changed-from">
            <Truncate lines={1} ellipsis={changedFromEllipsisHtml}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sinceLastScrape.changedFrom,
                }}
              />
            </Truncate>
          </td>
        </tr>
      );
    });

    return tableRows;
  }

  showReadMoreText(html = "") {
    // console.log('html = ', html);
  }

  doSort(sortBy, oldSortOrder) {
    if (!sortBy) {
      return;
    }
    let { tableHeadOpts } = this.state;
    // get new order
    let newSortOrder = oldSortOrder === "asc" ? "desc" : "asc";
    tableHeadOpts = tableHeadOpts.map((x) => {
      if (x.sort === sortBy) {
        return {
          ...x,
          order: newSortOrder,
        };
      }

      // else we have to reset other columns order
      // to `asc`
      return {
        ...x,
        order: "asc",
      };
    });
    this.setState(
      {
        tableHeadOpts,
      },
      () => {
        let sort = `${sortBy};${newSortOrder}`;
        // let sort = this.getSort();
        this.props.doFetchProducts({
          sort,
          page: this.props.page,
          IS_SORT_FLAG: true,
        });
      }
    );
  }

  getSort() {
    let { tableHeadOpts } = this.state;
    let sortingProps = [];
    tableHeadOpts.forEach((prop, i) => {
      if (!prop.sort) {
        return;
      }
      sortingProps.push(`${prop.sort};${prop.order}`);
    });
    return sortingProps;
  }

  render() {
    let { isLoading, products, retailer, totalCount } = this.props;
    let { tableHeadOpts, showRetailerContactModal } = this.state;

    let thHtml = tableHeadOpts.map((prop, key) => {
      let sortIndicator = "";
      if (prop.sort) {
        let sortIndicatorClassNames = cx({
          col: true,
          fa: true,
          sort: true,
          "fa-sort-asc": prop.order === "asc",
          "fa-sort-desc": prop.order === "desc",
        });
        sortIndicator = (
          <i className={sortIndicatorClassNames} aria-hidden="true" />
        );
      }

      let thClassName = cx({
        thRow: true,
        "Sorting-Enabled": prop.sort,
      });

      return (
        <th
          className={thClassName}
          key={`pdtr-${key}`}
          onClick={() => this.doSort(prop.sort, prop.order)}
        >
          <div className="thRow-Inner">
            <span className="col">{prop.name}</span>
            {sortIndicator}
          </div>
        </th>
      );
    });

    let tbHtml = "";
    if (isLoading) {
      tbHtml = (
        <tr className="Products-Loader">
          <td colSpan="8">
            <i className="fa fa-spin fa-spinner fa-2x fa-fw" />
          </td>
        </tr>
      );
    } else {
      tbHtml = this.createTableData();
    }

    return (
      <div className="product-table-data">
        <div className="export-btn pull-right">
          <label>{totalCount} Items Found</label>
          <button
            id="scrapped-product-export-btn"
            className={cx({
              disabled: products.length === 0,
              "btn btn-default": true,
            })}
            onClick={() => products.length && this.props.exportRetailerInfo()}
          >
            export
            <i className="fa fa-file-excel-o" />
          </button>
        </div>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                textCenter
                tableFullWidth
                content={
                  <div>
                    <Row>
                      <Col sm={3}>
                        {/*  <div className="contact-retailer-btn-container">
                          <Button
                            bsStyle="default"
                            wd
                            className="contact-retailer-btn"
                            onClick={this.openRetailerContactModal}
                          >
                            Contact Retailer
                          </Button>
                        </div> */}
                        {showRetailerContactModal && (
                          <RetailerContactModal
                            retailer={retailer}
                            products={products}
                            show={showRetailerContactModal}
                            callback={this.onRetailerContactModalClose}
                            multiple={true}
                            source={"lastscan"}
                          />
                        )}
                      </Col>
                      <Col sm={9}>
                        <ProductPagination />
                      </Col>
                    </Row>
                    <Table
                      responsive
                      className="RetailerLastScrapeProductsTable table-bigboy"
                    >
                      <thead className="RetailerLastScrapeProductsTableHead">
                        <tr>{thHtml}</tr>
                      </thead>
                      <tbody className="RetailerLastScrapeProductsTableBody">
                        {tbHtml}
                      </tbody>
                    </Table>
                    <ProductPagination />
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.items,
    isLoading: state.products.isLoading,
    page: state.products.filters.page,
    totalCount: state.products.totalProducts,
    currentCount: state.products.currentProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doFetchProducts,
      doFetchProductsUpdate,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailerLastScrapeProductsTable);
