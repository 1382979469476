import React from 'react';

class FileUpload extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: []
    };
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  onFileInputChange(event) {
    let { selectedFiles } = this.state;
    let newSeletions = selectedFiles.concat(Array.from(event.target.files));
    this.setState(
      {
        selectedFiles: newSeletions
      },
      () => {
        this.props.callback(newSeletions);
      }
    );
  }

  removeFile(index) {
    let { selectedFiles } = this.state;
    let newArr = [...selectedFiles];
    newArr.splice(index, 1);
    this.setState(
      {
        selectedFiles: newArr
      },
      () => {
        this.props.callback(newArr);
      }
    );
  }

  render() {
    let { selectedFiles } = this.state;
    return (
      <div className="row">
        <div className="col-sm-8 col-md-8 form-footer-lcol">
          <ul className="attachments">
            {selectedFiles.map((file, index) => {
              return (
                <li key={index} className="attachment-name">
                  {file.name}
                  <button
                    className="remove-file"
                    onClick={e => {
                      e.preventDefault();
                      this.removeFile(index);
                    }}
                  >
                    <i className="remove-icon fa fa-times" />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-sm-4 col-md-4 uploadblock">
          <div className="footer-attach">
            <div className="footer-attach__file-upload">
              <input
                id="uploadFile"
                placeholder="Choose File"
                disabled="disabled"
              />
              <span>Attach a File</span>
              <input
                id="uploadBtn"
                className="upload form-control"
                type="file"
                label="File"
                name="attachment"
                multiple
                accept="image/*"
                onChange={this.onFileInputChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileUpload;
