import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import './ProductScreen.scss';
import ImageLoader from 'dashboard/components/ImageLoader';
import moment from 'moment';
import Helpers from 'utils/helpers';

class ProductScreen extends React.PureComponent {
  render() {
    let { product, retailer, months } = this.props;
    let { isFlagged } = product;
    let flaggedClass = '';

    if (isFlagged) {
      flaggedClass = 'fa fa-flag flag-marked fa-lg';
    } else {
      flaggedClass = 'fa fa-flag flag-unmarked fa-lg';
    }

    if (_.isEmpty(product)) {
      return <div />;
    }

    let nextScan = Helpers.getNextScan(
      months,
      retailer._id,
      retailer.nextScanDay
    );
    let timeZone = new Date().getTimezoneOffset();
    return (
      <div className="product-screens-container">
        <div className="product-name">
          {`Details for ${product.name} on ${retailer.name}`}
          <span>
            <i className={flaggedClass} />
          </span>
        </div>
        <Row className="product-screens-r2">
          <Col md={4}>
            <div className="Thumbnail-Image">
              <ImageLoader image={product.screenshot} isBackground />
            </div>
          </Col>
          <Col md={4}>
            <div className="Thumbnail-Image">
              <ImageLoader image={product.image} />
            </div>
          </Col>
          <Col md={4}>
            <div className="product-details">
              <h5>Quick Details</h5>

              <label> Last Scan (current period): </label>
              <span>
                &nbsp;
                {retailer.approvedDate
                  ? moment(new Date(`${retailer.approvedDate}`))
                      .add(timeZone, 'minutes')
                      .format('MMMM DD, YYYY')
                  : 'N/A'}
              </span>
              <br />
              <label> Next Scan: </label>
              <span>
                &nbsp;
                {nextScan}
              </span>
              <br />
              <label>Retailer Name: </label>
              <span> {retailer.name}</span>
              <br />
              <label>Product Name: </label>
              <span> {product.name}</span>
              <br />
              <label>URL: </label>
              <span>
                <a href={product.URL} target="_blank" rel="noopener noreferrer">
                  {' '}
                  {product.URL}
                </a>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    retailer: state.retailer,
    product: state.product,
    months: state.months.items,
    month: state.month
  };
};

export default connect(
  mapStateToProps,
  null
)(ProductScreen);
