import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';

class NotificationDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.show || false
    };
    this.onModalHide = this.onModalHide.bind(this);
  }

  onModalHide() {
    let { callback } = this.props;
    this.setState(
      {
        showModal: false
      },
      () => {
        callback && callback();
      }
    );
  }
  render() {
    let { showModal } = this.state;
    let { notification } = this.props;
    // console.log('notification', notification);
    const message = (
      <div
        className="notification-message"
        dangerouslySetInnerHTML={{ __html: notification.message }}
      />
    );

    return (
      <div className="modal-container">
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="NotificationPopUpModal"
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          <Modal.Header closeButton className="modal-header-con">
            <Modal.Title>{notification.retailer}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={9}>
                <span className="subject">
                  {notification.subject.split(':')[1]}
                </span>
              </Col>
              <Col sm={3}>
                <span className="lastUpdated-date">
                  {moment(new Date(`${notification.createdAt}`)).format(
                    'MM/DD/YYYY'
                  )}
                </span>
              </Col>
            </Row>
            <hr />
            {message}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default NotificationDetailsModal;
