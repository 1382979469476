import React from 'react';
import AdminComponent from './Admin';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class AdminHomePage extends React.PureComponent {
  render() {
    return (
      <div className="admin">
        <div className="admin-con">
          <div className="container">
            <AdminComponent {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { unseenCount: state.admin.notification.data.unseenCount };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(AdminHomePage)
);
