import React, { useState, useRef, useEffect } from 'react';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import BrandSkuModal from 'dashboard/components/catalog-matching/BrandSkuModal';
import thunkFetchProduct from 'dashboard/thunks/thunkFetchProduct';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const user = Auth.getUser();
const defaultOptions = {
  label: 'Other',
  value: 'other'
};
const defaultState = {
  brandSkuOptions: [defaultOptions],
  showBrandSkuModal: false
};
const BrandSku = props => {
  const [state, setState] = useState(defaultState);
  const isMounted = useRef(true);
  let { showBrandSkuModal, brandSkuOptions } = state;
  let { product } = props;
  async function getBrandSku() {
    let brandId = user.brand && user.brand._id;

    try {
      brandId &&
        omni_axios_instance.get(`/getBrandSkus/${brandId}`).then(res => {
          let data = res.data.data;
          let selectedOptions = data.map(opt => ({
            label: opt.sku,
            value: opt._id
          }));
          selectedOptions.unshift(defaultOptions);
          if (isMounted.current) {
            setState({
              ...state,
              brandSkuOptions: selectedOptions,
              brandId
            });
          }
        });
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getBrandSku();
    return () => {
      isMounted.current = false;
    };
  }, []);
  const openBrandSkuModal = () => {
    setState({
      ...state,
      showBrandSkuModal: true
    });
  };
  const onBrandSkuModalClose = () => {
    props.doFetchProduct(product._id);
    setState({
      ...state,
      showBrandSkuModal: false
    });
  };
  return (
    <>
      <div className="brand-sku-info">
        N/A
        <span onClick={openBrandSkuModal}>edit</span>
      </div>
      {showBrandSkuModal && (
        <BrandSkuModal
          show={showBrandSkuModal}
          brandSkuOptions={brandSkuOptions}
          productId={product._id}
          callback={onBrandSkuModalClose}
        />
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    doFetchProduct: value => {
      dispatch(thunkFetchProduct(value));
    }
  };
};

const mapStateToProps = state => {
  return {
    retailer: state.retailer,
    product: state.product
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BrandSku)
);
