import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import forEach from 'lodash/forEach';
import replace from 'lodash/replace';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import Auth from 'utils/auth';

class MessageSelect extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      openDropdown: false,
      cannedMessagesOpts: [],
      filteredMessages: [],
      searchText: '',
      showReplyPopOver: false
    };
    this.listRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    const user = Auth.getUser();
    (() => {
      if (isEmpty(user)) {
        return;
      }
      omni_axios_instance
        .get(`/cannedmessages?brand=${user.brand._id}&limit=10000`)
        .then(res => {
          let cannedMessages = res.data.data;
          let filteredMessages = cannedMessages.filter(function(message) {
            return message.status !== 'inactive';
          });
          let cannedMessagesOpts = filteredMessages.map(m => ({
            key: m._id,
            value: m.content,
            text: m.name
          }));
          if (this._isMounted) {
            this.setState({
              cannedMessagesOpts,
              filteredMessages: cannedMessagesOpts
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    })();
  }
  componentWillMount() {
    this._isMounted = false;
  }
  toggleDropdown = () => {
    this.setState(prev => ({ openDropdown: !prev.openDropdown }));
  };

  addMessage = message => {
    const { onChange, variablesInfo, name } = this.props;
    let text = message.value;
    forEach(variablesInfo, function(value, key) {
      text = replace(text, `{${key}}`, value);
    });
    if (includes(text, '{User_name}')) {
      text = replace(text, '{User_name}', name);
    }
    const blocksFromHtml = htmlToDraft(text);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    // console.log('blocksFromHtml', blocksFromHtml);
    let editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    onChange(EditorState.push(editorState, contentState, 'insert-message'));
    this.setState({
      openDropdown: false,
      searchText: ''
    });
    this.props.callback(true);
    //while adding message we have to change replace variable with vals into message
  };

  onSearchChange = e => {
    this.setState(
      {
        searchText: e.target.value
      },
      () => this.filterMessages()
    );
  };

  filterMessages = () => {
    let { cannedMessagesOpts, searchText } = this.state;
    let searchValue = searchText.toLowerCase();
    let filteredList = cannedMessagesOpts;

    filteredList = filteredList.filter(item => {
      return item.text.toLowerCase().indexOf(searchValue) > -1;
    });
    this.setState({
      filteredMessages: filteredList
    });
  };

  listItems = () => {
    let { filteredMessages } = this.state;
    return filteredMessages.map(item => (
      <ListGroupItem
        key={item.key}
        className="mdx-dropdown-option"
        title={item.text}
        onClick={() => this.addMessage(item)}
      >
        {item.text}
      </ListGroupItem>
    ));
  };

  render() {
    let { openDropdown, searchText } = this.state;

    return (
      <>
        <div
          className="rdw-option-wrapper rdw-message-dropdown-wrapper"
          ref={this.listRef}
        >
          <i className="fa fa-commenting-o" onClick={this.toggleDropdown} />
          {openDropdown ? (
            <>
              <ListGroup className="mdx-dropdown">
                <ListGroupItem
                  title="Search"
                  className="mdx-dropdown-option mdx-search-option"
                >
                  <input
                    type="text"
                    className="form-control rdw-search"
                    onChange={this.onSearchChange}
                    value={searchText}
                  />
                </ListGroupItem>
                {this.listItems()}
              </ListGroup>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export default MessageSelect;
