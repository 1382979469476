import {
  SET_ACTIVE_MONTH,
  FETCH_MONTH_REQUEST,
  FETCH_MONTH_SUCCESS,
  FETCH_MONTH_FAILURE
} from 'dashboard/actions/actionTypes';

let initialState = {};
function month(state = initialState, action) {
  switch (action.type) {
    // Rather than retailers routes, we have to empty months
    case '@@router/LOCATION_CHANGE':
      let { pathname } = action.payload;
      if (pathname && pathname.indexOf('/retailer') === -1) {
        return initialState;
      } else {
        return state;
      }

    case SET_ACTIVE_MONTH:
      return Object.assign({}, action.period);

    case FETCH_MONTH_REQUEST:
      return Object.assign({}, initialState);

    case FETCH_MONTH_SUCCESS:
      let { data } = action.payload;

      return Object.assign({}, data);

    case FETCH_MONTH_FAILURE:
      return Object.assign({}, initialState);

    default:
      return state;
  }
}

export default month;
