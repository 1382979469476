import {
  FETCH_RETAILERS_REQUEST,
  FETCH_RETAILERS_SUCCESS,
  FETCH_RETAILERS_FAILURE
} from './actionTypes';

/*
 * Retailers list
 */
export function doFetchRetailersList(atts) {
  return {
    type: FETCH_RETAILERS_REQUEST,
    atts
  };
}

export function doFetchRetailersListSuccess(payload) {
  return {
    type: FETCH_RETAILERS_SUCCESS,
    payload
  };
}

export function doFetchRetailersListFailure(error) {
  return {
    type: FETCH_RETAILERS_FAILURE,
    error
  };
}
