import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import MessagesEditor from './MessagesEditor';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';

const options = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' }
];

const defaultState = {
  name: '',
  content: '',
  status: { label: 'Select message status', value: '' }
};
const AddEditMessageModal = props => {
  let { message, mode } = props;
  const [state, setState] = useState(defaultState);
  const [errors, setErrors] = useState([]);
  const user = Auth.getUser();

  useEffect(() => {
    if (mode === 'edit') {
      setState(message);
    } else {
      setState(defaultState);
    }
  }, [mode]);

  const onCloseModal = () => {
    props.onHide(false);
  };

  const handleInputChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };
  const handleSelectChange = selectedOption => {
    setState({
      ...state,
      status: selectedOption
    });
  };
  const onEditorStateChangeCallback = (content, hasText) => {
    setState({
      ...state,
      content
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    let status = state.status;
    // Handle validations
    let tErrors = [];
    if (!state.name) {
      tErrors.push('Name is required.');
    }
    if (!state.content) {
      tErrors.push('Message is required.');
    }
    if (isEmpty(status.value)) {
      tErrors.push('Please select status.');
    }
    if (!isEmpty(tErrors)) {
      setErrors(tErrors);
      return;
    }
    let brandId = '';
    if (!isEmpty(user)) {
      brandId = user.brand._id;
    }
    // Data to be posted
    let formData = {
      ...state,
      brand: brandId,
      status: status.value
    };

    // When add/edit mode
    let promise = null;
    let mesg = '';
    if (mode === 'edit') {
      promise = omni_axios_instance.put(
        `/cannedmessages/${state._id}`,
        formData
      );
      mesg = 'Message updated successfully';
    } else {
      promise = omni_axios_instance.post(`/cannedmessages`, formData);
      mesg = 'Message created successfully';
    }
    promise
      .then(res => {
        toastr.success('SUCCESS', mesg);
      })
      .catch(function(error) {
        console.log(error);
      });
    props.onHide(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={onCloseModal}
      container={this}
      className="add-edit-message-modal"
    >
      <Modal.Header closeButton>
        {mode === 'edit' ? (
          <Modal.Title>Edit Canned Message</Modal.Title>
        ) : (
          <Modal.Title>Create new Canned Message</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <form className="add-edit-message-modal__form" onSubmit={onSubmit}>
          {errors.length > 0 && (
            <header className="add-edit-message-modal__form__errors">
              {errors.map((e, index) => (
                <p className="error" key={index}>
                  {e}
                </p>
              ))}
            </header>
          )}
          <div className="form-group add-edit-message-modal__form--margin">
            <label
              htmlFor="message-name"
              className="add-edit-message-modal__form__label"
            >
              Message Name
            </label>
            <input
              type="text"
              id="message-name"
              name="name"
              className="form-control add-edit-message-modal__mesg-name"
              value={state.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group add-edit-message-modal__form--margin">
            <label className="add-edit-message-modal__form__label">
              Message Content
            </label>
            <MessagesEditor
              name="content"
              onEditorStateChangeCallback={onEditorStateChangeCallback}
              mesgContent={state.content}
              mode={mode}
            />
          </div>
          <div className="form-group add-edit-message-modal__form--margin">
            <label className="add-edit-message-modal__form__label">
              status
            </label>
            <Select
              value={state.status}
              options={options}
              onChange={handleSelectChange}
              placeholder="Select message status"
            />
          </div>
          <div className="add-edit-message-modal__form_btn">
            {mode === 'edit' ? (
              <Button
                type="submit"
                className="btn add-edit-message-modal__form__updateMesgBtn"
              >
                Update
              </Button>
            ) : (
              <Button
                type="submit"
                className="btn add-edit-message-modal__form__createMesgBtn"
              >
                Create
              </Button>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditMessageModal;
