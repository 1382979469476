import React from 'react';
import { Modal } from 'react-bootstrap';

class EmailPreviewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.onModalHide = this.onModalHide.bind(this);
    // this.onShowHide = this.onShowHide.bind(this);
  }

  onModalHide() {
    this.props.onModalHide();
  }

  render() {
    const { showModal, emailData } = this.props;
    return (
      <div>
        <Modal
          className="email-preview"
          animation={false}
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Email Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body className="contact-form">
            <div dangerouslySetInnerHTML={{ __html: emailData }} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default EmailPreviewModal;
