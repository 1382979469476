import React from 'react';
import { Button } from 'react-bootstrap';
import AdminHeader from './common/AdminHeader';
import { history } from '../configureStore';
import BrandSelectionModal from '../home/BrandSelectionModal';
import Auth from 'utils/auth';
import store from 'store';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import bgImage from 'assets/img/TopZone-bg2-blur.png';
class AdminConfirm extends React.Component {
  state = { show: false, brands: [] };
  loginAs(type) {
    if (type == 'admin') {
      Auth.adminAsBrandUser = false;
      history.push('/admin');
    } else {
      this.setState({ show: true });
      return false;
    }
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  componentDidMount() {
    let user = Auth.getUser();
    let isAdmin = _.find(user.roles, function(o) {
      return o.name === 'ADMIN';
    });
    if (_.isEmpty(isAdmin)) {
      store.set('omni_deeplink', {
        path: this.props.location.pathname,
        qs: this.props.location.search
      });
      this.props.history.push('/login');
    }
  }
  render() {
    let { show, brands } = this.state;
    return (
      <div
        className="admin-confirm"
        style={{ backgroundImage: 'url(' + bgImage + ')' }}
      >
        <AdminHeader />
        <BrandSelectionModal show={show} handleClose={this.handleClose} />

        <div className="card">
          <div
            className="card-body"
            style={{ textAlign: 'center', paddingTop: '20px' }}
          >
            <div>
              <Link
                onClick={e => (window.location.href = window.location.origin)}
                to="/"
              >
                <img
                  className="logo img-responsive"
                  src={`${process.env.PUBLIC_URL}/siteNalysis_Logo.png`}
                  alt="SiteNalysis"
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: 280
                  }}
                />
              </Link>
              <p>powered by OPT</p>
            </div>
            <h3 className="card-title">Login as</h3>
            <br />
            <p className="card-text">
              <Button variant="primary" onClick={e => this.loginAs('admin')}>
                Admin
              </Button>
              <Button
                variant="primary"
                onClick={e => this.loginAs('branduser')}
              >
                Brand User
              </Button>
            </p>
          </div>
        </div>

        {/* <div
          style={{
            display: "none",
            background: "white",
            width: 400,
            position: "absolute",
            left: "30%",
            top: "30%",
            minHeight: 200
          }}
        >
          {" "}
          <b>Login As</b>
          <br />
          <Button
            bsStyle="warning"
            className="btn btn-warning"
            onClick={e => this.loginAs("admin")}
          >
            Admin
          </Button>
          <Button
            bsStyle="default"
            className="btn btn-default"
            onClick={e => this.loginAs("branduser")}
          >
            Brand User
          </Button>
        </div> */}
      </div>
    );
  }
}
export default AdminConfirm;
