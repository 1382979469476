import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isIE } from "react-device-detect";
import cx from "classnames";

class HeaderLinks extends React.PureComponent {
  render() {
    return (
      <div className="navbar-menus-container header-right-menu">
        {/* <div className="siteNalysisLogo-header">
          <div
            className={cx({
              'siteNalysisLogo-inner': true,
              'siteNalysisLogo-inner-ie': isIE
            })}
          >
            <img
              src={`${process.env.PUBLIC_URL}/siteNalysis_Logo.png`}
              alt="SiteNalysis"
              style={{
                marginTop: 5
              }}
            />
            <div
              className={cx({
                'powered-by': true,
                'powered-by-ie': isIE
              })}
            >
              <sub>Powered by OPT</sub>
            </div>
          </div>
        </div> */}
        <div className="searchbar-mobile">
          <Link to="/login?session=logout">
            <Button className="logout-btn" bsStyle="danger">
              <span className="btn-label">
                <i className="pe-7s-close-circle" />
              </span>
              Log out
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}
export default HeaderLinks;
