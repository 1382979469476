import {
  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE
} from './actionTypes';

/*
 * STATS list
 */
export function doFetchStats(atts) {
  return {
    type: FETCH_STATS_REQUEST,
    atts
  };
}

export function doFetchStatsSuccess(payload) {
  return {
    type: FETCH_STATS_SUCCESS,
    payload
  };
}

export function doFetchStatsFailure(error) {
  return {
    type: FETCH_STATS_FAILURE,
    error
  };
}
