import React from 'react';
import { Button } from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import { toastr } from 'react-redux-toastr';

class ExportContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onExportFile = this.onExportFile.bind(this);
  }

  onExportFile(format) {
    let { contactCount } = this.props;
    const user = Auth.getUser();
    const brandId = user.brand._id;
    if (contactCount > 0) {
      omni_axios_instance
        .get(`/addressBook/export/${brandId}?fileType=${format}`, {
          responseType: 'blob'
        })
        .then(res => {
          // window.open = res.data;
          toastr.success('SUCCESS', 'File downloaded successfully.');
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          let d = new Date();
          let month = d.getMonth() + 1;
          let fileName =
            'contacts.' +
            d.getFullYear() +
            '.' +
            month +
            '.' +
            d.getDay() +
            '_' +
            d.getHours() +
            '.' +
            d.getMinutes() +
            `.${format}`;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    } else {
      alert('Please import files');
    }
  }

  render() {
    let { contactCount } = this.props;
    return (
      <div className="export-contact">
        <div className="export-contact-title">
          <h6>Total Contacts:</h6>
          <span>{contactCount}</span>
        </div>
        <div className="export-contacts-btns">
          <form encType="multipart/form-data">
            <Button
              className="default-btn"
              onClick={() => this.onExportFile('csv')}
            >
              Export A CSV FILE
            </Button>
            <span>or</span>
            <Button
              className="default-btn"
              onClick={() => this.onExportFile('xlsx')}
            >
              Export An Excel File
            </Button>
            <span>for downloading all existing contacts</span>
          </form>
        </div>
      </div>
    );
  }
}
export default ExportContact;
