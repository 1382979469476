import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { withRouter } from 'react-router';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import Button from 'components/CustomButton/CustomButton.jsx';
import { toastr } from 'react-redux-toastr';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';

const optionsforEmailFrequency = [
  { value: 'Daily', label: 'Daily' },
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly' }
];
class UserSettingForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      eventKey: 'user',
      email: '',
      password: '',
      name: '',
      brandName: '',
      UserEmailBcc: '',
      selectedOption: null
    };
    this.handleInputField = this.handleInputField.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.handleChangeEmailFrequency = this.handleChangeEmailFrequency.bind(
      this
    );
  }

  componentWillMount() {
    // let formVals = LocalStorage._getValueFromStore('settingsForm');
    let USER = Auth.getUser();
    this.setState({
      email: USER.email,
      name: USER.name,
      password: USER.password,
      brandName: USER.brand.name,
      UserEmailBcc: USER.user_email_bcc,
      selectedOption: { value: USER.emailFrequency, label: USER.emailFrequency }
    });
    // this.props.initialize(formVals);
  }

  handleChangeEmailFrequency = selectedOption => {
    this.setState({ selectedOption });
  };

  handleInputField(e) {
    e.preventDefault();
    let name = e.target.name;
    this.setState({
      [name]: e.target.value
    });
  }

  saveSettings(e) {
    e.preventDefault();
    let USER = Auth.getUser();
    let {
      email,
      password,
      name,
      brandName,
      UserEmailBcc,
      selectedOption
    } = this.state;

    let values = {
      email,
      password,
      name,
      brandName,
      user_email_bcc: UserEmailBcc,
      emailFrequency: selectedOption.value
    };
    omni_axios_instance
      .put(`/users/${USER._id}`, values)
      .then(response => {
        this.props.history.push('/login');
        toastr.success('SUCCESS', 'Settings updated.');
      })
      .catch(error => {
        // https://github.com/axios/axios#handling-errors
      });

    // LocalStorage._setValueInStore(values, 'settingsForm');
  }

  render() {
    const { submitting } = this.props;

    let {
      email,
      password,
      name,
      brandName,
      UserEmailBcc,
      selectedOption
    } = this.state;

    return (
      <form onSubmit={this.saveSettings}>
        <FormGroup>
          <ControlLabel>Email address</ControlLabel>
          <FormControl
            name="email"
            value={email || ''}
            disabled={true}
            className="form-control"
            type="email"
            onChange={this.handleInputField}
            required
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>Password</ControlLabel>
          <FormControl
            name="password"
            value={password || ''}
            className="form-control"
            onChange={this.handleInputField}
            type="password"
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Name</ControlLabel>
          <FormControl
            name="name"
            value={name || ''}
            className="form-control"
            onChange={this.handleInputField}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Brand Name</ControlLabel>
          <FormControl
            name="brandName"
            disabled={true}
            value={brandName || ''}
            className="form-control"
            onChange={this.handleInputField}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>user email bcc</ControlLabel>
          <span data-tip="To bcc email address(es) on all outbound emails sent via the Retailer Contact Form, add the desired email address(es) below. To include multiple emails, separate each with a comma.">
            <img
              className="img-icon"
              src={`${process.env.PUBLIC_URL}/info-icon.png`}
              alt="info-icon"
            />
          </span>
          <FormControl
            name="UserEmailBcc"
            disabled={false}
            value={UserEmailBcc || ''}
            className="form-control"
            onChange={this.handleInputField}
            type="text"
          />
          <ReactTooltip
            className="omni__tooltip omni__tooltip--right"
            place="right"
            type="light"
            effect="solid"
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>Retailer scan updates email frequency</ControlLabel>
          <span data-tip="A summary email will be sent including a consolidated digest/recap of all retailer website updates/new scans at your desired frequency.">
            <img
              className="img-icon"
              src={`${process.env.PUBLIC_URL}/info-icon.png`}
              alt="info-icon"
            />
          </span>
          <Select
            value={selectedOption}
            onChange={this.handleChangeEmailFrequency}
            options={optionsforEmailFrequency}
          />
        </FormGroup>

        <FormGroup>
          <Button bsStyle="info" fill type="submit" disabled={submitting}>
            Update
          </Button>
        </FormGroup>
      </form>
    );
  }
}

export default withRouter(UserSettingForm);
