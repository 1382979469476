import {
  FETCH_CONTACTINFO_REQUEST,
  FETCH_CONTACTINFO_SUCCESS,
  FETCH_CONTACTINFO_FAILURE
} from 'dashboard/actions/actionTypes';

const defaultState = {
  loading: false
};

export default function contactInfo(state = defaultState, action) {
  switch (action.type) {
    case FETCH_CONTACTINFO_REQUEST:
      return Object.assign(defaultState, {
        loading: true
      });

    case FETCH_CONTACTINFO_SUCCESS:
      let { data } = action.payload;
      return Object.assign(
        defaultState,
        {
          loading: false
        },
        data
      );

    case FETCH_CONTACTINFO_FAILURE:
      return Object.assign(defaultState, {
        loading: false
      });

    default:
      return state;
  }
}
