import {
  FETCH_RETAILER_SCRAPPING_INFO_REQUEST,
  FETCH_RETAILER_SCRAPPING_INFO_SUCCESS,
  FETCH_RETAILER_SCRAPPING_INFO_FAILURE
} from 'dashboard/actions/actionTypes';

let initialState = {
  loading: false,
  data: {}
};

function retailerScrappingInfo(state = initialState, action) {

  switch (action.type) {
    case FETCH_RETAILER_SCRAPPING_INFO_REQUEST:
        return Object.assign({}, state, {
          loading: true,
          data: {}
        });

    case FETCH_RETAILER_SCRAPPING_INFO_SUCCESS:
      let resp = action.payload || {};
      return Object.assign({}, state, {
        loading: false,
        data: resp
      });

    case FETCH_RETAILER_SCRAPPING_INFO_FAILURE:
      return Object.assign({}, state, action.atts, {
        loading: false,
        errors: action.payload
          ? action.payload.errors
          : ['Oops, blank response!']
      });

    default:
      return state;
  }
}

export default retailerScrappingInfo;

