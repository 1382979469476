import React from 'react';
import { Tab, Nav, NavItem } from 'react-bootstrap';
import ImportContact from './ImportContact';
import ExportContact from './ExportContact';
import ImportExportHistory from './ImportExportHistory';
import { omni_axios_instance } from 'utils/axios';
import axios from 'axios';
import Auth from 'utils/auth';
import './address-book.scss';

// console.log('brandId', brandId)
class ContactTab extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      eventKey: 'import-contacts',
      show: false,
      loading: false,
      downloadedFiles: [],
      uploadedFiles: [],
      contactCount: ''
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  getFiles(brandId) {
    this.setState({ loading: true });
    axios
      .all([
        omni_axios_instance.get(
          `/importexportaddressBook/${brandId}?type=addressBook&uploadType=export`
        ),
        omni_axios_instance.get(
          `/importexportaddressBook/${brandId}?type=addressBook&uploadType=import`
        )
      ])
      .then(
        axios.spread((downloadedFiles, uploadedFiles) => {
          this.setState({
            loading: false,
            uploadedFiles: uploadedFiles.data.data,
            downloadedFiles: downloadedFiles.data.data
          });
        })
      );
  }
  getCount(brandId) {
    omni_axios_instance
      .get(`/addressBook/getContactCount/${brandId}`)
      .then(res => {
        let count = res.data.contactCount;
        this.setState({
          contactCount: count
        });
      });
  }
  handleSelect(eventKey) {
    let user = Auth.getUser();
    let brandId = user.brand._id;
    if (eventKey === 'import-export-history') {
      this.getFiles(brandId);
    } else if (eventKey === 'export-contacts') {
      this.getCount(brandId);
    }
    this.setState({
      eventKey
    });
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }
  onContinue() {
    this.handleSelect('export-contacts');
    this.handleClose();
  }
  render() {
    let {
      eventKey,
      show,
      downloadedFiles,
      uploadedFiles,
      contactCount,
      loading
    } = this.state;
    return (
      <div className="contact-tab__menu container-fluid">
        <Tab.Container
          id="tabs-for-contact"
          activeKey={eventKey}
          onSelect={this.handleSelect}
          className="setting-form"
        >
          <div className="clearfix">
            <div className="contact-tab__menu--leftcol">
              <Nav bsStyle="tabs">
                <NavItem eventKey="import-contacts">
                  <h4>Import Contacts (.csv or .xlsx)</h4>
                </NavItem>
                <NavItem eventKey="export-contacts">
                  <h4>Export Contacts</h4>
                </NavItem>
                <NavItem eventKey="import-export-history">
                  <h4>Import/Export History</h4>
                </NavItem>
              </Nav>
            </div>
            <div className="contact-tab__menu--rightcol">
              <Tab.Content animation={false}>
                <Tab.Pane eventKey="import-contacts">
                  <ImportContact
                    showSuccessModal={show}
                    onSuccessModalShow={this.handleShow}
                    onSuccessModalClose={this.handleClose}
                    onContinue={this.onContinue}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="export-contacts">
                  <ExportContact contactCount={contactCount} />
                </Tab.Pane>
                <Tab.Pane eventKey="import-export-history">
                  <ImportExportHistory
                    downloadedFiles={downloadedFiles}
                    uploadedFiles={uploadedFiles}
                    loading={loading}
                  />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    );
  }
}

export default ContactTab;
