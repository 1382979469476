import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createHashHistory } from "history";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./rootReducer";

export const history = createHashHistory({
  // basename: `/optportal/showcase/${SHOWCASE}.html`,
});
// getting the persisted state from local storage
const persistedState = sessionStorage.getItem("reduxState")
  ? JSON.parse(sessionStorage.getItem("reduxState"))
  : {};

// const initialState = {};

const store = createStore(
  rootReducer(history), // root reducer with router state
  persistedState,
  composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
);
//storing the redux store at local storage for persisting the state.
window.store = store;
store.subscribe(() => {
  sessionStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

// epicMiddleware.run(rootEpic)
/* eslint-enable */
export default store;

// epicMiddleware.run(rootEpic);

// composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
