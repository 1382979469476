import { INIT_SEARCH, SET_SEARCH, REFRESH_EXPORT_HISTORY } from './actionTypes';

/**
 * Search string actions
 */
export function initSearch(atts) {
  return {
    type: INIT_SEARCH,
    atts
  };
}

export function setSearch(searchStr) {
  return {
    type: SET_SEARCH,
    searchStr
  };
}

export function setRefreshExportHistoryFlag(val) {
  return {
    type: REFRESH_EXPORT_HISTORY,
    val
  };
}
