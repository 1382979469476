import React from 'react';
import Settings from './Settings';
import { Row, Col, Nav, NavItem } from 'react-bootstrap';
import Sorting from './Sorting';
import Requirement from './Requirement';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

let HeaderLinks = () => {
  return (
    <Nav bsStyle="pills" activeKey={1}>
      <NavItem eventKey={1} href="#/login?session=logout">
        <i className="pe-7s-close-circle" />
        Log out
      </NavItem>
    </Nav>
  );
};

class Header extends React.PureComponent {
  render() {
    // console.log('unseenCount', this.props.unseenCount);
    let pathName = this.props.location.pathname;
    let isComparison = true;
    let isSetting = true;
    let isSettingPage = pathName.indexOf('/admin/post-office/settings') === -1;
    let isNotificationPage =
      pathName.indexOf('/admin/post-office/notification') === -1;

    let isComparisonPage =
      pathName.indexOf('/admin/post-office/comparison') === -1;
    if (!isSettingPage) {
      isSetting = false;
    } else {
      isSetting = true;
    }
    if (!isComparisonPage) {
      isComparison = false;
    } else {
      isComparison = true;
    }

    return (
      <div className="po-header">
        <Row>
          <Col xs={3} sm={4} md={6}>
            <Link to="/admin/post-office" className="po-logo">
              <img src="./postoffice.png" alt="post-office" />
            </Link>
          </Col>

          <Col xs={9} sm={8} md={6} className="po-header-rcol">
            <Row>
              <Col xs={6} sm={2} className="sitenaylsis-admin">
                <div className="sitenalysis-admin_con">
                  <Link to="/admin" className="admin-text">
                    Go to Admin{' '}
                  </Link>
                </div>
              </Col>
              <Col xs={6} sm={2} className="po-logout">
                <div className="po-logout-con">
                  <p className="admin-text">SiteNalysis</p>
                  <HeaderLinks />
                </div>
              </Col>

              {/*  {isNotificationPage && <Col xsHidden sm={2} className="po-go-live">
                <Notification />
              </Col>} */}

              {isSetting &&
                (!isNotificationPage || (
                  <Col xs={6} sm={2} className="po-settings">
                    <Settings />
                  </Col>
                ))}

              {isSetting &&
                isComparison &&
                (!isNotificationPage || (
                  <Col xs={6} sm={3} className="po-sorting">
                    <Sorting />
                  </Col>
                ))}

              {isSetting &&
                (!isNotificationPage || (
                  <Col xsHidden sm={3} className="po-requirement">
                    <Requirement />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(Header);
