import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_UPDATE,
} from "./actionTypes";

/*
 * action creators
 */

export function doFetchProducts(atts) {
  return {
    type: FETCH_PRODUCTS_REQUEST,
    atts,
  };
}

export function doFetchProductsFullfilled(payload) {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload,
  };
}
export function doFetchProductsUpdate(payload) {
  return {
    type: FETCH_PRODUCTS_UPDATE,
    payload,
  };
}

export function doFetchProductsError(error) {
  return {
    type: FETCH_PRODUCTS_FAILURE,
    error,
  };
}
