import React from 'react';
import { omni_axios_instance } from 'utils/axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setRefreshExportHistoryFlag } from 'dashboard/actions/common';
class AllExports extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      exportedFiles: []
    };
  }

  componentDidMount() {
    this.props.setRefreshExportHistoryFlag(true);
  }

  componentWillReceiveProps(nextProps) {
    let { eventKey } = nextProps;
    if (eventKey === 'allExports') {
      this.getAllExports();
    }
    /* if (refreshExportHistory) {
      this.getAllExports();
    }
   */
  }

  getAllExports() {
    console.log('getAllExports');
    // let brandId = user.brand._id;
    // let user = Auth.getUser();
    let { retailerId } = this.props;
    omni_axios_instance
      .get(`/getExportHistory/${retailerId}`)
      .then(response => {
        // console.log('response=====> ', response.data);
        this.setState(
          {
            exportedFiles: response.data
          },
          () => {
            this.props.setRefreshExportHistoryFlag(false);
          }
        );
      })
      .catch(error => {
        console.log('error');
      });
  }

  render() {
    let { exportedFiles } = this.state;

    let list = exportedFiles.map((item, index) => {
      return (
        <li key={index}>
          <a href={item.url}>{item.name}</a>
        </li>
      );
    });

    return (
      <div className="all-exports-list">
        <ul>{list}</ul>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setRefreshExportHistoryFlag
    },
    dispatch
  );
};

const mapStateToProps = state => {
  return {
    refreshExportHistory: state.common.refreshExportHistory
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllExports);
