import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import has from 'lodash/has';

import {
  doFetchMonths,
  doFetchMonthsSuccess,
  doFetchMonthsFailure
} from 'dashboard/actions/months';

export default function thunkFetchMonths(action, store) {
  return function(dispatch, getState) {
    dispatch(doFetchMonths());
    let user = Auth.getUser();
    let brandId = user.brand._id;

    let apiUrl = `/RetailerSummaryDays/${brandId}`;
    // Using same api for calling periods for all / single retailer
    if (action && !has(action.rId, 'forAllRetailers')) {
      let { rId } = action;
      if (!rId) {
        const state = getState();
        rId = state.retailer._id;
      }
      apiUrl += `/${rId}`;
    }
    return omni_axios_instance
      .get(apiUrl)
      .then(response => {
        dispatch(doFetchMonthsSuccess(response));
      })
      .catch(error => dispatch(doFetchMonthsFailure(error)));
  };
}

// export default epicFetchMonths;
