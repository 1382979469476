import React from 'react';
import RetailerPopUp from './RetailerPopUp';
import _ from 'lodash';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Truncate from 'react-truncate';

class UserRetailerList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRetailers: [],
      showModal: false
    };
    this.showPopUpModal = this.showPopUpModal.bind(this);
    this.hidePopUpModal = this.hidePopUpModal.bind(this);
  }
  showPopUpModal(e, retailers) {
    e.preventDefault();
    this.setState({
      showModal: true,
      selectedRetailers: retailers
    });
  }
  hidePopUpModal() {
    this.setState({
      showModal: false
    });
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  };

  selectedRetailerList = props => {
    let selectedRetailers = props.retailers || [];
    let retailerList = [];
    if (_.isEmpty(selectedRetailers)) {
      return false;
    }
    _.map(selectedRetailers, (item, key) => {
      return item.flag && retailerList.push(`${item.retailer.name}`);
    });
    let filteredList = retailerList.sort().join(', ');
    return (
      <Truncate
        lines={1}
        width={540}
        ellipsis={
          <span className="ellipsis-con">
            ...
            <Button onClick={e => this.showPopUpModal(e, props)}>
              view more
            </Button>
          </span>
        }
      >
        <span className="retailerText">{`${filteredList}`}</span>
      </Truncate>
    );
  };

  render() {
    let { showModal, selectedRetailers } = this.state;
    let { brands, retailers, getBrands } = this.props;

    const columns = [
      {
        Header: 'Name',
        accessor: 'name', // String-based value accessors!
        className: 'table-cell-edit'
      },
      {
        Header: 'Allowed Retailer',
        accessor: 'retailer', // String-based value accessors!
        minWidth: 200,
        Cell: props => {
          return this.selectedRetailerList(props.original);
        }
      },
      {
        Header: 'Action',
        accessor: 'retailes',
        filterable: false,
        className: 'table-cell-edit',
        Cell: props => (
          <span onClick={e => this.showPopUpModal(e, props.original)}>
            <i className="fa fa-edit" />
          </span>
        )
      }
    ];

    return (
      <div className="container retailer-list">
        <h2 className="text-left">User Retailer List </h2>
        <div className="back-btn-div">
          <Link className="back-btn" to="/admin">
            <i className="fa fa-angle-left" aria-hidden="true" />
            <span>Back</span>
          </Link>
        </div>
        <div className="retailer-users-table">
          <ReactTable
            data={brands}
            columns={columns}
            defaultPageSize={100}
            filterable={true}
            minRows={0}
            showPaginationTop={false}
            defaultFilterMethod={(filter, row) =>
              this.filterCaseInsensitive(filter, row)
            }
            className="-striped"
          />
        </div>

        {showModal && (
          <RetailerPopUp
            show={showModal}
            selectedRetailers={selectedRetailers}
            callback={this.hidePopUpModal}
            getBrand={getBrands}
          />
        )}
      </div>
    );
  }
}

export default UserRetailerList;
