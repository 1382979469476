import React from 'react';
import NotificationList from 'admin/notification-center/NotificationList';
import './notification-center.scss';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class Notification extends React.PureComponent {
  render() {
    let { notification } = this.props;
    return (
      <div className="notification">
        <NotificationList notifications={notification} />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { notification: state.admin.notification.data.data };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Notification)
);
