import React from "react";
import { Grid, Modal } from "react-bootstrap";

export default function ImageModal(props) {
  let { show, handleImageModal, url } = props;
  return (
    <div className="modal-container">
      <Modal
        animation={false}
        autoFocus={false}
        enforceFocus={false}
        className="ImageModal"
        show={show}
        onHide={() => {
          handleImageModal(false, "");
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div>
            <img src={url} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
