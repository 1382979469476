import {
  FETCH_CONTACTINFO_REQUEST,
  FETCH_CONTACTINFO_SUCCESS,
  FETCH_CONTACTINFO_FAILURE
} from './actionTypes';

// Contact list

export function doFetchContactInfo(atts) {
  return {
    type: FETCH_CONTACTINFO_REQUEST,
    atts
  };
}

export function doFetchContactInfoSuccess(payload) {
  return {
    type: FETCH_CONTACTINFO_SUCCESS,
    payload
  };
}

export function doFetchContactInfoFailure(error) {
  return {
    type: FETCH_CONTACTINFO_FAILURE,
    error
  };
}
