import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ProductScreen from 'dashboard/components/ProductScreen/ProductScreen';
import ProductDrillDown from 'dashboard/components/ProductDrillDown/ProductDrillDown.jsx';
import Helpers from 'utils/helpers';
import thunkFetchMonths from 'dashboard/thunks/thunkFetchMonths';
import thunkFetchRetailer from 'dashboard/thunks/thunkFetchRetailer';
import thunkFetchProduct from 'dashboard/thunks/thunkFetchProduct';
import thunkFetchContactInfo from 'dashboard/thunks/thunkFetchContactInfo';
import isEmpty from 'lodash/isEmpty';
import './dashboard.scss';
import _ from 'lodash';
import ProductPreviousVersions from 'dashboard/components/ProductDrillDown/ProductPreviousVersions';
class RetailerProduct extends React.PureComponent {
  componentDidMount() {
    const { rId: retailerId, pId: productId } = this.props.match.params;
    this.props.doFetchRetailer(retailerId);
    this.props.doFetchProduct(productId);
    Helpers.scrollToTop();
  }

  componentDidUpdate(prevProps, prevState) {
    Helpers.scrollToTop();
  }
  componentWillReceiveProps(nextProps) {
    let { rId, pId } = nextProps.match.params;

    if (pId !== this.props.match.params.pId) {
      this.props.doFetchRetailer(rId);
      this.props.doFetchProduct(pId);
    }

    if (!_.isEmpty(nextProps.retailer.retailer)) {
      let rId = nextProps.retailer.retailer;
      this.props.doFetchMonths({
        rId
      });
    }

    return;
  }

  render() {
    let { product, retailer } = this.props;
    let content = '';
    if (isEmpty(product)) {
      content = (
        <div className="Dashboard-Loader">
          <i className="fa fa-spin fa-spinner fa-3x fa-fw" />
        </div>
      );
    } else {
      content = (
        <div>
          <ProductScreen />
          <ProductDrillDown retailer={retailer} />
          <ProductPreviousVersions retailer={retailer} />
        </div>
      );
    }

    return (
      <div id="RetailerProductView" className="porduct-page">
        {content}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    retailer: state.retailer,
    product: state.product
  };
};

/* const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      doFetchProduct,
      doFetchRetailer,
      doFetchContactInfo,
      doFetchMonths
    },
    dispatch
  );
}; */

const mapDispatchToProps = dispatch => {
  return {
    doFetchProduct: value => {
      dispatch(thunkFetchProduct(value));
    },
    doFetchRetailer: rId => {
      dispatch(thunkFetchRetailer(rId));
    },
    doFetchContactInfo: value => dispatch(thunkFetchContactInfo()),
    doFetchMonths: value => {
      dispatch(thunkFetchMonths(value));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RetailerProduct)
);
