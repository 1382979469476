import React from 'react';
import validator from 'validator';
import uniqid from 'uniqid';

// Form field components
export const renderInputField = props => {
  let {
    input,
    label,
    type,
    disabled,
    appendLabel,
    placeholder,
    componentClass,
    fieldClass,
    meta: { touched, error, warning }
  } = props;

  let fieldId = uniqid('field-');
  let labelHtml = '';
  if (label) {
    labelHtml = <label htmlFor={fieldId}>{label}</label>;
  }

  return (
    <div className={componentClass}>
      {!appendLabel && labelHtml}
      <input
        {...input}
        disabled={disabled}
        id={fieldId}
        className={fieldClass}
        placeholder={placeholder}
        type={type}
      />
      {appendLabel && labelHtml}
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
};
// Form field components
export const renderFileInputField = props => {
  let {
    input,
    label,
    type,
    disabled,
    appendLabel,
    placeholder,
    value,
    accept,
    componentClass,
    fieldClass,
    meta: { touched, error, warning }
  } = props;

  let fieldId = uniqid('field-');
  let labelHtml = '';
  if (label) {
    labelHtml = <label htmlFor={fieldId}>{label}</label>;
  }

  return (
    <div className={componentClass}>
      {!appendLabel && labelHtml}
      <input
        {...input}
        disabled={disabled}
        id={fieldId}
        value={value}
        className={fieldClass}
        placeholder={placeholder}
        accept={accept}
        type={type}
      />
      {appendLabel && labelHtml}
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
};

export const renderTextArea = props => {
  let {
    input,
    type,
    disabled,
    placeholder,
    componentClass,
    fieldClass,
    meta: { touched, error, warning }
  } = props;

  let textAreaId = uniqid('field-');

  return (
    <div className={componentClass}>
      <textarea
        rows="4"
        cols="50"
        {...input}
        disabled={disabled}
        id={textAreaId}
        className={fieldClass}
        placeholder={placeholder}
        type={type}
      />
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
};

// Validators
export const required = value => {
  if (!value) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return <div className="form-error is-visible">This field is required.</div>;
  }
};

export const email = value => {
  if (!validator.isEmail(value)) {
    return (
      <div className="form-error is-visible">{value} is not a valid email.</div>
    );
  }
};

export const password = value => {
  if (value && value.length < 5) {
    return <div className="form-error is-visible">Password is too short!</div>;
  }
};
