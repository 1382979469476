import {
  SET_ACTIVE_PRODUCT,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE
} from './actionTypes';

/*
 * action creators
 */

export function setActiveProduct(product) {
  return {
    type: SET_ACTIVE_PRODUCT,
    product
  };
}

export function doFetchProduct(pId) {
  return {
    type: FETCH_PRODUCT_REQUEST,
    pId
  };
}

export function doFetchProductFullfilled(payload) {
  return {
    type: FETCH_PRODUCT_SUCCESS,
    payload
  };
}

export function doFetchProductError(error) {
  return {
    type: FETCH_PRODUCT_FAILURE,
    error
  };
}
