import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';

import {
  doFetchRetailersList,
  doFetchRetailersListSuccess,
  doFetchRetailersListFailure
} from 'dashboard/actions/retailers';

// To get Retailer Scrappig List by month & brand
// RetailerScraping/:brandId?month=<mm-yyyy>
// brandId >> you'll get from user.brand._id

export default function thunkFetchRetailersList(upcomingAtts = {}) {
  return function(dispatch, getState) {
    dispatch(doFetchRetailersList());
    let user = Auth.getUser();
    let brandId = user.brand._id;

    return omni_axios_instance
      .get(`/getRetailers/${brandId}`)
      .then(response => {
        dispatch(doFetchRetailersListSuccess(response.data));
      })
      .catch(error => dispatch(doFetchRetailersListFailure(error.xhr)));
  };
}
