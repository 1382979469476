import {
  SET_ACTIVE_MONTH,
  FETCH_MONTH_REQUEST,
  FETCH_MONTH_SUCCESS,
  FETCH_MONTH_FAILURE
} from './actionTypes';

/*
 * action creators
 */
export function setActiveMonth(period) {
  return {
    type: SET_ACTIVE_MONTH,
    period
  };
}

export function doFetchMonth(rId) {
  return {
    type: FETCH_MONTH_REQUEST,
    rId
  };
}

export function doFetchMonthSuccess(payload) {
  return {
    type: FETCH_MONTH_SUCCESS,
    payload
  };
}

export function doFetchMonthError(error) {
  return {
    type: FETCH_MONTH_FAILURE,
    error
  };
}
