import axios from 'axios';
import { OMNI_API_URL } from './constants';
import NProgress from 'nprogress';
import { toastr } from 'react-redux-toastr';
import Auth from 'utils/auth';

// Set config defaults when creating the instance
export const omni_axios_instance = axios.create({
  baseURL: OMNI_API_URL
});

/************************************************
 * interceptors
 ************************************************/

// Add a request interceptor
omni_axios_instance.interceptors.request.use(
  config => {
    let AUTH_TOKEN = Auth.getAccessToken();
    config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
    // Do something before request is sent
    NProgress.start();
    return config;
  },
  error => {
    // https://github.com/axios/axios#handling-errors
    if (error.request) {
      toastr.error('ERROR', error.request);
    }
    // Do something with request error
    NProgress.done();
    return Promise.reject(error);
  }
);

// Add a response interceptor
omni_axios_instance.interceptors.response.use(
  response => {
    // Do something with response data
    NProgress.done();
    return response;
  },
  error => {
    // Do something with response error
    NProgress.done();
    const { response } = error;

    if (!response) {
      toastr.error('ERROR', error.message);
      return Promise.reject(error);
    }
    if (response.status === 401) {
      setTimeout(() => {
        let { origin } = window.location;
        window.location.href = origin + '/#/login?session=expired';
      });
      return;
    }
    // Handle errors
    if (response.data) {
      let data = response.data;
      if (data.message) {
        toastr.warning(data.message);
      } else if (data.errors) {
        toastr.error('ERROR', data.errors.toString());
      } else if (typeof data === 'string') {
        toastr.warning(data);
      } else {
        toastr.error('ERROR', 'Something went wrong, please try again!');
      }
    }
    return Promise.reject(error);
  }
);
