import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import Card from 'dashboard/components/Card/Card.jsx';
import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';
import isEmpty from 'lodash/isEmpty';
import AddEditMessageModal from './AddEditMessageModal';
import ViewMessageModal from './ViewMessageModal';
import InboxPagination from './InboxPagination';

// let _doRefreshMessages = false;

const Messages = () => {
  const user = Auth.getUser();
  const [state, setState] = useState({
    messages: [],
    totalCount: 0,
    loading: false,
    doRefreshMessages: false,
    showAddEditModal: {
      isOpen: false,
      mode: 'add'
    },
    showViewModal: false,
    message: {},
    filters: {
      page: 1,
      limit: 10,
      sort: 'name;asc'
    }
  });
  let brandId = '';
  if (!isEmpty(user)) {
    brandId = user.brand._id;
  }
  let {
    messages,
    totalCount,
    loading,
    showAddEditModal,
    showViewModal,
    message,
    filters,
    doRefreshMessages
  } = state;
  async function getData(newAtts) {
    setState({
      ...state,
      loading: true
    });
    try {
      const result = await omni_axios_instance.get(
        `/cannedmessages?brand=${brandId}&page=${newAtts.page}&limit=${
          newAtts.limit
        }&sort=${newAtts.sort}`
      );
      let data = result.data.data;
      setState({
        ...state,
        messages: data,
        totalCount: result.data.totalCount,
        doRefreshMessages: false,
        loading: false
      });
    } catch (error) {
      setState({
        ...state,
        loading: false,
        doRefreshMessages: false
      });
      console.error(error);
    }
    // doRefreshMessages = false;
  }

  useEffect(() => {
    // console.log("TCL: doRefreshMessages", doRefreshMessages)
    getData(filters);
  }, [filters, doRefreshMessages]);

  const editMessage = mesg => {
    let statusObj = {};
    if (mesg.status === 'active') {
      statusObj = { value: 'active', label: 'Active' };
    } else {
      statusObj = { value: 'inactive', label: 'Inactive' };
    }
    let newMesg = {
      _id: mesg._id,
      name: mesg.name,
      content: mesg.content,
      brand: mesg.brand._id,
      status: statusObj
    };
    setState({
      ...state,
      showAddEditModal: {
        isOpen: !state.showAddEditModal.isOpen,
        mode: 'edit'
      },
      message: newMesg
    });
  };

  const handleAddEditModalHide = () => {
    setState({
      ...state,
      doRefreshMessages: true,
      showAddEditModal: {
        isOpen: !state.showAddEditModal.isOpen,
        mode: 'add'
      }
    });
    // doRefreshMessages = true;
  };

  const queryUpdate = params => {
    var activePage = params.activePage;
    let newFilters = { ...filters, page: activePage };
    setState({ ...state, filters: newFilters });
  };

  const viewMessage = mesg => {
    let newMesg = {
      name: mesg.name,
      content: mesg.content,
      brand: mesg.brand._id,
      status: mesg.status
    };
    setState({ ...state, showViewModal: true, message: newMesg });
  };

  const handleViewModalHide = () => {
    setState({ ...state, showViewModal: false });
  };

  let tableBody;
  if (loading) {
    tableBody = (
      <tbody>
        <tr>
          <td
            className="text-center"
            colSpan={4}
            style={{
              padding: '20% 0'
            }}
          >
            <div className="messages-table__body--loader-image">
              <i className="fa fa-spin fa-spinner fa-2x fa-fw" />
            </div>
            <div className="messages-table__body--loader-text">
              Loading list, please wait...
            </div>
          </td>
        </tr>
      </tbody>
    );
  } else {
    tableBody = (
      <tbody className="messages-table__body">
        {messages.map((prop, key) => {
          let status = prop.status === 'inactive' ? 'in active' : prop.status;
          return (
            <tr key={key} className="messages-table__row">
              <td className="message-name">{prop.name}</td>
              <td className="message-status">{status}</td>
              <td className="actions-btns">
                <Button
                  bsStyle="default"
                  className="view-btn"
                  onClick={e => viewMessage(prop)}
                >
                  View
                </Button>
                <Button
                  bsStyle="default"
                  className="edit-btn"
                  onClick={e => editMessage(prop)}
                >
                  Edit
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
  let cardHeader = (
    <>
      Inbox <span>(view/edit canned messages)</span>
    </>
  );
  let htmlContent = (
    <>
      <Card
        title={cardHeader}
        className="card"
        text={
          <Button
            bsStyle="default"
            className="card__btn"
            onClick={handleAddEditModalHide}
          >
            Add New Message
          </Button>
        }
        tableFullWidth
        content={
          <Table striped hover responsive className="messages-table">
            <thead className="messages-table__header">
              <tr className="messages-table__row">
                <th className="message-header">
                  <div className="d-flex">
                    <span>Canned Message Name</span>
                  </div>
                </th>
                <th className="status-header">
                  <div className="d-flex">
                    <span>Status</span>
                  </div>
                </th>
                <th className="actions-header">
                  <div className="d-flex justify-content-center">
                    <span>Actions</span>
                  </div>
                </th>
              </tr>
            </thead>
            {tableBody}
          </Table>
        }
      />
      <InboxPagination
        totalCount={totalCount}
        itemsPerPage={filters.limit}
        callback={queryUpdate}
      />
      <AddEditMessageModal
        show={showAddEditModal.isOpen}
        mode={showAddEditModal.mode}
        message={message}
        onHide={handleAddEditModalHide}
      />
      <ViewMessageModal
        show={showViewModal}
        message={message}
        onHide={handleViewModalHide}
      />
    </>
  );
  return htmlContent;
};
export default Messages;
