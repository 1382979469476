import React from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Row, Col, Nav, NavItem, Tab } from "react-bootstrap";
import _ from "lodash";
import Card from "dashboard/components/Card/Card.jsx";
import EmailHistory from "./EmailHistory.jsx";
import AllExports from "./AllExports.jsx";
import ContactInfo from "./ContactInfo.jsx";
import ImageLoader from "dashboard/components/ImageLoader";
import FlagBrandModal from "../components/FlagBrandModal";
import confirm from "../components/confirm";
import Helpers from "utils/helpers";
import ImageModal from "../components/ImageModal.js";

class RetailerIntro extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.filterOptionsData = this.filterOptionsData.bind(this);
    this.toggleBrandFlagModal = this.toggleBrandFlagModal.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.handleImageModal = this.handleImageModal.bind(this);
    this.state = {
      singleSelect: null,
      eventKey: "details",
      retailer_contacts: [],
      showModal: false,
      isFlagged: props.retailerScrappingInfo.isFlagged || false,
      showImageModal: false,
      imageUrl: "",
    };
  }
  handleImageModal(value, url) {
    this.setState({ showImageModal: value, imageUrl: url });
  }

  toggleBrandFlagModal() {
    let { retailerScrappingInfo } = this.props;
    let { isFlagged } = this.state;
    let formData = {
      reason: [],
      isFlagged: "no",
    };
    if (isFlagged) {
      confirm("Are you sure you want to un flag this product ?").then(
        (result) => {
          Helpers.setBrandFlag(formData, retailerScrappingInfo._id);
          this.setState({
            isFlagged: false,
          });
        },
        (result) => {
          // `cancel` callback
        }
      );
    } else {
      // otherwise show modal
      this.setState({
        showModal: true,
      });
    }
  }

  onModalClose(params = {}) {
    this.setState({
      showModal: false,
      isFlagged: params.isFlagged || false,
    });
    //refresh page call api if isFlagged == true
  }

  filterOptionsData(item) {
    let content = "";
    if (
      item.brand_retailer &&
      item.brand_retailer.webSiteType === "retailerWithoutProducts"
    ) {
      content =
        "This Retailer does not feature any product pages or a module on their website";
    } else if (
      item.brand_retailer &&
      item.brand_retailer.webSiteType === "retailerWithModule"
    ) {
      content = `This Retailer uses a Module to display ${item.brand_name} items on their website`;
    } else {
      content = "";
    }
    return content;
  }
  createDrillDown() {
    let { retailerScrappingInfo, months, selectedPeriod } = this.props;
    // let retailerId = retailerScrappingInfo._id;
    let { isFlagged } = this.state;
    let textClass =
      retailerScrappingInfo.totalProducts >=
      retailerScrappingInfo.previousTotalProducts
        ? "text-success"
        : "text-danger";
    let iconClasses = "";
    if (textClass === "text-danger") {
      iconClasses = "fa fa-caret-down";
    } else {
      iconClasses = "fa fa-caret-up";
    }

    let classes = "";
    if (isFlagged) {
      classes = "fa fa-flag flag-marked fa-lg";
    } else {
      classes = "fa fa-flag flag-unmarked fa-lg";
    }

    let nextscan = Helpers.getNextScan(
      months,
      selectedPeriod._id,
      retailerScrappingInfo.nextScanDay
    );

    let timeZone = new Date().getTimezoneOffset();
    // console.log(retailerScrappingInfo.period,'retailerScrappingInfo.period',timeZone);

    return (
      <div className="retailer-intro-tab">
        <div className="drill-props">
          <span className="">
            {this.filterOptionsData(retailerScrappingInfo)}
          </span>
        </div>
        <div className="drill-props">
          <label>Total Products: </label>
          <div>
            {retailerScrappingInfo.totalProducts}
            <span className={textClass}>
              <i className={iconClasses} />
            </span>
            <span className="stats">
              {`(${retailerScrappingInfo.previousTotalProducts} last scan)`}
            </span>
          </div>
        </div>

        <div className="drill-props">
          <label>Last Scan (current period): </label>
          <div>
            {retailerScrappingInfo.approvedDate ? (
              <span>
                {moment(new Date(`${retailerScrappingInfo.approvedDate}`))
                  .add(timeZone, "minutes")
                  .format("MMMM DD, YYYY")}
              </span>
            ) : (
              <span>N/A</span>
            )}
          </div>
        </div>
        <div className="drill-props">
          <label>Next Scan: </label>
          <div> {nextscan}</div>
        </div>
        {/* <div className="drill-props">
          <label>Featured on homepage? </label>
          <div className="text-caps">
            {retailerScrappingInfo.featuredOnHomepage}{' '}
          </div>
        </div> */}
        <div className="drill-props">
          <label>Featured in Menu? </label>
          <div className="text-caps">
            {retailerScrappingInfo.featuredInMenu}{" "}
          </div>
        </div>
        <div className="drill-props">
          <label>Site Link: </label>
          <div>
            {retailerScrappingInfo.siteLink && (
              <a
                href={retailerScrappingInfo.siteLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {retailerScrappingInfo.siteLink}
              </a>
            )}
          </div>
        </div>
        <div className="drill-props">
          <label>Brand Landing Page: </label>
          <div className="brand-link">
            <a
              href={retailerScrappingInfo.brandLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {retailerScrappingInfo.brandLink}
            </a>
          </div>
        </div>
        <div className="drill-props">
          <label>
            Brand Description:{" "}
            <span
              data-tip={
                "To report an issue with the Brand Description click the flag."
              }
              onClick={this.toggleBrandFlagModal}
            >
              <i className={classes} aria-hidden="true" />
            </span>
          </label>
          <div
            dangerouslySetInnerHTML={{
              __html: retailerScrappingInfo.brand_description
                ? retailerScrappingInfo.brand_description
                : "N/A",
            }}
          />
        </div>
      </div>
    );
  }

  handleSelect(eventKey) {
    this.setState({
      eventKey,
    });
  }

  render() {
    let { retailerScrappingInfo } = this.props;
    let retailerId = retailerScrappingInfo._id;
    let retailer = retailerScrappingInfo.retailer;
    if (_.isEmpty(retailerScrappingInfo)) {
      return <div />;
    }

    let { eventKey, isFlagged, showModal, showImageModal, imageUrl } =
      this.state;

    const tabs = (
      <Tab.Container
        id="tabs-with-dropdown"
        activeKey={eventKey}
        onSelect={this.handleSelect}
      >
        <Row className="clearfix">
          <Col sm={12}>
            <Nav bsStyle="tabs">
              <NavItem eventKey="details">
                <h4>DETAILS</h4>
              </NavItem>
              <NavItem eventKey="contactInfo">
                <h4>Address Book</h4>
              </NavItem>
              <NavItem eventKey="emailHistory" data-product="data-product">
                <h4>EMAIL HISTORY</h4>
              </NavItem>
              <NavItem eventKey="allExports">
                <h4>ALL EXPORTS</h4>
              </NavItem>
            </Nav>
          </Col>
          <Col sm={12} className="content">
            <Tab.Content animation={false}>
              <Tab.Pane eventKey="details">
                <Card ctFullWidth content={this.createDrillDown()} />
              </Tab.Pane>
              <Tab.Pane eventKey="contactInfo">
                <Card
                  ctFullWidth
                  content={<ContactInfo retailer={retailer} />}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="emailHistory">
                <Card
                  ctFullWidth
                  content={
                    <EmailHistory retailerId={retailerId} eventKey={eventKey} />
                  }
                />
              </Tab.Pane>
              <Tab.Pane eventKey="allExports">
                <Card
                  ctFullWidth
                  content={
                    <AllExports retailerId={retailerId} eventKey={eventKey} />
                  }
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );

    var additionalView = false;

    if (
      !_.isEmpty(
        retailerScrappingInfo.brand_logo ||
          retailerScrappingInfo.brand_hero_image ||
          retailerScrappingInfo.brand_screenshot
      )
    ) {
      additionalView = true;
    }

    let classes = "";
    if (isFlagged) {
      classes = "fa fa-flag flag-marked fa-lg";
    } else {
      classes = "fa fa-flag flag-unmarked fa-lg";
    }

    return (
      <div className="retailer-intro">
        <Row>
          <Col md={4}>
            <div className="text-center">
              Brand Landing Page
              <span
                data-tip={
                  "To report an issue with the Brand Landing Page click the flag."
                }
                onClick={this.toggleBrandFlagModal}
              >
                <i className={classes} aria-hidden="true" />
              </span>
            </div>
            <div className="Thumbnail-Image">
              {retailerScrappingInfo.brand_screenshot && (
                <ImageLoader
                  isBackground
                  image={retailerScrappingInfo.brand_screenshot}
                  imageLarge={retailerScrappingInfo.brand_screenshot_large}
                  handleImageModal={this.handleImageModal}
                />
              )}
            </div>
            {additionalView && (
              <p className="Image-Caption text-center">Additional Views</p>
            )}
            <div className="Polaroid-Images">
              {retailerScrappingInfo.brand_logo && (
                <ImageLoader
                  image={retailerScrappingInfo.brand_logo}
                  isBackground
                  containBg
                  zoomable={false}
                  additionalView
                  handleImageModal={this.handleImageModal}
                />
              )}
              {retailerScrappingInfo.brand_hero_image && (
                <ImageLoader
                  image={retailerScrappingInfo.brand_hero_image}
                  isBackground
                  containBg
                  zoomable={false}
                  additionalView
                  handleImageModal={this.handleImageModal}
                />
              )}
              {retailerScrappingInfo.brand_screenshot && (
                <ImageLoader
                  image={retailerScrappingInfo.brand_screenshot}
                  isBackground
                  containBg
                  zoomable={false}
                  additionalView
                  handleImageModal={this.handleImageModal}
                />
              )}
            </div>
          </Col>
          <Col md={8} className="retailer-info">
            {tabs}
          </Col>
        </Row>

        {showModal && (
          <FlagBrandModal
            isFlagged={isFlagged}
            flagFor={"brandMarketingVisuals"}
            retailerBrandScrappingid={retailerId}
            callback={this.onModalClose}
          />
        )}
        <ReactTooltip
          className="omni__tooltip omni__tooltip--right"
          place="right"
          type="light"
          effect="solid"
        />
        {showImageModal && (
          <ImageModal
            show={showImageModal}
            url={imageUrl}
            handleImageModal={this.handleImageModal}
          />
        )}
      </div>
    );
  }
}

export default RetailerIntro;
