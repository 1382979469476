import { omni_axios_instance } from 'utils/axios';
import Auth from 'utils/auth';

import {
  doFetchContactInfo,
  doFetchContactInfoSuccess,
  doFetchContactInfoFailure
} from 'dashboard/actions/contactInfo';

export default function thunkFetchContactInfo(action) {
  return function(dispatch, getState) {
    dispatch(doFetchContactInfo());
    let user = Auth.getUser();
    let brandId = user.brand._id;
    const state = getState();
    let { retailer, _id } = state.retailer;
    let retailerId = retailer || _id;

    if(!retailerId) return;

    return omni_axios_instance
      .get(`/getContactInfo?brand=${brandId}&retailer=${retailerId}`)
      .then(response => {
        dispatch(doFetchContactInfoSuccess(response));
      })
      .catch(error => dispatch(doFetchContactInfoFailure(error.xhr)));
  };
}
