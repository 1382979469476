import React from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import Card from 'dashboard/components/Card/Card.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import WhyToFlagModal from 'dashboard/components/WhyToFlagModal';
import RetailerContactModal from 'dashboard/components/RetailerContactModal';
import Helpers from 'utils/helpers';
import { withRouter } from 'react-router';
import queryString from 'querystringify';
import confirm from 'dashboard/components/confirm';
import thunkFetchProduct from 'dashboard/thunks/thunkFetchProduct';
import thunkFetchContactInfo from 'dashboard/thunks/thunkFetchContactInfo';

class ProductDrillDown extends React.PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    let { product } = this.props;
    this.state = {
      showWhyToFlagModal: false,
      showRetailerContactModal: false,
      isFlagged: product.isFlagged,
      reasonsToFlag: []
    };
    this.toggleFlagProduct = this.toggleFlagProduct.bind(this);
    this.onWhyToFlagModalClose = this.onWhyToFlagModalClose.bind(this);
    this.openRetailerContactModal = this.openRetailerContactModal.bind(this);
    this.onRetailerContactModalClose = this.onRetailerContactModalClose.bind(
      this
    );
  }

  componentDidMount() {
    let q = queryString.parse(this.props.location.search);
    if (q.contact) {
      this.setState({
        showRetailerContactModal: true
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let { retailer } = nextProps.retailer;
    if (!_.isEmpty(retailer)) {
      this.props.doFetchContactInfo();
    }
    return;
  }

  createProductDrillDown() {
    let { product, retailer } = this.props;
    if (_.isEmpty(product)) {
      return <div />;
    }

    if (_.isEmpty(retailer)) {
      return null;
    }
    let retailerName = retailer.name;
    // let lastScan = retailer.previousPeriod;
    let lastScan = retailer.approvedDate;
    if (_.isObject(product.retailer_brand_scraping)) {
      retailerName = product.retailer_brand_scraping.retailer.name;
    }

    let drillContent = Helpers.getDrillDown(
      product,
      retailerName,
      false,
      lastScan
    );

    return (
      <div>
        <h4 className="title">PRODUCT DETAILS :</h4>
        {drillContent}
      </div>
    );
  }

  onWhyToFlagModalClose(params) {
    this.setState({
      showWhyToFlagModal: false,
      isFlagged: params && params.isFlagged,
      reasonsToFlag: params && params.reasonsToFlag
    });
  }

  openRetailerContactModal() {
    this.setState({
      showRetailerContactModal: true
    });
  }

  onRetailerContactModalClose() {
    this.setState({
      showRetailerContactModal: false
    });
  }

  toggleFlagProduct() {
    let { product } = this.props;
    let { isFlagged } = this.state;

    if (isFlagged) {
      confirm('Are you sure you want to un flag this product ?').then(
        result => {
          // `proceed` callback
          Helpers.setFlaggedProduct({ isFlagged: 'no' }, product._id).then(
            () => {
              this.props.doFetchProduct(product._id);
            }
          );
          this.setState({
            isFlagged: false
          });
        }
      );
    } else {
      // otherwise show modal
      this.setState({
        showWhyToFlagModal: true
      });
    }
  }

  productFlag() {
    let { product } = this.props;
    let { isFlagged } = this.state;

    let flaggedReason = '';
    let flagIconClasses = 'fa fa-flag flag-unmarked fa-lg';
    let flagIconText = 'Flag this product';

    if (isFlagged) {
      flagIconClasses = 'fa fa-flag flag-marked fa-lg';
      flagIconText = 'Unflag this product';
      // Flagged msg/reasons
      let additionalDetails = Helpers.getFlaggedReason(product);
      flaggedReason = (
        <div
          className="flagged-reason"
          dangerouslySetInnerHTML={{ __html: additionalDetails }}
        />
      );
    }
    return (
      <div>
        <h4 className="title">FLAG REASON: </h4>
        {flaggedReason}
        <div className="flag-product-btn-container">
          <Button
            bsStyle="default"
            className="product-flag"
            onClick={this.toggleFlagProduct}
          >
            {flagIconText}
            <i className={flagIconClasses} />
          </Button>
        </div>
        <div className="contact-retailer-btn-container">
          <Button
            bsStyle="default"
            wd
            className="contact-retailer-btn"
            onClick={this.openRetailerContactModal}
          >
            Contact Retailer
          </Button>
        </div>
      </div>
    );
  }

  render() {
    let { product, retailer } = this.props;
    let { showWhyToFlagModal, showRetailerContactModal } = this.state;

    return (
      <div className="drill-down-parent">
        <Row className="product-drilldown">
          <Col md={12}>
            <Card
              content={
                <Row>
                  <Col md={8} xs={12}>
                    {this.createProductDrillDown()}
                  </Col>
                  <Col md={4} xs={12}>
                    {this.productFlag()}
                  </Col>
                </Row>
              }
            />
            {showWhyToFlagModal && (
              <WhyToFlagModal
                rId={retailer._id}
                pId={product._id}
                productName={product.name}
                callback={this.onWhyToFlagModalClose}
              />
            )}

            {showRetailerContactModal && (
              <RetailerContactModal
                retailer={retailer._id}
                pId={product._id}
                show={showRetailerContactModal}
                callback={this.onRetailerContactModalClose}
                source={'product'}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

/* const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      doFetchContactInfo,
      doFetchProduct
    },
    dispatch
  );
};
 */
const mapDispatchToProps = dispatch => {
  return {
    doFetchContactInfo: value => dispatch(thunkFetchContactInfo()),
    doFetchProduct: value => {
      dispatch(thunkFetchProduct(value));
    }
  };
};

const mapStateToProps = state => {
  return {
    retailer: state.retailer,
    product: state.product
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductDrillDown)
);
