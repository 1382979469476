import React from 'react';
import { Link } from 'react-router-dom';

class Banner extends React.PureComponent {
  render() {
    let imgUrl = `${process.env.PUBLIC_URL}/website-images/TopZone-bg2.jpg`;
    const isMobile = window.innerWidth <= 768;
    let divStyle = {};
    if (isMobile) {
      divStyle = {
        backgroundImage:
          'linear-gradient(to bottom, rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.7) 100%), url(' +
          imgUrl +
          ')',
        backgroundSize: 'cover'
      };
    } else {
      divStyle = {
        backgroundImage: 'url(' + imgUrl + ')',
        backgroundSize: 'cover'
      };
    }

    return (
      <div id="banner" className="container-fluid" style={divStyle}>
        <div className="banner__wrap">
          <div className="banner__con">
            <div className="banner__img-wrapper">
              <img
                src={`${process.env.PUBLIC_URL}/siteNalysis_Logo.png`}
                alt="SiteNalysis"
              />
            </div>

            <div className="banner__text">
              <p>
                <span>MONITOR.</span>
                <span>AUDIT.</span>
                <span>PROTECT.</span>
              </p>
              <div className="banner__spacer" />
              {/*  <h2>THE ULTIMATE BRAND</h2>
            <h2>MANAGEMENT TOOL</h2> */}
              <h2>RETAILER WEBSITE MONITORING </h2>
              <h2>TOOLS FOR LUXURY BRANDS</h2>
            </div>

            <div className="banner__demo-btns">
              <div className="request__con">
                <Link to="/?to=demo" className="btn btn-blue btn-block">
                  REQUEST A DEMO
                </Link>
              </div>
              <div className="learn__con">
                <Link
                  to="/?to=about"
                  className="btn btn-blue-inverse btn-block"
                >
                  LEARN MORE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
