import React from 'react';
import { Pagination } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import doFetchNotificationData from 'admin/thunks/thunkNotificationData';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class NotificationPagination extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: Number(this.props.page) || 1
    };
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // This will help to sync store and local state
    if (nextProps.page) {
      this.setState({
        activePage: nextProps.page
      });
    }
  }

  pagination(activePage) {
    this.props.doFetchNotificationData({ page: activePage });
    // IS_PAGINATION_FLAG: true // Needed in reducer fn `products`
  }

  handlePagination(e) {
    let page = e.target.text || e.target.textContent;
    let { itemsPerPage, totalCount } = this.props;

    const totalPages = Math.ceil(totalCount / itemsPerPage);
    let map = {
      first: 1,
      last: totalPages,
      lastButtonText: 'Last',
      firstButtonText: 'First'
    };
    // use map later
    if (page === map.firstButtonText) {
      page = map.first;
    } else if (page === map.lastButtonText) {
      page = map.last;
    }
    const activePage = Number(page);
    this.setState(
      {
        activePage
      },
      this.pagination(activePage)
    );
  }

  render() {
    let { itemsPerPage, totalCount } = this.props;
    // let contactBtn = "";
    const totalPages = Math.ceil(totalCount / itemsPerPage);
    // If 1 page, then hide
    if (totalPages < 2) {
      return null;
    }

    return (
      <div className="Pagination-Container">
        <Row>
          <Col sm={2}>
            <div className="back-btn-div">
              <Link to="/admin" className="backBtn">
                <i className="fa fa-angle-left" aria-hidden="true" />
                <span>Back</span>
              </Link>
            </div>
          </Col>
          <Col sm={10}>
            <Pagination
              first={totalPages > 1 ? 'First' : false}
              last={totalPages > 1 ? 'Last' : false}
              items={totalPages}
              maxButtons={10}
              boundaryLinks={true}
              ellipsis={true}
              activePage={this.state.activePage}
              onClick={e => {
                if (e.target.classList.contains('disabled')) {
                  return false;
                }
                this.handlePagination(e);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    itemsPerPage: state.admin.notification.filters.limit,
    totalCount: state.admin.notification.data.totalCount,
    page: state.admin.notification.filters.page
  };
};

/* const mapDispatchToProps = dispatch => {
  return {
    doFetchNotificationData: (value) => {
        dispatch(doFetchNotificationData());
      }
  }
}; */

const mapDispatchToProps = dispatch => {
  return {
    doFetchNotificationData: page => {
      dispatch(doFetchNotificationData({ atts: page }));
    }
  };
};

NotificationPagination.propTypes = {
  activePage: PropTypes.number
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationPagination);
