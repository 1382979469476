import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { setActiveRetailer } from "dashboard/actions/retailer";
import { withRouter } from "react-router-dom";
import thunkFetchRetailersList from "dashboard/thunks/thunkFetchRetailers";
import { setActiveMonth } from "dashboard/actions/month";
import thunkFetchMonths from "dashboard/thunks/thunkFetchMonths";
import { matchPath } from "react-router";
import uniqid from "uniqid";

class RetailerSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialRendering: true,
    };
    this.onRetailerSelectChange = this.onRetailerSelectChange.bind(this);
  }

  selectRetailer(retailers, rId) {
    if (isEmpty(retailers)) {
      return;
    }
    let selectedRetailer = find(retailers, {
      _id: rId,
    });
    if (!selectedRetailer) {
      return;
    }
    this.props.setActiveRetailer(selectedRetailer);
    // Get months belogs to selected retailer
    // console.log("rId =>", rId);
    this.props.doFetchMonths(rId);
  }

  onRetailerSelectChange(event) {
    let { retailers } = this.props;
    let value = event.target.value;
    // On retailer change, we have to empty month from redux store
    // Means default selection with value '0'
    if (!value) {
      this.selectRetailer(retailers, value);
      return;
    }
    this.selectRetailer(retailers, value);
    this.props.setActiveMonth({});
    // Push new url
    const path = `/dashboard/retailer/${value}`;
    this.props.history.push(path);
  }

  componentDidMount() {
    // get retailers
    this.props.setActiveRetailer({});
    this.props.doFetchRetailersList();
  }

  componentWillReceiveProps(nextProps) {
    let { retailers } = nextProps;
    if (isEmpty(retailers)) {
      return;
    }
    let { initialRendering } = this.state;
    if (!initialRendering) {
      return;
    }
    // This lets you use the same matching code that <Route> uses except
    // outside of the normal render cycle,
    // like gathering up data dependencies before rendering on the server.
    let pathname = nextProps.location.pathname;
    let path = "/dashboard/retailer/:rId";
    if (pathname.indexOf("/period/") > -1) {
      path = "/dashboard/retailer/:rId/period/:pId";
    }
    const match = matchPath(nextProps.location.pathname, {
      path,
      exact: true,
      strict: false,
    });
    // if not match found
    if (!match) {
      return;
    }
    let { rId } = match.params;
    this.selectRetailer(retailers, rId);
    // We are done with initial page load
    this.setState({
      initialRendering: false,
    });
  }

  render() {
    let { retailer, retailers } = this.props;
    // console.log(this.props, "this.props");
    let opts = [];
    retailers.forEach((rObj, index) => {
      opts.push({
        value: rObj._id,
        label: rObj.name,
      });
    });

    let selectedRetailer = 0;
    if (!isEmpty(retailer)) {
      selectedRetailer = retailer._id;
    }

    return (
      <div className="navbar-select-container retailer-select-container">
        <select
          name="retailer-select"
          className="form-control"
          value={selectedRetailer}
          onChange={this.onRetailerSelectChange}
          placeholder="Select a Retailer"
        >
          <option value="Select a Retailer">Select a Retailer</option>
          {opts.map((option, key) => {
            let optionKey = uniqid("select_");
            return (
              <option key={optionKey} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    retailer: state.retailer,
    retailers: state.retailers.items,
    isLoading: state.retailers.isLoading,
  };
};
/* const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setActiveRetailer,
      setActiveMonth,
      doFetchMonths,
      doFetchRetailersList
    },
    dispatch
  );
}; */

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveRetailer: (value) => {
      // console.log("value: ", value);
      dispatch(
        setActiveRetailer({
          _id: value._id,
          name: value.name,
          optId: value.optId,
        })
      );
    },
    setActiveMonth: () => {
      dispatch(setActiveMonth({}));
    },
    doFetchMonths: (value) => {
      dispatch(
        thunkFetchMonths({
          rId: value,
        })
      );
    },
    doFetchRetailersList: () => {
      dispatch(thunkFetchRetailersList());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RetailerSelect)
);
