import {
  SET_ACTIVE_RETAILER,
  FETCH_RETAILER_REQUEST,
  FETCH_RETAILER_SUCCESS,
  FETCH_RETAILER_FAILURE,
} from "dashboard/actions/actionTypes";

/**
 * Reducers
 */
let initialState = {};

export default function retailer(state = initialState, action) {
  // console.log("action.retailer : ", action.retailer);
  switch (action.type) {
    case SET_ACTIVE_RETAILER:
      return Object.assign({}, action.retailer);

    case FETCH_RETAILER_REQUEST:
      return initialState;

    case FETCH_RETAILER_SUCCESS:
      let { data } = action.payload;
      return Object.assign({}, data);

    case FETCH_RETAILER_FAILURE:
      return initialState;

    default:
      return state;
  }
}
