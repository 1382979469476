import React from 'react';
import { Grid, Row, Col, Media, FormControl, FormGroup } from 'react-bootstrap';
import { Redirect } from 'react-router';

import Card from 'dashboard/components/Card/Card.jsx';

import Button from 'components/CustomButton/CustomButton.jsx';

class RegisterPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fireRedirect: false,
      email: '',
      password: '',
      cfpassword: '',
      emailError: null,
      passwordError: null,
      cfpasswordError: null,
      isValidForm: false,
      emailLogin: '',
      emailErrorLogin: null,
      passwordLogin: '',
      passwordErrorLogin: null,

      first_name: '',
      last_name: '',
      company: '',
      type_textError: null
    };
    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value
    });
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(event.target.value) === false
      ? this.setState({
          emailError: (
            <small className="text-danger">
              Email is required and format should be <i>john@doe.com</i>.
            </small>
          )
        })
      : this.setState({ emailError: null });
  }
  handlePasswordChange(event) {
    this.setState({
      password: event.target.value
    });
    event.target.value.length < 6
      ? this.setState({
          passwordError: (
            <small className="text-danger">
              You must enter a password of at least 6 characters.
            </small>
          )
        })
      : this.setState({ passwordError: null });
  }
  handleCfPasswordChange(event) {
    this.setState({
      cfpassword: event.target.value
    });
    event.target.value !== this.state.password
      ? this.setState({
          cfpasswordError: (
            <small className="text-danger">Passwords do not match.</small>
          )
        })
      : this.setState({ cfpasswordError: null });
  }

  handleRegisterSubmit() {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    re.test(this.state.email) === false
      ? this.setState({
          emailError: (
            <small className="text-danger">
              Email is required and format should be <i>john@doe.com</i>.
            </small>
          ),
          isValidForm: false
        })
      : this.setState({ emailError: null, isValidForm: true });

    this.state.password.length < 6
      ? this.setState({
          passwordError: (
            <small className="text-danger">
              You must enter a password of at least 6 characters.
            </small>
          ),
          isValidForm: false
        })
      : this.setState({ passwordError: null, isValidForm: true });

    this.state.cfpassword !== this.state.password
      ? this.setState({
          cfpasswordError: (
            <small className="text-danger">Passwords do not match.</small>
          ),
          isValidForm: false
        })
      : this.setState({ cfpasswordError: null, isValidForm: true });

    this.state.first_name === '' ||
    this.state.last_name === '' ||
    this.state.compnay === ''
      ? this.setState({
          type_textError: (
            <small className="text-danger">Text is required.</small>
          ),
          isValidForm: false
        })
      : this.setState({ type_textError: null, isValidForm: true });

    if (this.state.isValidForm) {
      this.setState({ fireRedirect: true });
    }
  }
  render() {
    const { from } = this.props.location.state || '/';
    const { fireRedirect } = this.state;

    return (
      <Grid>
        <div id="register">
          <Row>
            <Col md={8} mdOffset={2}>
              <div className="header-text">
                <img
                  className="logo img-responsive"
                  src={`${process.env.PUBLIC_URL}/siteNalysis_Logo.png`}
                  alt="SiteNalysis"
                  style={{
                    marginTop: 20,
                    marginBottom: 30,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: 320
                  }}
                />
                <h4>Register for free and experience the dashboard today</h4>
                <hr />
              </div>
            </Col>
            <Col md={4} mdOffset={2}>
              <Media>
                <Media.Left>
                  <div className="icon">
                    <i className="pe-7s-user" />
                  </div>
                </Media.Left>
                <Media.Body>
                  <Media.Heading>Free Account</Media.Heading>
                  Here you can write a feature description for your dashboard,
                  let the users know what is the value that you give them.
                </Media.Body>
              </Media>
              <Media>
                <Media.Left>
                  <div className="icon">
                    <i className="pe-7s-graph1" />
                  </div>
                </Media.Left>
                <Media.Body>
                  <Media.Heading>Awesome Performances</Media.Heading>
                  Here you can write a feature description for your dashboard,
                  let the users know what is the value that you give them.
                </Media.Body>
              </Media>
              <Media>
                <Media.Left>
                  <div className="icon">
                    <i className="pe-7s-headphones" />
                  </div>
                </Media.Left>
                <Media.Body>
                  <Media.Heading>Global Support</Media.Heading>
                  Here you can write a feature description for your dashboard,
                  let the users know what is the value that you give them.
                </Media.Body>
              </Media>
            </Col>
            <Col md={4}>
              <form>
                <Card
                  plain
                  content={
                    <div>
                      <FormGroup>
                        <FormControl
                          type="text"
                          name="first_name"
                          placeholder="Your first name"
                          onChange={event => {
                            this.setState({ first_name: event.target.value });
                            event.target.value === ''
                              ? this.setState({
                                  type_textError: (
                                    <small className="text-danger">
                                      First name is required.
                                    </small>
                                  )
                                })
                              : this.setState({ type_textError: null });
                          }}
                        />
                        {this.state.type_textError}
                      </FormGroup>
                      <FormGroup>
                        <FormControl
                          type="text"
                          name="last_name"
                          placeholder="Your last name"
                          onChange={event => {
                            this.setState({ last_name: event.target.value });
                            event.target.value === ''
                              ? this.setState({
                                  type_textError: (
                                    <small className="text-danger">
                                      Last name is required.
                                    </small>
                                  )
                                })
                              : this.setState({ type_textError: null });
                          }}
                        />
                        {this.state.type_textError}
                      </FormGroup>
                      <FormGroup>
                        <FormControl
                          type="text"
                          name="company"
                          placeholder="Your company"
                          onChange={event => {
                            this.setState({ company: event.target.value });
                            event.target.value === ''
                              ? this.setState({
                                  type_textError: (
                                    <small className="text-danger">
                                      Company is required.
                                    </small>
                                  )
                                })
                              : this.setState({ type_textError: null });
                          }}
                        />
                        {this.state.type_textError}
                      </FormGroup>
                      <FormGroup>
                        <FormControl
                          type="text"
                          name="email"
                          placeholder="Email Address"
                          onChange={event => this.handleEmailChange(event)}
                        />
                        {this.state.emailError}
                      </FormGroup>
                      <FormGroup>
                        <FormControl
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={event => this.handlePasswordChange(event)}
                        />
                        {this.state.passwordError}
                      </FormGroup>
                      <FormGroup>
                        <FormControl
                          type="password"
                          name="cfpassword"
                          placeholder="Confirm password"
                          onChange={event => this.handleCfPasswordChange(event)}
                        />
                        {this.state.cfpasswordError}
                      </FormGroup>
                    </div>
                  }
                  ftTextCenter
                  legend={
                    <Button wd fill neutral onClick={this.handleRegisterSubmit}>
                      Create Free Account
                    </Button>
                  }
                />
              </form>
              {fireRedirect && <Redirect to={from || '/login'} />}
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }
}

export default RegisterPage;
