import React from 'react';
import UserList from './UserList';
import './users.scss';

class Users extends React.PureComponent {
  render() {

    return (
      <div className="manage-users">
        <UserList />
      </div>
    );
  }
}

export default Users;
