import React from 'react';
import CurrentProductsView from './CurrentProductsView';
import PreviousProductView from './PreviousProductView';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { omni_axios_instance } from 'utils/axios';
import { STANDALONE_API_URL } from 'utils/constants';
import moment from 'moment';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import FileHeader from 'admin/post-office/FileHeader';
import FileViewPagniation from 'admin/post-office/FileViewPagniation';

const FileDataLoader = props => (
  <>
    <ContentLoader
      height={200}
      width={500}
      speed={2}
      primaryColor="#ded7d759"
      secondaryColor="#efefef"
      {...props}
    >
      <rect x="0" y="15" rx="0" ry="0" width="500" height="15" />
      <rect x="0" y="50" rx="3" ry="3" width="85" height="8" />
      <rect x="260" y="50" rx="3" ry="3" width="85" height="8" />
      {/* Left Content */}
      <rect x="0" y="65" rx="0" ry="0" width="250" height="20" />
      <rect x="0" y="90" rx="0" ry="0" width="250" height="20" />
      <rect x="0" y="115" rx="0" ry="0" width="250" height="20" />
      {/* Right Content */}

      <rect x="260" y="65" rx="0" ry="0" width="250" height="20" />
      <rect x="260" y="90" rx="0" ry="0" width="250" height="20" />
      <rect x="260" y="115" rx="0" ry="0" width="250" height="20" />
    </ContentLoader>
    <p className="text-center" style={{ fontSize: '12px' }}>
      Loading, please wait. It may take longer.
    </p>
  </>
);

class FileView extends React.PureComponent {
  // _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fileCompareData: {},
      threshHoldsData: {},
      fileDetails: {},
      samePeriodFiles: [],
      pagination: {
        offset: 0,
        page: 0,
        pageCount: 0,
        perPage: 100
      }
    };
    // this.handleClick = this.handleClick.bind(this);
    this.handleNeedAttentionBtnClick = this.handleNeedAttentionBtnClick.bind(
      this
    );
    this.handleApproveBtnClick = this.handleApproveBtnClick.bind(this);
  }

  componentDidMount() {
    // this._isMounted = true;
    const fileId = this.props.match.params.fId;
    omni_axios_instance.get('/settings/threshold').then(res => {
      this.setState(
        {
          threshHoldsData: res.data.settings
        },
        () => {
          this.getFileDetails(fileId);
        }
      );
    });
  }
  getSamePeriodFileData(fileId) {
    omni_axios_instance.get(`/samePeriodFiles/${fileId}`).then(res => {
      this.setState(
        {
          samePeriodFiles: res.data.samePeriodFiles
        },
        () => {}
      );
    });
  }
  getFileDetails(fileId) {
    // const fileId = this.props.match.params.fId;
    omni_axios_instance.get(`/getFiles/${fileId}`).then(res => {
      this.setState(
        {
          fileDetails: res.data[0]
        },
        () => {
          this.getFileData();
          this.getSamePeriodFileData(fileId);
        }
      );
    });
  }

  componentWillUnmount() {
    // this._isMounted = false;
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {
    const currFileId = nextProps.match.params.fId;
    const prevFileId = this.props.match.params.fId;
    if (currFileId !== prevFileId) {
      this.getFileDetails(currFileId);
    }
  }

  handleScroll = e => {
    let isSyncingRightScroll = false;
    let isSyncingLeftScroll = false;
    let leftDiv = document.getElementById('leftDiv');
    let rightDiv = document.getElementById('rightDiv');

    if (leftDiv && rightDiv) {
      leftDiv.onscroll = function() {
        if (!isSyncingLeftScroll) {
          isSyncingRightScroll = true;
          rightDiv.scrollTop = leftDiv.scrollTop;
          rightDiv.scrollLeft = leftDiv.scrollLeft;
        }
        isSyncingLeftScroll = false;
      };

      rightDiv.onscroll = function() {
        if (!isSyncingRightScroll) {
          isSyncingLeftScroll = true;
          leftDiv.scrollTop = rightDiv.scrollTop;
          leftDiv.scrollLeft = rightDiv.scrollLeft;
        }
        isSyncingRightScroll = false;
      };
    }
  };

  getFileData() {
    const fileId = this.props.match.params.fId;
    let { pagination } = this.state;
    this.setState({
      isLoading: true
    });
    omni_axios_instance.get(`/getFileData/${fileId}`).then(response => {
      let data = response.data;

      // Mock required keys
      if (!data.currentProductData) {
        data.currentProductData = {
          data: [],
          date: ''
        };
      }
      if (!data.previousProductData) {
        data.previousProductData = {
          data: [],
          date: ''
        };
      }

      let prevProductsLength = 0;
      if (data.previousProductData.data) {
        prevProductsLength = data.previousProductData.data.length;
      }

      let currentProductsLength = 0;
      if (data.currentProductData.data) {
        currentProductsLength = data.currentProductData.data.length;
      }

      let maxProductCount = Math.max(currentProductsLength, prevProductsLength);
      let pageCount = Math.ceil(maxProductCount / pagination.perPage);

      this.setState({
        fileCompareData: data,
        isLoading: false,
        pagination: {
          ...pagination,
          pageCount
        }
      });

      let leftDiv = document.getElementById('leftDiv');
      let rightDiv = document.getElementById('rightDiv');
      if (leftDiv) {
        leftDiv.addEventListener('scroll', e => this.handleScroll(e));
      }
      if (rightDiv) {
        rightDiv.addEventListener('scroll', e => this.handleScroll(e));
      }
    });
  }

  handleNeedAttentionBtnClick() {
    let isDisabled = document.getElementById('need-attention-btn');
    isDisabled.disabled = true;
    isDisabled.style.cursor = 'not-allowed';
    let selectedFileId = this.props.match.params.fId;
    let url = `/upload/NeedAttention`;
    omni_axios_instance.post(`${url}`, { _id: selectedFileId }).then(res => {
      toastr.success('SUCCESS', 'Approved Status Rejected.');
      isDisabled.disabled = true;
      isDisabled.style.cursor = 'not-allowed';
      this.getFileDetails(selectedFileId);
    });
  }

  handleApproveBtnClick() {
    let isDisabled = document.getElementById('approved-btn');
    isDisabled.disabled = true;
    isDisabled.style.cursor = 'not-allowed';
    let selectedFileId = this.props.match.params.fId;
    axios
      .get(`${STANDALONE_API_URL}/upload/approveStatus?_id=${selectedFileId}`)
      .then(res => {
        if (res.data.success === true) {
          toastr.success('SUCCESS', 'File processed successfully!');
          isDisabled.disabled = true;
          isDisabled.style.cursor = 'not-allowed';
        } else {
          let message = !_.isEmpty(res.data.message)
            ? res.data.message
            : 'File not processed!';

          isDisabled.disabled = false;
          isDisabled.style.cursor = 'pinter';
          toastr.warning(JSON.stringify(message), {
            timeOut: 20000
          });
        }
        this.getFileDetails(selectedFileId);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  }

  addKeyToObj = fileData => {
    return fileData.map(element => {
      let obj = {};
      _.map(element, function(value, key) {
        obj[key] = {
          value: value,
          isChanged: false
        };
        return obj;
      });
      return obj;
    });
  };

  currentFileName(name) {
    let fileName = name.indexOf('/');

    if (fileName) {
      return name.split('/')[1];
    }
    return;
  }

  getFileHeading(props) {
    let {
      date,
      fileName,
      name,
      type,
      approvedStatus,
      flag
    } = this.state.fileDetails;

    const fileNameBgColor = flag =>
      classNames({
        item: true,
        itemFileName: true,
        [flag]: true
      });
    const statusColor = color =>
      classNames({
        fileType: true,
        [color]: true
      });

    return (
      <div className="po-product-content ">
        <div className="leftContent">
          <div className="file-text">
            <strong> File:</strong>
          </div>
          <div
            className={fileNameBgColor(flag)}
            data-toggle="tooltip"
            title={this.currentFileName(fileName)}
          >
            {this.currentFileName(fileName)}
          </div>

          <div className="item fileNameCon"> {name}</div>
          <div className="item fileType"> {type} </div>
          <div className="item">
            {moment(new Date(`${date}`)).format('MM/DD/YYYY')}
          </div>
        </div>
        {approvedStatus === 'pending' && flag === 'require_review' ? (
          <div className="rightContent">
            <button
              id="approved-btn"
              type="button"
              onClick={e => this.handleApproveBtnClick(e)}
              className="right-item approved-btn"
            >
              Approve
              <i className="thumb-icon fa fa-thumbs-up pull-right" />
            </button>

            <button
              id="need-attention-btn"
              type="button"
              onClick={e => this.handleNeedAttentionBtnClick(e)}
              className="right-item need-attention-btn"
            >
              Needs Attention
              <i className="thumb-icon fa fa-thumbs-down pull-right" />
            </button>
          </div>
        ) : (
          <div className="rightContent">
            <div
              className={statusColor(approvedStatus)}
              data-toggle="tooltip"
              title="Approved Status"
            >
              {approvedStatus}
            </div>
          </div>
        )}
      </div>
    );
  }

  doComparision = (currentProducts, prevProducts, fileTypeForkey) => {
    let { threshHoldsData } = this.state;
    let uniqueKey = 'Style Number';
    let fileType = fileTypeForkey || '';

    if (fileType === 'product') {
      uniqueKey = 'Style Number';
    } else if (fileType === 'homepage') {
      uniqueKey = 'Primary Menu';
    } else if (fileType === 'brand') {
      uniqueKey = 'Brand ID';
    }

    // get Compare keys
    let compareKeys = _.keys(threshHoldsData[fileType]);
    let rCurrentProducts = _.clone(currentProducts);
    let rPrevProducts = _.clone(prevProducts);

    _.forEach(rCurrentProducts, (currentProduct, currentProductKey) => {
      // get unique key for comparsion
      let isrPrevProducts = _.find(rPrevProducts, o => {
        if (!_.isEmpty(currentProduct[uniqueKey] && o[uniqueKey])) {
          return currentProduct[uniqueKey].value === o[uniqueKey].value;
        }
      });

      if (_.isEmpty(isrPrevProducts)) {
        _.map(currentProduct, function(item, key) {
          currentProduct[key]['isPrev'] = false;
        });
        return;
      }
    });

    _.forEach(rPrevProducts, (prevProduct, prevProductKey) => {
      // get unique key for comparsion
      let currentProduct = _.find(rCurrentProducts, o => {
        if (!_.isEmpty(prevProduct[uniqueKey] && o[uniqueKey])) {
          return prevProduct[uniqueKey].value === o[uniqueKey].value;
        }
      });

      // If matching current product is not found
      // loop on currentProduct and prevProduct keys
      if (_.isEmpty(currentProduct)) {
        _.map(prevProduct, function(item, key) {
          prevProduct[key]['isPrev'] = true;
        });
        return;
      }

      _.forEach(currentProduct, (value, currentKey) => {
        if (!_.includes(compareKeys, currentKey)) {
          currentProduct[currentKey].isPrev = true;
          return;
        }
        if (
          prevProduct[currentKey] &&
          currentProduct[currentKey] &&
          prevProduct[currentKey].value !== currentProduct[currentKey].value
        ) {
          currentProduct[currentKey].isChanged = true;
          prevProduct[currentKey].isChanged = true;
          currentProduct[currentKey].isPrev = true;
        } else {
          currentProduct[currentKey].isPrev = true;
        }
      });
    });

    // Sort by `Style Number`
    rCurrentProducts = _.sortBy(rCurrentProducts, [uniqueKey]);
    rPrevProducts = _.sortBy(rPrevProducts, [uniqueKey]);

    return {
      currentProducts: rCurrentProducts,
      prevProducts: rPrevProducts
    };
  };

  getSamePeriodFiles(files) {
    if (_.isEmpty(files)) {
      return <div className="alert alert-info">No Same period files exist</div>;
    }

    return files.map((item, index) => {
      // let isOpened = activeIndex === item._id;
      return <FileHeader key={index} item={item} />;
    });
  }

  handlePageClick = data => {
    let selected = data.selected;
    let { pagination } = this.state;
    let offset = Math.ceil(selected * pagination.perPage);
    this.setState({
      pagination: {
        ...pagination,
        offset: offset,
        page: selected
      }
    });
  };

  render() {
    let { fileCompareData, isLoading, pagination } = this.state;

    if (_.isEmpty(fileCompareData) || isLoading) {
      return (
        <div>
          {isLoading ? (
            <div className="po-comparison-view">
              <div
                onClick={() => this.props.history.push('/admin/post-office')}
                className="backBtn"
              >
                <i className="fa fa-angle-left" aria-hidden="true" />
                <span>Back</span>
              </div>
              <FileDataLoader />
            </div>
          ) : (
            <div className="alert alert-info text-center">
              No product(s) found for comparison.
            </div>
          )}
        </div>
      );
    }

    let {
      date: currentDate,
      data: currentProducts
    } = fileCompareData.currentProductData;
    let {
      date: prevDate,
      data: prevProducts
    } = fileCompareData.previousProductData;

    let slicedCurrentProducts = currentProducts.slice(
      pagination.offset,
      pagination.offset + pagination.perPage
    );
    let slicedPrevProducts = prevProducts.slice(
      pagination.offset,
      pagination.offset + pagination.perPage
    );

    // Add isChanged key to Array elemet
    slicedCurrentProducts = this.addKeyToObj(slicedCurrentProducts);
    slicedPrevProducts = this.addKeyToObj(slicedPrevProducts);

    let fileType = fileCompareData.type;
    if (fileCompareData.type === 'brands') {
      fileType = 'brand';
    }
    let comparison = this.doComparision(
      slicedCurrentProducts,
      slicedPrevProducts,
      fileType
    );

    return (
      <div className="po-comparison-view">
        <div
          onClick={() => this.props.history.push('/admin/post-office')}
          className="backBtn"
        >
          <i className="fa fa-angle-left" aria-hidden="true" />
          <span>Back</span>
        </div>
        {this.getFileHeading(fileCompareData)}
        <FileViewPagniation
          {...pagination}
          handlePageClick={this.handlePageClick}
        />
        <Row className="po-file-view">
          <Col sm={6} className="po-current-file-view">
            <CurrentProductsView
              products={comparison.currentProducts}
              currentProductDate={currentDate}
              count={currentProducts.length}
              {...pagination}
            />
          </Col>
          <Col sm={6} className="po-previous-file-view">
            <PreviousProductView
              products={comparison.prevProducts}
              count={prevProducts.length}
              previousProductDate={prevDate}
              {...pagination}
            />
          </Col>
        </Row>
        <FileViewPagniation
          {...pagination}
          handlePageClick={this.handlePageClick}
        />
        <div className="same-period-files">
          <legend>Same period files</legend>
          {this.getSamePeriodFiles(this.state.samePeriodFiles)}
        </div>
      </div>
    );
  }
}

export default withRouter(FileView);
